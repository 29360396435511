// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging } from 'firebase/messaging';
// Add additional Firebase services you want to use
// Example: import { getAuth } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBD2E76FyFlW_KjCVYziFz5IHo1TpqSqXQ',
  authDomain: 'finablr-75ff6.firebaseapp.com',
  projectId: 'finablr-75ff6',
  storageBucket: 'finablr-75ff6.appspot.com',
  messagingSenderId: '251407639132',
  appId: '1:251407639132:web:fc8b2d7864d3463ed0fc99',
  measurementId: 'G-2ST2ET6ERJ',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const messaging = getMessaging(app);

// Export the initialized app and other Firebase services if needed
export { app, analytics, messaging };
