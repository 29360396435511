import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const deleteAsset = ({ id }) => {
  return axios.delete(`/collaterals/${id}`);
};

deleteAsset.propTypes = {
  id: PropTypes.string.isRequired,
};

export const useDeleteAsset = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (deletedAsset) => {
      await queryClient.cancelQueries('assets');

      const previousAssets = queryClient.getQueryData('assets');

      queryClient.setQueryData(
        'assets',
        previousAssets?.filter((asset) => asset.id !== deletedAsset.id)
      );

      return { previousAssets };
    },
    onError: (_, __, context) => {
      if (context?.previousAssets) {
        queryClient.setQueryData('assets', context.previousAssets);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('assets');
      addNotification({
        type: 'success',
        title: 'Collateral asset deleted',
      });
    },
    ...config,
    mutationFn: deleteAsset,
  });
};
