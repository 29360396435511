import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const deleteServiceFeeRange = ({ id, serviceFeeId }) => {
  return axios.delete(`/products/${id}/service-fee/${serviceFeeId}`);
};

deleteServiceFeeRange.propTypes = {
  id: PropTypes.string.isRequired,
  serviceFeeId: PropTypes.string.isRequired,
};

export const useDeleteServiceFeeRange = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (deletedProduct) => {
      await queryClient.cancelQueries('products');

      const previousProducts = queryClient.getQueryData('products');

      queryClient.setQueryData(
        'products',
        previousProducts?.filter((product) => product.id !== deletedProduct.id)
      );

      return { previousProducts };
    },
    onError: (_, __, context) => {
      if (context?.previousProducts) {
        queryClient.setQueryData('products', context.previousProducts);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['product', data._id]);
      addNotification({
        type: 'success',
        title: 'Range Deleted',
      });
    },
    ...config,
    mutationFn: deleteServiceFeeRange,
  });
};
