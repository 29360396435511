import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { Authorization, ROLES } from 'lib/authorization';
import { TrashIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

import { useDeleteAsset } from '../api/deleteAsset';

export const DeleteAsset = ({ id }) => {
  const deleteAssetMutation = useDeleteAsset();

  if (deleteAssetMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}>
      <ConfirmationDialog
        icon="danger"
        title="Remove Asset"
        body="Are you sure you want to remove this collateral asset?"
        triggerButton={
          <button>
            <TrashIcon className="h-4 w-4" />
          </button>
        }
        confirmButton={
          <Button
            isLoading={deleteAssetMutation.isLoading}
            type="button"
            className="bg-red-600"
            onClick={async () => await deleteAssetMutation.mutateAsync({ id: id })}
          >
            Trash
          </Button>
        }
      />
    </Authorization>
  );
};

DeleteAsset.propTypes = {
  id: PropTypes.string.isRequired,
};
