import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const addComment = ({ data, loanId }) => {
  return axios.post(`/loans/${loanId}/comment`, data);
};

export const useAddComment = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (newComment) => {
      await queryClient.cancelQueries(['loan', newComment?.loanId]);

      const previousLoan = queryClient.getQueryData(['loan', newComment?.loanId]);

      queryClient.setQueryData(['loan', newComment?.loanId], (old) => ({
        ...old,
        comments: [
          ...old.comments,
          { ...newComment, id: Date.now(), date: new Date().toISOString() },
        ],
      }));

      return { previousLoan };
    },
    onError: (_, __, context) => {
      if (context?.previousLoan) {
        queryClient.setQueryData(['loan', context.previousLoan.loanId], context.previousLoan);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['loan', data._id]);
      addNotification({
        type: 'success',
        title: 'Comment Sent.',
      });
    },
    ...config,
    mutationFn: addComment,
  });
};
