import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button, Spinner } from 'components/Elements';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useLoan } from '../api/getLoan';
import { UPLOADS_URL } from 'config';
import { generatePaymentSummary } from 'utils/generatePaymentSummary';
import { splitAndCapitalize } from 'utils/splitAndCapitalize';
import { formatDate } from 'utils/format';
import { IntroductoryVideoCall } from '../components/IntroductoryVideoCall';
import { formatText } from 'utils/formatText';
import { TrackingID } from '../components/TrackingID';
import { NINPreview } from '../components/NINPreview';
import { SendSMS } from '../components/SendSMS';
import { SendEmail } from '../components/SendEmail';
import { RequestGuarantorVerification } from '../components/RequestGuarantorVerification';
import { VerifyGuarantor } from '../components/VerifyGuarantor';
import { AddComment } from '../components/AddComment';
import { EFLApproval } from '../components/EFLApproval';
import { UpdateLoanStatus } from '../components/UpdateLoanStatus';
import { Circle } from 'react-feather';
import { SendWhatsApp } from '../components/SendWhatsApp';
import { PaymentSchedule } from 'features/pigeonLoans';
import { InitiateContract } from '../components/InitiateContract';

export const Loan = () => {
  const { id } = useParams();

  const loanQuery = useLoan({ id });
  const navigate = useNavigate();

  const routes = [
    {
      name: 'Loans',
      path: '/app/loans',
    },
    {
      name: ` ${loanQuery.data?.client?.basicInformation?.firstName} ${loanQuery.data?.client?.basicInformation?.lastName}`,
      path: `/app/loans/${id}`,
    },
  ];

  if (loanQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!loanQuery.data) return null;

  console.log(loanQuery.data);

  return (
    <ContentLayout
      title="Loan details"
      end={
        <div className="flex flex-row items-center space-x-2">
          <PaymentSchedule
            amount={loanQuery.data?.amount?.loanRequired}
            interestRate={loanQuery.data?.interestRate}
            term={loanQuery.data?.term}
            monthlyPayment={loanQuery.data?.monthlyPayment}
            paymentMethod={loanQuery.data?.paymentMethod}
          />

          <SendEmail trackingId={loanQuery.data?.trackingId} />
          <SendSMS trackingId={loanQuery.data?.trackingId} />
          <SendWhatsApp trackingId={loanQuery.data?.trackingId} />
          <UpdateLoanStatus id={id} />
        </div>
      }
    >
      <Breadcrumbs routes={routes} />
      <div className="mt-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="flex flex-col space-y-8">
            <div className="shadow bg-white rounded-2xl flex flex-col items-center space-y-1 pb-8">
              <div className="rounded-full border bg-white border-primary-100 p-2 px-4 -mt-2 mb-6">
                Borrower
              </div>
              <img
                src={
                  UPLOADS_URL + '/images/users/' + loanQuery.data?.client?.basicInformation?.avatar
                }
                alt="user"
                className="w-20 h-20 rounded-full"
              />
              <div className="text-xl font-semibold">
                {loanQuery.data?.client?.basicInformation?.firstName}{' '}
                {loanQuery.data?.client?.basicInformation?.lastName}
              </div>
              <div className="font-light">
                {loanQuery.data?.client?.basicInformation?.contactInformation?.email}
              </div>
              <div className="font-light">
                {loanQuery.data?.client?.basicInformation?.contactInformation?.phoneNumber}
              </div>
            </div>
            <div className="">
              <div className="mb-3 shadow bg-white rounded-2xl  p-2 grid grid-cols-3 gap-2 items-center">
                <div className="text-xs text-gray-500 text-start">Loan Status</div>
                <div className="text-center uppercase col-span-2 bg-gray-100 text-gray-600 rounded-xl font-semibold">
                  {formatText(loanQuery.data?.status)}
                </div>
              </div>
              <div className="shadow bg-white rounded-2xl  p-2 grid grid-cols-3 gap-2 items-center">
                <div className="text-xs text-gray-500 text-start">Recovery Mode</div>
                <div className="text-center uppercase col-span-2 bg-green-100 text-green-600 rounded-xl font-semibold">
                  {formatText(loanQuery.data?.recoveryStatus)}
                </div>
              </div>
            </div>

            <div className="shadow bg-white rounded-2xl text-center p-2 ">
              <div className="text-xs text-gray-500 text-start">Borrower's Signature</div>
              <div className="p-3">
                <img
                  src={`${UPLOADS_URL}/signatures/${loanQuery.data?.signature}`}
                  alt="National ID Front"
                  className="w-full"
                />
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="bg-white rounded-2xl shadow mb-4">
              {loanQuery.data?.trackingId && <TrackingID trackingId={loanQuery.data?.trackingId} />}
              <hr />
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Amount</div>
                <div className="font-semibold text-md col-span-2">
                  UGX {loanQuery.data?.amount?.loanRequired?.toLocaleString()}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Interest</div>
                <div className="font-semibold text-md col-span-2">
                  {loanQuery.data?.interestRate}%
                </div>
              </div>
              <hr />
              <div className=" p-6">
                <div className="font-semibold text-md col-span-2">
                  <IntroductoryVideoCall
                    loanId={loanQuery.data?._id}
                    scheduledDates={loanQuery.data?.introductory_video_call_scheduled_dates}
                  />
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Duration</div>
                <div className="font-semibold text-md col-span-2">
                  {loanQuery.data?.term} months
                </div>
              </div>
              {loanQuery.data?.payments && (
                <>
                  <hr />
                  <div className="grid grid-cols-3 gap-8 p-6">
                    <div className="text-gray-600 text-sm">Installments</div>
                    <div className="font-semibold text-md col-span-2">
                      {generatePaymentSummary(
                        loanQuery.data?.payments,
                        loanQuery.data?.amount?.loanRequired
                      )}
                    </div>
                  </div>
                </>
              )}
              <hr />
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Product</div>
                <div className="font-semibold text-md col-span-2">
                  {loanQuery.data?.product?.product_title}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Guarantor</div>
                <div className="font-semibold text-md col-span-2">
                  <div className="mb-4">
                    <div className="flex flex-row items-center space-x-2">
                      <div className="text-sm">Name:</div>
                      <div className="">{loanQuery.data?.guarantor?.name}</div>
                    </div>
                    <div className="flex flex-row items-center space-x-2">
                      <div className="text-sm">Phone Number:</div>
                      <div className="">{loanQuery.data?.guarantor?.phoneNumber}</div>
                    </div>
                    <div className="flex flex-row items-center space-x-2">
                      <div className="text-sm">Email Address:</div>
                      <div className="">{loanQuery.data?.guarantor?.email}</div>
                    </div>
                  </div>
                  <div className="mb-4">
                    {loanQuery.data?.guarantor?.verified?.phone ? (
                      <span className="bg-green-100 text-green-600 rounded-full px-4 text-xs font-bold py-2">
                        VERIFIED
                      </span>
                    ) : (
                      <>
                        <div className="mb-3 w-fit bg-red-100 text-red-600 rounded-full px-4 text-xs font-bold py-2">
                          NOT VERIFIED
                        </div>
                        <div className="flex flex-row items-center space-x-2">
                          <RequestGuarantorVerification
                            phoneNumber={loanQuery.data?.guarantor?.phoneNumber}
                            loanId={id}
                          />
                          <VerifyGuarantor
                            phoneNumber={loanQuery.data?.guarantor?.phoneNumber}
                            loanId={id}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Assets</div>
                {loanQuery.data?.collateral?.map((collateral, index) => {
                  if (collateral.vehicles) {
                    return (
                      <>
                        <div className="">
                          {collateral.vehicles?.brand} {collateral.vehicles?.model} -{' '}
                          {collateral.vehicles?.year_of_manufacture}
                        </div>
                        <div className="">
                          <div className="text-gray-600 text-sm">Value</div>
                          <div className="font-semibold text-md col-span-2">
                            UGX {collateral?.current_worth?.toLocaleString()}
                          </div>
                        </div>
                      </>
                    );
                  }
                  if (collateral?.real_estate) {
                    return (
                      <Link
                        to={`/app/assets/${collateral?._id}`}
                        className="grid grid-cols-2 gap-4 w-full"
                      >
                        <div className="">
                          Title Tenre: {collateral?.title}
                          <div className="">
                            Location:{' '}
                            <span className="font-semibold">
                              {collateral.real_estate?.land_location}
                            </span>
                          </div>
                          <div className="">
                            Size of Land:{' '}
                            <span className="font-semibold">
                              {splitAndCapitalize(collateral.real_estate?.size_of_land)}
                            </span>
                          </div>
                          <div className="">
                            What's on land:{' '}
                            <span className="font-semibold">
                              {splitAndCapitalize(collateral.real_estate?.whats_on_land)}
                            </span>
                          </div>
                        </div>
                        <div className="">
                          <div className="text-gray-600 text-sm">Value</div>
                          <div className="font-semibold text-md col-span-2">
                            UGX {collateral?.current_worth?.toLocaleString()}
                          </div>
                          <div className="text-gray-600 text-sm">Assessment Fees</div>
                          <div className="font-semibold text-md col-span-2">
                            UGX {collateral?.total_assessment_fees?.toLocaleString()}
                          </div>
                        </div>
                      </Link>
                    );
                  }
                  if (collateral?.computers) {
                    return (
                      <Link
                        to={`/app/assets/${collateral?._id}`}
                        className="flex flex-row items-center gap-4 w-full"
                      >
                        <div className="">
                          {collateral.computers?.specify_others &&
                          collateral.computers?.specify_others !== 'undefined' ? (
                            <div className="flex flex-row items-center space-x-2">
                              <div className="text-gray-600 text-sm">Brand:</div>
                              <div className="font-semibold">
                                {collateral.computers?.specify_others}
                              </div>
                            </div>
                          ) : (
                            <div className="flex flex-row items-center space-x-2">
                              <div className="text-gray-600 text-sm">Brand:</div>
                              <div className="font-semibold">{collateral.computers?.brand}</div>
                            </div>
                          )}
                          <div className="flex flex-row items-center space-x-2">
                            <div className="text-gray-600 text-sm">Hard Disk Space: </div>
                            <div className="font-semibold">
                              {collateral?.computers?.hard_disk_space} GB
                            </div>
                          </div>
                          <div className="flex flex-row items-center space-x-2">
                            <div className="text-gray-600 text-sm">Processor Speed: </div>
                            <div className="font-semibold">
                              {collateral.computers?.processor_speed} GHz
                            </div>
                          </div>
                          <div className="flex flex-row items-center space-x-2">
                            <div className="text-gray-600 text-sm">RAM Size: </div>
                            <div className="font-semibold">{collateral.computers?.ram_size} GB</div>
                          </div>
                          <div className="flex flex-row items-center space-x-2">
                            <div className="text-gray-600 text-sm">Collateral Value:</div>
                            <div className="font-semibold text-md col-span-2">
                              UGX {collateral?.current_worth?.toLocaleString()}
                            </div>
                          </div>
                          <div className="flex flex-row items-center space-x-2">
                            <div className="text-gray-600 text-sm">Assessment Fees:</div>
                            <div className="font-semibold text-md col-span-2">
                              UGX {collateral?.total_assessment_fees?.toLocaleString()}
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  }
                  if (collateral?.smart_phone) {
                    return (
                      <Link
                        to={`/app/assets/${collateral?._id}`}
                        className="flex flex-row items-center gap-4 w-full"
                      >
                        <div className="">
                          {collateral.smart_phone?.specify_others &&
                          collateral.smart_phone?.specify_others !== 'undefined' ? (
                            <div className="flex flex-row items-center space-x-2">
                              <div className="text-gray-600 text-sm">Brand:</div>
                              <div className="font-semibold">
                                {collateral.smart_phone?.specify_others}
                              </div>
                            </div>
                          ) : (
                            <div className="flex flex-row items-center space-x-2">
                              <div className="text-gray-600 text-sm">Brand:</div>
                              <div className="font-semibold">{collateral.smart_phone?.brand}</div>
                            </div>
                          )}
                          <div className="flex flex-row items-center space-x-2">
                            <div className="text-gray-600 text-sm">RAM Size: </div>
                            <div className="font-semibold">
                              {collateral?.smart_phone?.ram_size} GB
                            </div>
                          </div>
                          <div className="flex flex-row items-center space-x-2">
                            <div className="text-gray-600 text-sm">Storage Space: </div>
                            <div className="font-semibold">
                              {collateral.smart_phone?.storage_space} GB
                            </div>
                          </div>
                          <div className="flex flex-row items-center space-x-2">
                            <div className="text-gray-600 text-sm">Model: </div>
                            <div className="font-semibold">{collateral.smart_phone?.model}</div>
                          </div>
                          <div className="flex flex-row items-center space-x-2">
                            <div className="text-gray-600 text-sm">Collateral Value:</div>
                            <div className="font-semibold text-md col-span-2">
                              UGX {collateral?.current_worth?.toLocaleString()}
                            </div>
                          </div>
                          <div className="flex flex-row items-center space-x-2">
                            <div className="text-gray-600 text-sm">Assessment Fees:</div>
                            <div className="font-semibold text-md col-span-2">
                              UGX {collateral?.total_assessment_fees?.toLocaleString()}
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  }
                  if (collateral?.other_electronics) {
                    return (
                      <Link
                        to={`/app/assets/${collateral?._id}`}
                        className="flex flex-row items-center gap-4 w-full"
                      >
                        <div className="">
                          {collateral.other_electronics?.specify_others &&
                          collateral.other_electronics?.specify_others !== 'undefined' ? (
                            <div className="flex flex-row items-center space-x-2">
                              <div className="text-gray-600 text-sm">Brand:</div>
                              <div className="font-semibold">
                                {collateral.other_electronics?.specify_others}
                              </div>
                            </div>
                          ) : (
                            <div className="flex flex-row items-center space-x-2">
                              <div className="text-gray-600 text-sm">Brand:</div>
                              <div className="font-semibold">
                                {collateral.other_electronics?.brand}
                              </div>
                            </div>
                          )}
                          <div className="flex flex-row items-center space-x-2">
                            <div className="text-gray-600 text-sm">Nature of Electronic:</div>
                            <div className="font-semibold">
                              {collateral.other_electronics?.nature_of_electronic}
                            </div>
                          </div>
                          <div className="flex flex-row items-center space-x-2">
                            <div className="text-gray-600 text-sm">Model: </div>
                            <div className="font-semibold">
                              {collateral.other_electronics?.model_type}
                            </div>
                          </div>
                          <div className="flex flex-row items-center space-x-2">
                            <div className="text-gray-600 text-sm">Collateral Value:</div>
                            <div className="font-semibold text-md col-span-2">
                              UGX {collateral?.current_worth?.toLocaleString()}
                            </div>
                          </div>
                          <div className="flex flex-row items-center space-x-2">
                            <div className="text-gray-600 text-sm">Assessment Fees:</div>
                            <div className="font-semibold text-md col-span-2">
                              UGX {collateral?.total_assessment_fees?.toLocaleString()}
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  }
                })}
              </div>
              <hr />
            </div>

            <div className="mb-3">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <NINPreview
                  title="National ID Front"
                  location={loanQuery.data?.client?.documentVerification?.nationalID?.front}
                />
                <NINPreview
                  title="National ID Back"
                  location={loanQuery.data?.client?.documentVerification?.nationalID?.back}
                />
              </div>
            </div>
            {loanQuery.data.status === 'NEW' && <EFLApproval />}
            {loanQuery.data.status === 'OFFER_ACCEPTED' && <InitiateContract id={id} />}
            {loanQuery.data?.comments && (
              <div className="bg-white rounded-2xl shadow mb-4 p-8">
                <div className="text-lg mb-4">Comments</div>
                <AddComment />
                <div className="relative border-l border-gray-200">
                  {loanQuery.data?.comments?.map((c, index) => (
                    <div key={index} className="mb-4 flex flex-row items-start space-x-2">
                      <div className="-ml-4 rounded-full bg-white border border-gray-400 w-8 h-8 flex flex-row items-center justify-center">
                        <span>{c.username?.slice(0, 1)}</span>
                      </div>
                      <div className="">
                        <div className="flex flex-row items-center text-sm mb-1">
                          <span className="font-bold mr-2">{c.username}</span>{' '}
                          <span className="text-gray-400">added a comment</span>{' '}
                          <span>
                            <Circle className="h-1" />
                          </span>{' '}
                          <span>{formatDate(c.date)}</span>
                        </div>
                        <div className="rounded-lg">{c.comment}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
