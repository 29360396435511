import { Navigate, Route, Routes } from 'react-router-dom';

import { Notification } from './Notification';
import { Notifications } from './Notifications';

export const NotificationsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Notifications />} />
      <Route path=":id" element={<Notification />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
