import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx/xlsx.mjs';
import { formatDate } from 'utils/format';
import { Download } from 'react-feather';

// Function to flatten nested objects
const flattenObject = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, key) => {
    const pre = prefix.length ? `${prefix}.` : '';
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(acc, flattenObject(obj[key], pre + key));
    } else {
      acc[pre + key] = obj[key];
    }
    return acc;
  }, {});
};

export const ExportWorkBook = ({ data, title }) => {
  const handleExport = () => {
    // Flatten each object in the data array
    const flattenedData = data.map(item => flattenObject(item));

    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    const fileBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    saveAs(
      new Blob([fileBuffer], { type: 'application/octet-stream' }),
      `${title}-${formatDate(new Date())}.xlsx`
    );
  };

  return (
    <button onClick={handleExport} className="flex flex-row items-center space-x-2">
      <div className="">
        <Download size={14} />
      </div>
      <div className="font-bold text-xs">Download Data</div>
    </button>
  );
};
