import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { Authorization, ROLES } from 'lib/authorization';
import { TrashIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

import { useDeleteRepayment } from '../api/deleteRepayment';

export const DeleteRepayment = ({ id }) => {
  const deleteRepaymentMutation = useDeleteRepayment();

  if (deleteRepaymentMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}>
      <ConfirmationDialog
        icon="danger"
        title="Delete Repayment"
        body="This action is not reversible. Are you sure you want to delete this repayment?"
        triggerButton={
          <button>
            <TrashIcon className="h-4 w-4" />
          </button>
        }
        confirmButton={
          <Button
            isLoading={deleteRepaymentMutation.isLoading}
            type="button"
            className="bg-red-600"
            onClick={async () => await deleteRepaymentMutation.mutateAsync({ id: id })}
          >
            Delete
          </Button>
        }
      />
    </Authorization>
  );
};

DeleteRepayment.propTypes = {
  id: PropTypes.string.isRequired,
};
