export function splitAndCapitalize(inputString) {
  // Split the string into an array of words
  const wordsArray = inputString.split('_');

  // Capitalize the first letter of each word
  const capitalizedWordsArray = wordsArray.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  // Join the capitalized words back into a string
  const resultString = capitalizedWordsArray.join(' ');

  return resultString;
}
