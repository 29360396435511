import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const reallocateRepayment = ({ data, id }) => {
  return axios.patch(`/repayments/${id}/reallocate`, data);
};

export const useReallocateRepayment = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (reallocatingRepayment) => {
      await queryClient.cancelQueries(['repayment', reallocatingRepayment?.id]);

      const previousRepayment = queryClient.getQueryData(['repayment', reallocatingRepayment?.id]);

      queryClient.setQueryData(['repayment', reallocatingRepayment?.id], {
        ...previousRepayment,
        ...reallocatingRepayment.data,
        id: reallocatingRepayment.id,
      });

      return { previousRepayment };
    },
    onError: (_, __, context) => {
      if (context?.previousRepayment) {
        queryClient.setQueryData(
          ['repayment', context.previousRepayment.id],
          context.previousRepayment
        );
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['repayment', data._id]);
      addNotification({
        type: 'success',
        title: 'Reallocation Successful',
      });
    },
    ...config,
    mutationFn: reallocateRepayment,
  });
};
