import React from 'react';
import { useLoans } from 'features/loans';
import { UPLOADS_URL } from 'config';
import { Link } from 'react-router-dom';
import { FullScreenSpinner } from 'components/Elements';

export const RecentLoans = () => {
  const loansQuery = useLoans();

  if (loansQuery.isLoading) {
    return <FullScreenSpinner />;
  }

  if (!loansQuery.data) return null;

  // Filter out loans with status 'NEW'
  const loans = loansQuery.data.filter((loan) => loan.status === 'NEW');

  if (!loans.length) return null;

  return (
    <div className="bg-white p-4 rounded-xl shadow">
      <div className="text-primary-200 font-bold">New Loan Applications</div>
      <div className="flex space-x-12 overflow-x-auto p-4">
        {loans.map((loan) => (
          <Link key={loan.id} to={`./loans/${loan._id}`} className="flex flex-col items-center">
            <div className="relative">
              <img
                src={UPLOADS_URL + '/images/users/' + loan.client?.basicInformation?.avatar}
                alt="Client Avatar"
                className="w-16 h-16 rounded-full border-2 border-secondary-500"
              />
              <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 bg-secondary-500 text-white text-xs px-2 py-1 rounded-full">
                <div className="flex flex-row items-center">
                  <span>UGX</span>
                  <span>{loan?.amount?.loanRequired?.toLocaleString()}</span>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
