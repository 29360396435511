import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const deleteNotification = ({ id }) => {
  return axios.delete(`/notifications/${id}`);
};

deleteNotification.propTypes = {
  id: PropTypes.string.isRequired,
};

export const useDeleteNotification = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (deletedNotification) => {
      await queryClient.cancelQueries('notifications');

      const previousNotifications = queryClient.getQueryData('notifications');

      queryClient.setQueryData(
        'notifications',
        previousNotifications?.filter((notification) => notification.id !== deletedNotification.id)
      );

      return { previousNotifications };
    },
    onError: (_, __, context) => {
      if (context?.previousNotifications) {
        queryClient.setQueryData('notifications', context.previousNotifications);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('notifications');
      addNotification({
        type: 'success',
        title: 'Notification Deleted',
      });
    },
    ...config,
    mutationFn: deleteNotification,
  });
};
