import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button, Spinner } from 'components/Elements';
import { useNavigate, useParams } from 'react-router-dom';
import { Authorization, ROLES } from 'lib/authorization';
import { useUser } from '../api/getUser';

export const ServiceProvider = () => {
  const { id } = useParams();
  const serviceProviderQuery = useUser({ id });

  const routes = [
    {
      name: 'Service Providers',
      path: '/app/service-providers',
    },
    {
      name: serviceProviderQuery.data?.authentication?.username,
      path: `/app/service-providers/${id}`,
    },
  ];
  const navigate = useNavigate();

  if (serviceProviderQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!serviceProviderQuery.data) return null;

  const Entry = ({ label, value }) => (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{value}</dd>
    </div>
  );

  return (
    <ContentLayout title={serviceProviderQuery.data.authentication.username}>
      <Breadcrumbs routes={routes} />
      <Authorization
        forbiddenFallback={<div>Only admin can view this.</div>}
        allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
      >
        <div className="mt-6 flex flex-col space-y-4">
          <div className="bg-white rounded-2xl shadow-lg p-5">
            <div className="mb-2">Provider Basic Information</div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <Entry
                  label="First Name"
                  value={serviceProviderQuery.data?.basicInformation?.firstName}
                />
                <Entry
                  label="Last Name"
                  value={serviceProviderQuery.data?.basicInformation?.lastName}
                />
                <Entry
                  label="Email"
                  value={serviceProviderQuery.data?.basicInformation?.contactInformation.email}
                />
                <Entry
                  label="Phone Number"
                  value={
                    serviceProviderQuery.data?.basicInformation?.contactInformation.phoneNumber
                  }
                />
                <Entry label="Status" value={serviceProviderQuery.data?.status} />
                <Entry label="Created At" value={serviceProviderQuery.data?.createdAt} />
              </dl>
            </div>
          </div>
          <div className="bg-white rounded-2xl shadow-lg p-5">
            <div className="mb-2">Provider Profile</div>
            <div className="text-gray-400">Coming soon...</div>
          </div>
        </div>
      </Authorization>
    </ContentLayout>
  );
};
