export function formatText(sentence) {
  if (sentence) {
    if (sentence?.includes('_')) {
      return sentence
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    } else if (sentence === sentence.toUpperCase()) {
      return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
    } else {
      return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    }
  }
}
