import { Button } from 'components/Elements';
import { Form, InputField, ReactMultiSelectField } from 'components/Form';
import { ContentLayout } from 'components/Layout';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Circle } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useCreateProduct } from '../api/createProduct';
import { useCreateServiceFee } from '../api/createServiceFee';
import { useCreateLateFee } from '../api/createLateFee';

export const Create = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // set create-product to true in localstorage
    localStorage.setItem('create-product', true);
  }, []);

  const [productDetails, setProductDetails] = useState(
    localStorage.getItem('create-product') || true
  );
  const [serviceFees, setServiceFees] = useState();
  const [lateFees, setLateFees] = useState();

  const createProductMutation = useCreateProduct();
  const createServiceFeeMutation = useCreateServiceFee();

  const createLateFeeMutation = useCreateLateFee();

  return (
    <>
      <div className="bg-white shadow pb-4">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="flex flex-row items-center justify-between">
            <div className="px-4 py-6 sm:px-0">
              <h1 className="text-2xl font-semibold text-gray-900">Create Product</h1>
              <p className="mt-2 text-sm text-gray-500">
                Create a new loan product and specify the various valuation fees.
              </p>
            </div>
            <Button
              onClick={() => {
                Swal.fire({
                  title: 'Are you sure?',
                  text: 'You will lose all unsaved changes',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, cancel',
                  cancelButtonText: 'No, keep working',
                }).then((result) => {
                  if (result.isConfirmed) {
                    localStorage.removeItem('create-product');
                    navigate('/app/products');
                  }
                });
              }}
            >
              Exit
            </Button>
          </div>
        </div>
        <div className="bg-gray-100 py-3 mx-auto mb-4">
          <div className="flex flex-row justify-start mx-24 items-center space-x-2">
            <div
              className={`flex flex-row items-center space-x-2 ${
                productDetails ? 'text-primary-100' : 'text-gray-400'
              }`}
            >
              <Circle size={16} />
              <span className="">Product Details</span>
            </div>
            <div
              className={`flex flex-row items-center space-x-2 ${
                serviceFees ? 'text-primary-100' : 'text-gray-400'
              }`}
            >
              <Circle size={16} />
              <span className="">Service Fees</span>
            </div>
            <div
              className={`flex flex-row items-center space-x-2 ${
                lateFees ? 'text-primary-100' : 'text-gray-400'
              }`}
            >
              <Circle size={16} />
              <span className="">Late Fees</span>
            </div>
          </div>
        </div>
        <div className="mb-8 mx-24">
          {productDetails && (
            <>
              <Form
                id="create-product"
                onSubmit={async (values) => {
                  const result = await createProductMutation.mutateAsync({ data: values });
                  localStorage.setItem('create_product_product_id', result._id);
                  setServiceFees(true);
                  setProductDetails(false);
                }}
                className="mt-3"
              >
                {({ register, formState, watch, setValue }) => (
                  <div>
                    <div className="py-3 space-y-3">
                      <div className="p-3">
                        <div className="text-lg">Product Title *</div>
                        <div className="mb-3 text-sm">
                          The title of the product as it will appear in the system.
                        </div>
                        <InputField
                          error={formState.errors['product_title']}
                          registration={register('product_title')}
                          required={true}
                        />
                      </div>
                      <div className="flex justify-center">
                        <div className="grid grid-cols-1 gap-4">
                          <div className="p-3">
                            <div className="text-lg">Principal Amount</div>
                            <div className="mb-3 text-sm">
                              The principal amount that can be borrowed using this product.
                            </div>
                            <div className="flex flex-row items-center space-x-2">
                              <InputField
                                label="Minimum (UGX)"
                                error={formState.errors['principal_min']}
                                registration={register('principal_min')}
                                required={true}
                              />
                              <InputField
                                label="Maximum (UGX)"
                                error={formState.errors['principal_max']}
                                registration={register('principal_max')}
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="p-3">
                            <div className="text-lg">Interest Rate</div>
                            <div className="mb-3 text-sm">
                              The interest rate that will be charged on the principal amount.
                            </div>
                            <div className="flex flex-row items-center space-x-2">
                              <InputField
                                label="Minimum (%) *"
                                required={true}
                                error={formState.errors['interest_rate_min']}
                                registration={register('interest_rate_min')}
                              />
                              <InputField
                                label="Maximum (%) *"
                                required={true}
                                error={formState.errors['interest_rate_max']}
                                registration={register('interest_rate_max')}
                              />
                            </div>
                          </div>

                          <div className="p-3">
                            <div className="text-lg">Loan Term</div>
                            <div className="mb-3 text-sm">
                              The period of time that the loan will be repaid.
                            </div>
                            <div className="flex flex-row items-center space-x-2">
                              <InputField
                                label="Minimum (months) *"
                                required={true}
                                error={formState.errors['period_min']}
                                registration={register('period_min')}
                              />
                              <InputField
                                label="Maximum (months) *"
                                required={true}
                                error={formState.errors['period_max']}
                                registration={register('period_max')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <div className="text-lg">Acceptable collateral assets *</div>
                        <div className="mb-3 text-sm">
                          The assets that can be used as collateral when borrowing using this
                          product.
                        </div>
                        <ReactMultiSelectField
                          registration={register('acceptable_collaterals')}
                          editorContent={watch('acceptable_collaterals')}
                          setValue={setValue}
                          isMulti={true}
                          name="acceptable_collaterals"
                          defaultValue={watch('acceptable_collaterals')}
                          options={[
                            {
                              label: 'Real Estate With Land Title',
                              value: 'REAL_ESTATE_WITH_LAND_TITLE',
                            },
                            { label: 'Vehicles', value: 'VEHICLES' },
                            { label: 'Computers', value: 'COMPUTER' },
                            { label: 'Television', value: 'TELEVISION' },
                            { label: 'Smart Phones', value: 'SMART_PHONES' },
                            { label: 'Other Electronics', value: 'OTHER_ELECTRONICS' },
                          ]}
                        />
                      </div>

                      <div className="">
                        <div className="text-lg">Early repayment fee (%) *</div>
                        <div className="mb-3 text-sm">
                          The fee that will be charged when the loan is repaid before the due date.
                        </div>

                        <InputField
                          required={true}
                          error={formState.errors['early_repayment_fee']}
                          registration={register('early_repayment_fee')}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="text-lg">Average lending rate (%) *</div>
                      <div className="mb-3 text-sm">
                        The average rate at which the loan will be lent out. This rate is fixed at
                        the moment.
                      </div>
                      <InputField
                        required={true}
                        error={formState.errors['average_lending_rate']}
                        registration={register('average_lending_rate')}
                      />
                    </div>

                    <div className="flex flex-row justify-end mb-4">
                      <div className="flex flex-row items-center space-x-2">
                        <Button
                          type="submit"
                          className="bg-primary-100 text-white"
                          isLoading={createProductMutation.isLoading}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            </>
          )}

          {serviceFees && (
            <>
              <Form
                id="create-service-fee"
                onSubmit={async (values) => {
                  await createServiceFeeMutation.mutateAsync({
                    data: values,
                    id: localStorage.getItem('create_product_product_id'),
                  });
                }}
                className="mt-3"
              >
                {({ register, formState, watch, setValue }) => (
                  <div>
                    <div className="py-3 space-y-3">
                      <div className="text-lg">Add a service fee to your product</div>
                      <div className="mb-3 text-sm">
                        Add a service fee to your product to be charged on the principal amount.
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                        <div className="p-1">
                          <div className="text-md">Name</div>
                          <InputField
                            error={formState.errors['name']}
                            registration={register('name')}
                            required={true}
                          />
                        </div>
                        <div className="p-1">
                          <div className="text-md">Minimum Amount</div>
                          <InputField
                            error={formState.errors['min']}
                            registration={register('min')}
                            required={true}
                          />
                        </div>
                        <div className="p-1">
                          <div className="text-md">Maximum Amount</div>
                          <InputField
                            error={formState.errors['max']}
                            registration={register('max')}
                            required={true}
                          />
                        </div>
                        <div className="p-1">
                          <div className="text-md">Service Fee Percentage</div>
                          <InputField
                            error={formState.errors['percentage']}
                            registration={register('percentage')}
                            required={true}
                          />
                        </div>
                        <div className="p-1 mt-6">
                          <Button type="submit">Create service fee</Button>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row justify-end mb-4">
                      <div className="flex flex-row items-center space-x-2 mr-6">
                        <Button
                          type="button"
                          className="px-8"
                          isLoading={createProductMutation.isLoading}
                          onClick={() => {
                            setLateFees(true);
                            setServiceFees(false);
                          }}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            </>
          )}
          {lateFees && (
            <>
              <Form
                id="create-late-fee"
                onSubmit={async (values) => {
                  await createLateFeeMutation.mutateAsync({
                    data: values,
                    id: localStorage.getItem('create_product_product_id'),
                  });
                }}
                className="mt-3"
              >
                {({ register, formState, watch, setValue }) => (
                  <div>
                    <div className="py-3 space-y-3">
                      <div className="text-lg">Add a late fee to your product</div>
                      <div className="mb-3 text-sm">
                        Add a late fee to your product to be charged on the principal amount.
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                        <div className="p-1">
                          <div className="text-md">Name</div>
                          <InputField
                            error={formState.errors['name']}
                            registration={register('name')}
                            required={true}
                          />
                        </div>
                        <div className="p-1">
                          <div className="text-md">Minimum Amount</div>
                          <InputField
                            error={formState.errors['min']}
                            registration={register('min')}
                            required={true}
                          />
                        </div>
                        <div className="p-1">
                          <div className="text-md">Maximum Amount</div>
                          <InputField
                            error={formState.errors['max']}
                            registration={register('max')}
                            required={true}
                          />
                        </div>
                        <div className="p-1">
                          <div className="text-md">Late Fee Percentage</div>
                          <InputField
                            error={formState.errors['percentage']}
                            registration={register('percentage')}
                            required={true}
                          />
                        </div>
                        <div className="p-1 mt-6">
                          <Button type="submit">Create Late Fee</Button>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row justify-end mb-4">
                      <div className="flex flex-row items-center space-x-2 mr-6">
                        <Button
                          type="button"
                          className="px-8"
                          isLoading={createProductMutation.isLoading}
                          onClick={() => {
                            setLateFees(false);
                            setServiceFees(false);
                            localStorage.removeItem('create-product');
                            navigate('/app/products');
                          }}
                        >
                          Finish
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            </>
          )}
        </div>
      </div>
    </>
  );
};
