import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const updateLateFeeRange = ({ data, id, lateFeeId }) => {
  return axios.patch(`/products/${id}/late-fees/${lateFeeId}`, data);
};

export const useUpdateLateFeeRange = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (updatingProduct) => {
      await queryClient.cancelQueries(['product', updatingProduct?.id]);

      const previousProduct = queryClient.getQueryData[('product', updatingProduct?.id)];

      queryClient.setQueryData(['product', updatingProduct?.id], {
        ...previousProduct,
        ...updatingProduct.data,
        id: updatingProduct.id,
      });

      return { previousProduct };
    },
    onError: (_, __, context) => {
      if (context?.previousProduct) {
        queryClient.setQueryData(['product', context.previousProduct.id], context.previousProduct);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['product', data._id]);
      addNotification({
        type: 'success',
        title: 'Late Fee updated',
      });
    },
    ...config,
    mutationFn: updateLateFeeRange,
  });
};
