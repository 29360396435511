import React from 'react';
import { FieldWrapper } from './FieldWrapper';
import Autocomplete from 'react-google-autocomplete';
import { REACT_APP_GOOGLE_MAPS_API } from 'config';

export const LocationField = ({ label, onChange, error }) => {
  const handlePlaceSelect = (place) => {
    if (place && place.formatted_address) {
      onChange(place.formatted_address);
    }
  };

  return (
    <FieldWrapper label={label} error={error}>
      <Autocomplete
        apiKey={REACT_APP_GOOGLE_MAPS_API}
        onPlaceSelected={handlePlaceSelect}
        className="appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
      />
    </FieldWrapper>
  );
};
