import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getServiceProviders = () => {
  return axios.get(`/service-providers`);
};

export const useServiceProviders = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['serviceProviders'],
    queryFn: () => getServiceProviders(),
  });
};
