import React from 'react';
import { ConfirmVideoCall } from './ConfirmVideoCall';
import { CounterVideoCall } from './CounterVideoCall';
import { formatText } from 'utils/formatText';
import { SendMeetingLink } from './SendMeetingLink';

export const IntroductoryVideoCall = ({ loanId, scheduledDates }) => {
  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-md">
      <div className="flex flex-row items-center justify-between">
        <h2 className="text-md font-semibold mb-4">Introductory Video Call Scheduled Dates</h2>
        <CounterVideoCall id={loanId} />
      </div>
      {scheduledDates?.map((item) => (
        <div key={item._id} className="mb-4">
          <di className="text-xs font-semibold mb-2 rounded-full p-1 px-2 bg-gray-200">
            {formatText(item.status)}
          </di>
          {item?.dates?.map((dateItem) => (
            <div key={dateItem._id} className="mb-2 flex flex-row items-center justify-between">
              <div>
                <p className="font-medium mb-1">{new Date(dateItem?.date)?.toDateString()}</p>
                <ul>
                  {dateItem?.time_slots?.map((slot, index) => (
                    <li key={index}>
                      <span className="font-semibold">Time:</span>{' '}
                      {`${slot?.hours}:${
                        slot?.minutes < 10 ? `0${slot?.minutes}` : slot?.minutes
                      } ${slot?.meridiem}`}
                    </li>
                  ))}
                </ul>
              </div>
              {item.status === 'CONFIRMED' && <SendMeetingLink id={loanId} />}
              {item.status === 'SUBMITTED' && <ConfirmVideoCall id={loanId} date={dateItem.date} />}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
