import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getAssets = () => {
  return axios.get('/collaterals');
};

export const useAssets = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['assets'],
    queryFn: () => getAssets(),
  });
};
