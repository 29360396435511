import React, { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { CheckboxField, Form, ReactSelectField } from 'components/Form';
import { Button, Spinner } from 'components/Elements';
import { PaymentSchedule } from '../components/PaymentSchedule';
import SignatureCanvas from 'react-signature-canvas';
import SuccessImg from 'assets/success.svg';
import { useProducts } from 'features/products';
import { PigeonTips } from '../components/PigeonTips';
import { useMyAssets } from 'features/assets';
import { useCreateLoan } from '../api/createLoan';
import { calculateLoanTerm } from 'utils/calculateLoanTerm';
import { Borrow } from '../tips/Borrow';

export const PigeonLoans = () => {
  const [step, setStep] = useState(1);

  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const sigPadRef = useRef(null);

  const productsQuery = useProducts();

  const createLoanMutation = useCreateLoan();

  const myAssetsQuery = useMyAssets();

  const navigate = useNavigate();

  const [complete, setComplete] = useState(false);
  const [interestRate, setInterestRate] = useState();
  const [serviceFee, setServiceFee] = useState();
  const [amount, setAmount] = useState();
  const [monthlyPayment, setMonthlyPayment] = useState();
  const [assessmentFee, setAssessmentFee] = useState();
  const [term, setTerm] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [averageLendingRate, setAverageLendingRate] = useState();
  const [guarantorName, setGuarantorName] = useState();
  const [guarantorPhoneNumber, setGuarantorPhoneNumber] = useState();
  const [guarantorEmail, setGuarantorEmail] = useState();
  const [collateralCategory, setCollateralCategory] = useState();
  const [collaterals, setCollaterals] = useState();
  const [purpose, setPurpose] = useState();

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [product, setProduct] = useState();

  useEffect(() => {
    if (product && collateralCategory) {
      const selectedProduct = productsQuery.data?.find((p) => p._id === product);

      function computeTotalAssessmentFees(category) {
        let totalFees = 0;

        if (category === 'real_estate_with_land_title') {
          category = 'real_estate';
        }

        // Check if the category exists
        if (selectedProduct?.applicable_fees?.hasOwnProperty(category)) {
          const fees = selectedProduct?.applicable_fees[category];

          // Iterate through each fee type in the category
          for (const feeType in fees) {
            if (typeof fees[feeType] === 'object') {
              // If it's a nested object, sum up the fees inside
              for (const fee in fees[feeType]) {
                totalFees += fees[feeType][fee];
              }
            } else {
              // If it's a single fee, add it directly
              totalFees += fees[feeType];
            }
          }
        } else {
          console.log('Category not found.');
        }

        return totalFees;
      }

      setServiceFee(selectedProduct?.service_fee);
      setAverageLendingRate(selectedProduct?.average_lending_rate);
      const totalAssessmentFees = computeTotalAssessmentFees(collateralCategory?.toLowerCase());
      setAssessmentFee(totalAssessmentFees);
    }
  }, [product]);

  // useEffect(() => {

  // }, [amount, interestRate, monthlyPayment]);

  const clear = () => {
    sigPadRef.current.clear();
  };

  const trim = () => {
    const trimmedData = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
    setTrimmedDataURL(trimmedData);
  };

  // const didOnBeforeChangeTrigger = (value) => {
  //   console.log(value);
  // };

  // const didOnAfterChangeTrigger = (value) => {
  //   setInterestRate(value);
  //   const loanTermInMonths = calculateLoanTerm(amount, interestRate, monthlyPayment);
  //   // console.log(loanTermInMonths);
  //   setTerm(loanTermInMonths);
  // };

  const handleAmountChange = (event) => {
    let inputValue = event.target.value;
    // Remove any non-numeric characters
    inputValue = inputValue.replace(/[^0-9]/g, '');

    // Convert input to a number
    let amount = parseInt(inputValue);

    const selectedProduct = productsQuery.data?.find((p) => p._id === product);

    // Check if the input is within the desired range
    if (inputValue < selectedProduct.principal?.min) {
      setAmount(selectedProduct.principal?.min);
    } else if (inputValue > selectedProduct.principal?.max) {
      setAmount(selectedProduct.principal?.max);
    }
    setAmount(event.target.value);
  };

  const handleGuarantorName = (event) => {
    setGuarantorName(event.target.value);
  };

  const handleGuarantorEmail = (event) => {
    setGuarantorEmail(event.target.value);
  };

  const handleGuarantorPhoneNumber = (event) => {
    setGuarantorPhoneNumber(event.target.value);
  };

  const handleMonthlyPaymentChange = (event) => {
    setMonthlyPayment(event.target.value);
  };

  const handleInterestRateChange = (event) => {
    setInterestRate(event.target.value);

    const loanTermInMonths = calculateLoanTerm(amount, event.target.value, monthlyPayment);
    setTerm(loanTermInMonths);
  };

  const handlePurpose = (event) => {
    setPurpose(event.target.value);
  };

  if (myAssetsQuery.isLoading || productsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  if (!productsQuery.data) return null;

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const goToStep = (stepNumber) => {
    setStep(stepNumber);
  };

  const steps = [
    'Payment Method',
    'Loan Details',
    'Guarantor',
    'Collateral Assets',
    'Terms and Conditions',
    'Signature',
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <PigeonTips />
      <div className="py-12">
        <Form
          id="create-loan"
          onSubmit={async (values) => {
            // add paymentMethod to values
            values.paymentMethod = paymentMethod;
            values.purpose = purpose;
            values.amount = amount;
            values.product = product;
            values.interestRate = interestRate;
            values.monthlyPayment = monthlyPayment;
            values.guarantor = {
              name: guarantorName,
              email: guarantorEmail,
              phoneNumber: guarantorPhoneNumber,
            };
            values.collateralCategory = collateralCategory;
            values.collateral = collaterals;
            values.signature = trimmedDataURL;
            values.acceptTerms = acceptTerms;
            const bodyFormData = new FormData();

            for (const key in values) {
              if (key === 'guarantor') {
                for (const subKey in values[key]) {
                  bodyFormData.append(`guarantor.${subKey}`, values[key][subKey]);
                }
              } else {
                bodyFormData.append(key, values[key]);
              }
            }
            await createLoanMutation.mutateAsync({ data: bodyFormData });
            setComplete(true);
            navigate('/app');
          }}
        >
          {({ register, formState, watch, setValue }) => (
            <>
              <div className="flex justify-center space-x-4 mb-4">
                {/* <div className={`flex-1 h-0.5 bg-gray-300 my-auto`} /> */}
                {steps.map((label, index) => (
                  <div key={index} className="flex flex-col items-center space-y-2">
                    <button
                      type="button"
                      className={`flex items-center justify-center h-8 w-8 rounded-full ${
                        step === index + 1
                          ? 'bg-primary-100 text-white'
                          : 'bg-gray-300 text-gray-500'
                      }`}
                      onClick={() => goToStep(index + 1)}
                    >
                      {index + 1}
                    </button>
                    <div className="whitespace-nowrap text-xs hidden md:block">{label}</div>
                  </div>
                ))}
              </div>
              {step === 1 && (
                <>
                  <div className=" h-screen px-12 md:px-24 pt-12">
                    <div className="">
                      <div className="text-lg mb-6">Select a payment method</div>
                      <button
                        disabled
                        className="border w-full border-gray-300 rounded-2xl p-8 mb-4"
                      >
                        <div className="text-gray-600">Fixed Flat Method</div>
                        <div className="text-gray-500 text-sm">
                          Recommended for small loan amounts
                        </div>
                      </button>
                      <button
                        onClick={() => {
                          setPaymentMethod('DECLINING_BALANCE');
                          setStep(step + 1);
                        }}
                        className="bg-primary-100 w-full rounded-2xl p-8"
                      >
                        <div className="text-white">Declining Balance Method</div>
                        <div className="text-gray-200 text-sm">
                          Recommended for small loan amounts
                        </div>
                      </button>
                    </div>
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <div className="px-12 md:px-24 pt-12">
                    <div className="text-lg mb-6">Provide your loan details</div>
                    <>
                      <ReactSelectField
                        label="Collateral Category"
                        registration={register('collateralCategory')}
                        type="text"
                        editorContent={watch('collateralCategory')}
                        setValue={setValue}
                        name="collateralCategory"
                        defaultValue={watch('collateralCategory')}
                        error={formState.errors['collateralCategory']}
                        options={[
                          { label: 'Real Estate', value: 'REAL_ESTATE_WITH_LAND_TITLE' },
                          { label: 'Vehicles', value: 'VEHICLES' },
                          { label: 'Computers', value: 'COMPUTERS' },
                          { label: 'Smart Phones', value: 'SMART_PHONE' },
                          { label: 'Television', value: 'TELEVISION' },
                          { label: 'Other Electronics', value: 'OTHER_ELECTRONICS' },
                        ]}
                        onChange={(e) => {
                          setCollateralCategory(e.value);
                        }}
                      />
                      <ReactSelectField
                        label="Please select Product"
                        type="text"
                        editorContent={watch('product')}
                        setValue={setValue}
                        name="product"
                        defaultValue={product}
                        error={formState.errors['product']}
                        options={productsQuery.data?.map((p) => ({
                          label: p.product_title,
                          value: p._id,
                        }))}
                        onChange={(e) => {
                          setProduct(e.value);
                          const selectedProduct = productsQuery.data?.find(
                            (p) => p._id === product
                          );
                          console.log(product);
                          function computeTotalAssessmentFees(category) {
                            let totalFees = 0;

                            if (category === 'real_estate_with_land_title') {
                              category = 'real_estate';
                            }

                            // Check if the category exists
                            if (selectedProduct?.applicable_fees?.hasOwnProperty(category)) {
                              const fees = selectedProduct?.applicable_fees[category];

                              // Iterate through each fee type in the category
                              for (const feeType in fees) {
                                if (typeof fees[feeType] === 'object') {
                                  // If it's a nested object, sum up the fees inside
                                  for (const fee in fees[feeType]) {
                                    totalFees += fees[feeType][fee];
                                  }
                                } else {
                                  // If it's a single fee, add it directly
                                  totalFees += fees[feeType];
                                }
                              }
                            } else {
                              console.log('Category not found.');
                            }

                            return totalFees;
                          }

                          setServiceFee(selectedProduct?.service_fee);
                          setAverageLendingRate(selectedProduct?.average_lending_rate);
                          const totalAssessmentFees = computeTotalAssessmentFees(
                            collateralCategory?.toLowerCase()
                          );
                          console.log('totalAssessmentFees', totalAssessmentFees);
                          setAssessmentFee(totalAssessmentFees);
                        }}
                      />

                      <div className="">
                        <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
                          What are you going to do with the money?
                        </div>
                        <textarea
                          rows="4"
                          value={purpose}
                          className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          onChange={handlePurpose}
                        ></textarea>
                      </div>

                      <div className="">
                        <div className="flex flex-row items-center space-x-4 mt-3">
                          <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                            How much do you want to borrow? (UGX)
                          </div>
                          {product && (
                            <Borrow
                              minAmount={productsQuery.data[0].principal.min}
                              maxAmount={productsQuery.data[0].principal.max}
                            />
                          )}
                        </div>

                        <input
                          type="text"
                          value={amount}
                          className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          onChange={handleAmountChange}
                        />
                      </div>

                      <div className="">
                        <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
                          How much can you pay back every month? (UGX)
                        </div>
                        <input
                          type="text"
                          value={monthlyPayment}
                          className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          onChange={handleMonthlyPaymentChange}
                        />
                      </div>

                      {product ? (
                        <div className="flex flex-col items-center justify-center">
                          {amount ? (
                            <div className="grid grid-cols-2 mb-2 gap-2 md:gap-8">
                              <div className="">Service Fee:</div>
                              <div className="">
                                {(amount ? (serviceFee / 100) * amount : null)?.toLocaleString()}{' '}
                                UGX
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="grid grid-cols-2 mb-2 gap-2 md:gap-8">
                            <div className="">Assessment Fee:</div>
                            <div className="">{assessmentFee?.toLocaleString()} UGX</div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* {interestRate ? (
                        <div className="flex flex-col items-center justify-start mb-4">
                          <div className="grid grid-cols-2 gap-3 md:gap-8">
                            <div className="">Monthly Rate:</div>
                            <div className="">{interestRate} %</div>
                          </div>
                          <div className="grid grid-cols-2 gap-3 md:gap-8">
                            <div className="flex justify-end">Time:</div>
                            <div className="">{term} months</div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )} */}
                      {/* <SliderField
                        label="How much interest are you offering monthly? (%)"
                        min={0}
                        max={10}
                        defaultValue={interestRate ? interestRate : 6.5}
                        onBeforeChange={didOnBeforeChangeTrigger}
                        onChangeComplete={didOnAfterChangeTrigger}
                      /> */}

                      <div className="">
                        <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
                          How much interest are you offering monthly? (%)
                        </div>
                        <input
                          type="number"
                          value={interestRate}
                          className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          onChange={handleInterestRateChange}
                        />
                      </div>

                      <div className="mx-0 md:mx-32 flex flex-col space-y-3">
                        {product ? (
                          <div className="bg-primary-100 p-2 rounded-2xl">
                            <div className="text-gray-900 text-center">
                              Current Network Average Lending Rate
                            </div>
                            <div className="text-white text-center">
                              {averageLendingRate} per month
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {interestRate ? (
                          <PaymentSchedule
                            amount={amount}
                            interestRate={interestRate}
                            term={term}
                            monthlyPayment={monthlyPayment}
                          />
                        ) : (
                          <></>
                        )}
                        <div className="flex flex-row items-center space-x-2">
                          <Button variant="outline" onClick={prevStep} className="w-full">
                            Previous
                          </Button>
                          <button>
                            <Button variant="dark" onClick={nextStep} className="w-full">
                              Next
                            </Button>
                          </button>
                        </div>
                      </div>
                    </>
                  </div>
                </>
              )}
              {step === 3 && (
                <>
                  <div className="px-12 md:px-24 pt-12">
                    <div className="text-lg mb-6">Provide your guarantor details</div>
                    <>
                      <div className="">
                        <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
                          Full Name
                        </div>
                        <input
                          type="text"
                          value={guarantorName}
                          className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          onChange={handleGuarantorName}
                        />
                      </div>
                      <div className="">
                        <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
                          Phone Number
                        </div>
                        <input
                          type="text"
                          value={guarantorPhoneNumber}
                          className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          onChange={handleGuarantorPhoneNumber}
                          placeholder="0782692271"
                        />
                      </div>
                      <div className="">
                        <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
                          Email
                        </div>
                        <input
                          type="text"
                          value={guarantorEmail}
                          className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          onChange={handleGuarantorEmail}
                        />
                      </div>
                      <div className="mx-0 md:mx-32 flex flex-col space-y-3">
                        <div className="flex flex-row items-center space-x-2">
                          <Button variant="outline" onClick={prevStep} className="w-full">
                            Previous
                          </Button>
                          <Button variant="dark" onClick={nextStep} className="w-full">
                            Next
                          </Button>
                        </div>
                        {/* <SkipGuarantor /> */}
                      </div>
                    </>
                  </div>
                </>
              )}
              {step === 4 && (
                <>
                  <div className="px-12 md:px-24 pt-12 m">
                    <div className="text-lg mb-6">Select your collateral Assets</div>
                    <>
                      <ReactSelectField
                        label="Select from your assets"
                        registration={register('collateral')}
                        options={
                          myAssetsQuery.data?.map((asset) => ({
                            label: asset.title,
                            value: asset._id,
                          })) || []
                        }
                        editorContent={watch('collateral')}
                        setValue={setValue}
                        name="collateral"
                        isMulti={true}
                        defaultValue={watch('collateral')}
                        error={formState.errors['collateral']}
                        onChange={(e) => {
                          setCollaterals(e.map((item) => item.value));
                        }}
                      />
                      {/* <button className="border w-full border-gray-500 bg-gray-100 rounded-lg p-4 flex flex-row items-center space-x-4">
                        <PlusIcon className="h-6 w-6" />
                        <div className="text-gray-600">New Collateral Asset</div>
                      </button> */}
                      <div className="mx-0 md:mx-32 flex flex-col space-y-3 mt-4">
                        <div className="flex flex-row items-center space-x-2">
                          <Button variant="outline" onClick={prevStep} className="w-full">
                            Previous
                          </Button>
                          <Button variant="dark" onClick={nextStep} className="w-full">
                            Next
                          </Button>
                        </div>
                      </div>
                    </>
                  </div>
                </>
              )}
              {step === 5 && (
                <>
                  <div className="px-12 md:px-24 pt-12">
                    <div className="text-lg mb-6">Review your loan application</div>
                    <div className="border border-gray-700 rounded-md shadow bg-white p-6">
                      <div className="rounded-xl border border-gray-200 mb-4">
                        <div className="bg-primary-100 p-2 rounded-t-xl text-white">
                          Loan Details
                        </div>
                        <div className="p-4">
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                            <div className="text-xs md:text-sm">Payment Method</div>
                            <div className="text-sm md:text-md font-semibold">{paymentMethod}</div>
                          </div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                            <div className="text-xs md:text-sm">Loan Amount Receiving</div>
                            <div className="text-sm md:text-md font-semibold">{amount} UGX</div>
                          </div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                            <div className="text-xs md:text-sm">Monthly Installment</div>
                            <div className="text-sm md:text-md font-semibold">
                              {monthlyPayment} UGX
                            </div>
                          </div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                            <div className="text-xs md:text-sm">Interest Rate</div>
                            <div className="text-sm md:text-md font-semibold">{interestRate}</div>
                          </div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                            <div className="text-xs md:text-sm">Duration</div>
                            <div className="text-sm md:text-md font-semibold">{term} months</div>
                          </div>
                        </div>
                      </div>

                      <div className="rounded-xl border border-gray-200 mb-4">
                        <div className="bg-primary-100 p-2 rounded-t-xl text-white">Guarantor</div>
                        <div className="p-4">
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                            <div className="text-xs md:text-sm">Name</div>
                            <div className="font-semibold text-sm md:text-md">{guarantorName}</div>
                          </div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                            <div className="text-xs md:text-sm">Phone Number</div>
                            <div className="font-semibold text-sm md:text-md">
                              {guarantorPhoneNumber}
                            </div>
                          </div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                            <div className="text-xs md:text-sm">Email Address</div>
                            <div className="font-semibold text-sm md:text-md overflow-hidden">
                              {guarantorEmail}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="rounded-xl border border-gray-200 mb-4">
                        <div className="bg-primary-100 p-2 rounded-t-xl text-white">
                          Collateral Assets
                        </div>
                        {collaterals?.map((collateral) => (
                          <div>{collateral} </div>
                        ))}
                      </div> */}

                      <div
                        className="rounded-xl border border-gray-200 mb-4"
                        style={{ height: '300px', overflow: 'hidden', overflowY: 'scroll' }}
                      >
                        <div className="bg-primary-100 p-2 rounded-t-xl text-white">
                          Terms and Conditions
                        </div>
                        <div className="p-4">
                          <div className="mb-2 text-sm md:text-md">
                            <b>1. Introduction:</b> These terms govern the lending activities
                            conducted through the Finablr platform operated by Enabling Finance
                            Limited.
                          </div>

                          <div className="mb-2 text-sm md:text-md">
                            <b>2. Legal and Regulatory Information:</b> EFL is registered in Uganda
                            and authorized by the Uganda Microfinance Regulatory Authority. The
                            company manages the platform connecting lenders and borrowers, oversees
                            loan agreements, and handles repayments.
                          </div>
                          <div className="mb-2 text-sm md:text-md">
                            <b>3. Lender Representations and Warranties:</b> Lenders must meet
                            certain criteria including being licensed in Uganda or consenting to
                            lending under the platform's terms. They must also understand the risks
                            involved and comply with relevant laws.
                          </div>
                          <div className="mb-2 text-sm md:text-md">
                            <b>4. Company Representation on Behalf of Lender:</b> The company acts
                            as an agent for lenders in managing loan agreements and recovery
                            processes.
                          </div>
                          <div className="mb-2 text-sm md:text-md">
                            <b>5. Provision Fund:</b> A discretionary fund is available to lenders
                            in case of borrower default, but compensation is not guaranteed.
                          </div>
                          <div className="mb-2 text-sm md:text-md">
                            <b>6. Costs and Charges:</b> EFL charges commissions on interest and
                            late fees based on the lender's licensing status.
                          </div>
                          <div className="mb-2 text-sm md:text-md">
                            <b>7. Collection and Company Recovery on Delinquent Loans:</b> EFL makes
                            efforts to minimize delinquency and may take time to prepare collateral
                            for disposal in case of default.
                          </div>
                          <div className="mb-2 text-sm md:text-md">
                            <b>8. Collection/Collection Account:</b> Funds are held in trust in a
                            designated account and disbursed accordingly.
                          </div>
                          <div className="mb-2 text-sm md:text-md">
                            <b>9. Holding of Lenders' Funds by Company:</b> Lenders may opt to have
                            their funds managed by the company for efficiency, with the ability to
                            recall funds upon notice.
                          </div>
                          <div className="mb-2 text-sm md:text-md">
                            <b>10. Risk Associated:</b> Lending involves risks, and EFL does not
                            guarantee repayment. Collateral is held to mitigate losses, and in case
                            of loss, detailed explanations are provided.
                          </div>
                          <div className="mb-2 text-sm md:text-md">
                            <b>11. Term and Termination:</b> Terms can be terminated by either party
                            with notice, and funds are returned upon termination.
                          </div>
                          <div className="mb-2 text-sm md:text-md">
                            <b>12. Dispute Resolution, Complaints, and Communications:</b> Disputes
                            are resolved according to Ugandan law, with avenues for complaints and
                            communications provided by the company.
                          </div>

                          <div className="mb-2 text-sm md:text-md">
                            By using the Finablr platform, users confirm their agreement to these
                            terms and conditions.
                          </div>
                        </div>
                        <div className="m-2">
                          <CheckboxField
                            label="Accept Terms and Conditions"
                            registration={register('acceptTerms')}
                            error={formState.errors['acceptTerms']}
                            onChange={(e) => setAcceptTerms(true)}
                          />
                        </div>
                      </div>
                      <div className="mx-0 md:mx-32 flex flex-col space-y-3">
                        <div className="flex flex-row items-center space-x-2">
                          <Button variant="outline" onClick={prevStep} className="w-full">
                            Previous
                          </Button>
                          <Button
                            disabled={acceptTerms ? false : true}
                            variant="dark"
                            onClick={nextStep}
                            className="w-full"
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {step === 6 && (
                <>
                  <div className="px-12 md:px-24 pt-12">
                    {complete ? (
                      <div className="flex flex-col items-center space-y-2">
                        <div className="">
                          <img src={SuccessImg} alt="Success" />
                        </div>
                        <div className="font-bold text-xl">Awesome</div>
                        <div className="">
                          Your loan request has been successfully submitted. Expect to receive
                          notifications as soon as your loan gets approved, along with offers.
                        </div>
                        <Button onClick={() => navigate('/app/my-loan')}>Close</Button>
                      </div>
                    ) : (
                      <>
                        <div className="text-lg mb-6">Digital Signature For Loan Contracts</div>
                        <div className="mb-4">
                          Please use the canvas below to capture your digital signature. This
                          signature will be utilized on loan contracts.
                        </div>
                        <div className="border border-gray-200 rounded-2xl shadow bg-white mb-4 p-4">
                          <div>
                            {trimmedDataURL ? (
                              <img alt="signature" src={trimmedDataURL} />
                            ) : (
                              <SignatureCanvas
                                penColor="darkblue"
                                canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                ref={sigPadRef}
                              />
                            )}
                          </div>
                          {!trimmedDataURL && (
                            <div className="flex flex-row items-center space-x-2">
                              <Button size="sm" type="button" variant="outline" onClick={clear}>
                                Clear
                              </Button>
                              <Button size="sm" type="button" onClick={trim}>
                                Confirm Signautre
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className="mx-0 md:mx-32 flex flex-col space-y-3">
                          <div className="flex flex-row items-center space-x-2">
                            <Button variant="outline" onClick={prevStep} className="w-full">
                              Previous
                            </Button>
                            <Button variant="dark" type="submit" className="w-full">
                              Finish
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </Form>
      </div>
    </div>
  );
};
