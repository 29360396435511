import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const approveRepayment = ({ data, id }) => {
  return axios.post(`/repayments/${id}/approve`, data);
};

export const useApproveRepayment = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (approvingRepayment) => {
      await queryClient.cancelQueries(['repayment', approvingRepayment?.id]);

      const previousRepayment = queryClient.getQueryData(['repayment', approvingRepayment?.id]);

      queryClient.setQueryData(['repayment', approvingRepayment?.id], {
        ...previousRepayment,
        ...approvingRepayment.data,
        id: approvingRepayment.id,
      });

      return { previousRepayment };
    },
    onError: (_, __, context) => {
      if (context?.previousRepayment) {
        queryClient.setQueryData(
          ['repayment', context.previousRepayment.id],
          context.previousRepayment
        );
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['repayment', data._id]);
      addNotification({
        type: 'success',
        title: 'Repayment Approved',
      });
    },
    ...config,
    mutationFn: approveRepayment,
  });
};
