import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { Authorization, ROLES } from 'lib/authorization';
import { TrashIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

import { useDeleteProduct } from '../api/deleteProduct';

export const DeleteProduct = ({ id }) => {
  const deleteProductMutation = useDeleteProduct();

  if (deleteProductMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.SUPER_ADMIN]}>
      <ConfirmationDialog
        icon="danger"
        title="Remove Product"
        body="Are you sure you want to remove this product?"
        triggerButton={
          <button>
            <TrashIcon className="h-4 w-4" />
          </button>
        }
        confirmButton={
          <Button
            isLoading={deleteProductMutation.isLoading}
            type="button"
            className="bg-red-600"
            onClick={async () => await deleteProductMutation.mutateAsync({ id: id })}
          >
            Trash
          </Button>
        }
      />
    </Authorization>
  );
};

DeleteProduct.propTypes = {
  id: PropTypes.string.isRequired,
};
