import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { AssetsList } from '../components/AssetsList';
import { FilePlus } from 'react-feather';

export const Assets = () => {
  const routes = [
    {
      name: 'Asset Center',
      path: '/app/assets',
    },
  ];
  const navigate = useNavigate();

  return (
    <ContentLayout
      title="Asset Center"
      end={
        <Button onClick={() => navigate('/app/assets/create')} startIcon={<FilePlus size={16} />}>
          Create Finablr Asset
        </Button>
      }
    >
      <Breadcrumbs routes={routes} />
      <div className="mt-8">
        <AssetsList />
      </div>
    </ContentLayout>
  );
};
