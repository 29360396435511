import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { Authorization, ROLES } from 'lib/authorization';
import PropTypes from 'prop-types';
import { useRequestGuarantorVerification } from '../api/requestGuarantorVerification';

export const RequestGuarantorVerification = ({ phoneNumber, loanId }) => {
  const requestGuarantorVerificationMutation = useRequestGuarantorVerification();

  if (requestGuarantorVerificationMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}>
      <ConfirmationDialog
        icon="info"
        title="Request Guarantor Verification"
        body="Send a reminder to guarantor to verify as guarantor for loan application?"
        triggerButton={<Button>Request Verification</Button>}
        confirmButton={
          <Button
            isLoading={requestGuarantorVerificationMutation.isLoading}
            type="button"
            onClick={async () =>
              await requestGuarantorVerificationMutation.mutateAsync({
                data: { phoneNumber },
                loanId,
              })
            }
          >
            Send Request
          </Button>
        }
      />
    </Authorization>
  );
};

RequestGuarantorVerification.propTypes = {
  id: PropTypes.string.isRequired,
};
