export const calculateLoanTerm = (amount, interestRate, monthlyPayment) => {
  const monthlyInterestRate = interestRate / 100;
  let balance = amount;
  let term = 0;

  while (balance > 0) {
    const interest = balance * monthlyInterestRate;
    const payment = Math.min(monthlyPayment, balance + interest);
    balance -= payment - interest;
    term++;

    if (balance <= 0) break;
  }
  return term;
};
