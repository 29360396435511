import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, FormDrawer, InputField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PlusIcon } from '@heroicons/react/outline';

import { useCreateLateFee } from '../api/createLateFee';

export const CreateLateFee = ({ id }) => {
  const createLateFeeMutation = useCreateLateFee();

  if (createLateFeeMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.SUPER_ADMIN]}>
      <FormDrawer
        isDone={createLateFeeMutation.isSuccess}
        size="md"
        triggerButton={
          <Button size="sm" startIcon={<PlusIcon className="h-4 w-4" />}>
            Add Late Fee
          </Button>
        }
        title="Create Late Fee"
        submitButton={
          <Button
            form="create-late-fee"
            type="submit"
            size="sm"
            isLoading={createLateFeeMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="create-late-fee"
          onSubmit={async (values) => {
            await createLateFeeMutation.mutateAsync({ data: values, id });
          }}
        >
          {({ register, formState }) => (
            <>
              <InputField
                label="Name"
                error={formState.errors['name']}
                registration={register('name')}
              />
              <InputField
                label="Minimum Amount"
                error={formState.errors['min']}
                registration={register('min')}
              />
              <InputField
                label="Maximum Amount"
                error={formState.errors['max']}
                registration={register('max')}
              />
              <InputField
                label="Late Fee Percentage"
                error={formState.errors['percentage']}
                registration={register('percentage')}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};
