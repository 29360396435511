import { Navigate, Route, Routes } from 'react-router-dom';

import { Users } from './Users';
import { User } from './User';
import { Lenders } from './portfolios/Lenders';

export const UsersRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Users />} />
      <Route path=":id" element={<User />} />
      <Route path="portfolios" element={<Lenders />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
