import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const deleteRepayment = ({ id }) => {
  return axios.delete(`/repayments/${id}`);
};

deleteRepayment.propTypes = {
  id: PropTypes.string.isRequired,
};

export const useDeleteRepayment = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (deletedRepayment) => {
      await queryClient.cancelQueries('repayments');

      const previousRepayments = queryClient.getQueryData('repayments');

      queryClient.setQueryData(
        'repayments',
        previousRepayments?.filter((repayment) => repayment.id !== deletedRepayment.id)
      );

      return { previousRepayments };
    },
    onError: (_, __, context) => {
      if (context?.previousRepayments) {
        queryClient.setQueryData('repayments', context.previousRepayments);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('repayments');
      addNotification({
        type: 'success',
        title: 'Repayment Deleted',
      });
    },
    ...config,
    mutationFn: deleteRepayment,
  });
};
