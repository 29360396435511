import { Spinner } from 'components/Elements';
import { MainLayout } from 'components/Layout';
import { lazyImport } from 'utils/lazyImport';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const { AnalyticsRoutes } = lazyImport(() => import('features/analytics'), 'AnalyticsRoutes');
const { ProductsRoutes } = lazyImport(() => import('features/products'), 'ProductsRoutes');
const { LoansRoutes } = lazyImport(() => import('features/loans'), 'LoansRoutes');
const { AssetsRoutes } = lazyImport(() => import('features/assets'), 'AssetsRoutes');
const { RepaymentsRoutes } = lazyImport(() => import('features/repayments'), 'RepaymentsRoutes');
const { DueDiligenceRoutes } = lazyImport(
  () => import('features/dueDiligence'),
  'DueDiligenceRoutes'
);
const { ContractsRoutes } = lazyImport(() => import('features/contracts'), 'ContractsRoutes');
const { PigeonLoansRoutes } = lazyImport(() => import('features/pigeonLoans'), 'PigeonLoansRoutes');
const { ThreadsRoutes } = lazyImport(() => import('features/messages'), 'ThreadsRoutes');
const { Dashboard } = lazyImport(() => import('features/misc'), 'Dashboard');
const { NetYouReceive } = lazyImport(() => import('features/misc'), 'NetYouReceive');
const { Profile } = lazyImport(() => import('features/users'), 'Profile');
const { UsersRoutes } = lazyImport(() => import('features/users'), 'UsersRoutes');
const { OfficersRoutes } = lazyImport(() => import('features/officers'), 'OfficersRoutes');
const { GuarantorsRoutes } = lazyImport(() => import('features/guarantors'), 'GuarantorsRoutes');
const { SettingsRoutes } = lazyImport(() => import('features/settings'), 'SettingsRoutes');
const { ChangesRoutes } = lazyImport(() => import('features/changeLog'), 'ChangesRoutes');
const { ServiceProvidersRoutes } = lazyImport(
  () => import('features/serviceProviders'),
  'ServiceProvidersRoutes'
);
const { NotificationsRoutes } = lazyImport(
  () => import('features/notifications'),
  'NotificationsRoutes'
);
const { TipsRoutes } = lazyImport(() => import('features/tips'), 'TipsRoutes');
const { DirectorsRoutes } = lazyImport(() => import('features/directors'), 'DirectorsRoutes');
const { PostsRoutes } = lazyImport(() => import('features/posts'), 'PostsRoutes');
const { CompaniesRoutes } = lazyImport(() => import('features/companies'), 'CompaniesRoutes');
const { TransactionsRoutes } = lazyImport(
  () => import('features/transactions'),
  'TransactionsRoutes'
);

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: '/app/products/*', element: <ProductsRoutes /> },
      { path: '/app/loans/*', element: <LoansRoutes /> },
      { path: '/app/contracts/*', element: <ContractsRoutes /> },
      { path: '/app/assets/*', element: <AssetsRoutes /> },
      { path: '/app/repayments/*', element: <RepaymentsRoutes /> },
      { path: '/app/due-deligences/*', element: <DueDiligenceRoutes /> },
      { path: '/app/accounts/*', element: <UsersRoutes /> },
      { path: '/app/officers/*', element: <OfficersRoutes /> },
      { path: '/app/pigeon-loans/*', element: <PigeonLoansRoutes /> },
      { path: '/app/chats/*', element: <ThreadsRoutes /> },
      { path: '/app/analytics/*', element: <AnalyticsRoutes /> },
      { path: '/app/my-account', element: <Profile /> },
      { path: '/app/service-providers/*', element: <ServiceProvidersRoutes /> },
      { path: '/app/notifications/*', element: <NotificationsRoutes /> },
      { path: '/app/tips/*', element: <TipsRoutes /> },
      { path: '/app/changelog/*', element: <ChangesRoutes /> },
      { path: '/app/companies/*', element: <CompaniesRoutes /> },
      { path: '/app/transactions/*', element: <TransactionsRoutes /> },
      { path: '/app/directors/*', element: <DirectorsRoutes /> },
      { path: '/app/posts/*', element: <PostsRoutes /> },
      { path: '/app/guarantors/*', element: <GuarantorsRoutes /> },
      { path: '/app/settings/*', element: <SettingsRoutes /> },
      { path: '/app/', element: <Dashboard /> },
      { path: '/app/net-you-receive', element: <NetYouReceive /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];
