import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getNotification = ({ id }) => {
  return axios.get(`/notifications/${id}`);
};

export const useNotification = ({ id, config }) => {
  return useQuery({
    ...config,
    queryKey: ['notification', id],
    queryFn: () => getNotification({ id }),
  });
};
