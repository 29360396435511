import React from 'react';
import { useAddComment } from '../api/addComment';
import { Button } from 'components/Elements';
import { Form, RegularTextAreaField } from 'components/Form';
import { useParams } from 'react-router-dom';

export const AddComment = () => {
  const addCommentMutation = useAddComment();

  const { id } = useParams();

  return (
    <div className="mb-6">
      <Form
        id="create-comment"
        onSubmit={async (values) => {
          await addCommentMutation.mutateAsync({ data: values, loanId: id });
        }}
      >
        {({ register, formState }) => (
          <>
            <RegularTextAreaField
              error={formState.errors['comment']}
              registration={register('comment')}
            />
            <div className="flex flex-row justify-end items-end">
              <Button isLoading={addCommentMutation.isLoading} type="submit">
                Publish
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
