import { Button, ConfirmationDialog } from 'components/Elements';
import { useAuth } from 'lib/auth';
import PropTypes from 'prop-types';

import { useDeleteUser } from '../api/deleteUser';

import { Trash } from 'react-feather';

export const DeleteUser = ({ id }) => {
  const { user } = useAuth();
  const deleteUserMutation = useDeleteUser();

  if (user?._id === id) return null;

  return (
    <ConfirmationDialog
      icon="danger"
      title="Delete User"
      body="Are you sure you want to delete this user?"
      triggerButton={
        <button>
          <Trash className="text-red-500" size={20} />
        </button>
      }
      confirmButton={
        <Button
          isLoading={deleteUserMutation.isLoading}
          type="button"
          className="bg-red-600"
          onClick={() => deleteUserMutation.mutate({ userId: id })}
        >
          Delete User
        </Button>
      }
    />
  );
};

DeleteUser.propTypes = {
  id: PropTypes.string.isRequired,
};
