import { ContentLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import PropTypes from 'prop-types';

import { UpdateProfile } from '../components/UpdateProfile';
import { UPLOADS_URL } from 'config';
import { Button } from 'components/Elements';
import { CiPen } from 'react-icons/ci';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { UpdateAvatar } from '../components/UpdateAvatar';
import { formatText } from 'utils/formatText';
import { formatDate } from 'utils/format';
import { UpdateSignature } from '../components/UpdateSignature';
import { PencilAltIcon } from '@heroicons/react/outline';

const Entry = ({ label, value }) => (
  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
    <dt className="text-sm font-medium text-gray-500">{label}</dt>
    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{value}</dd>
  </div>
);

Entry.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export const Profile = () => {
  const { user } = useAuth();
  const routes = [
    {
      name: 'My Account',
      path: '/app/my-account',
    },
  ];
  if (!user) return null;

  console.log(user);

  return (
    <ContentLayout>
      <div className="text-2xl text-center mb-4 rounded-2xl p-3">User Profile</div>
      <Breadcrumbs routes={routes} />
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
        <div>
          <div className="rounded-2xl mb-4 flex flex-col space-y-2 items-center bg-white pt-4 justify-start mb-3">
            <img
              src={UPLOADS_URL + '/images/users/' + user.basicInformation?.avatar}
              className="w-48 h-48 rounded-full shadow-lg"
              alt="avatar"
            />
            <UpdateAvatar id={user._id} />
          </div>
          <div className="rounded-2xl mb-4 flex flex-col space-y-2 items-center bg-white pt-4 justify-start mb-3">
            <h3 className="text-lg leading-6 font-medium text-gray-900 flex flex-row items-center space-x-2">
              <span>Signature</span>
              <UpdateSignature
                triggerButton={
                  <div className="flex flex-row items-center space-x-2 hover:text-primary-100 hover:underline">
                    <PencilAltIcon className="h-6 w-6" />
                  </div>
                }
              />
            </h3>
            {user.documentVerification?.signature ? (
              <img
                src={UPLOADS_URL + '/images/signatures/' + user.documentVerification?.signature}
                alt="signature"
              />
            ) : (
              <div className="text-gray-800">
                <UpdateSignature
                  triggerButton={
                    <div className="flex flex-row items-center space-x-2 hover:text-primary-100 hover:underline">
                      <PencilAltIcon className="h-6 w-6" /> <span>Add Signature</span>
                    </div>
                  }
                />{' '}
              </div>
            )}
          </div>
        </div>
        <div className="md:col-span-3 ">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">User Information</h3>
                <UpdateProfile />
              </div>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details of the user.</p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <Entry label="First Name" value={user.basicInformation?.firstName} />
                <Entry label="Last Name" value={user.basicInformation?.lastName} />
                <Entry
                  label="Phone Number"
                  value={`${user.basicInformation?.contactInformation?.phoneNumber}`}
                />
                <Entry
                  label="Email Address"
                  value={user.basicInformation?.contactInformation?.email}
                />

                <Entry label="Role" value={formatText(user.role)} />
                <Entry label="Gender" value={user.basicInformation?.gender} />
                <Entry
                  label="Date of Birth"
                  value={formatDate(user.basicInformation?.dateOfBirth)}
                />
              </dl>
            </div>
          </div>

          <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Address Information</h3>
              </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <Entry
                  label="Residential Address"
                  value={user.addressInformation?.residentialAddress}
                />
                <Entry
                  label="Permanent Address"
                  value={user.addressInformation?.permanentAddress}
                />
              </dl>
            </div>
          </div>

          <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Financial Information
                </h3>
              </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <Entry label="Job Title" value={user.financialInformation?.jobTitle} />
                <Entry label="Salary" value={user.financialInformation?.monthlyIncome} />
              </dl>
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
