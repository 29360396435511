import { Button, Dialog, DialogTitle } from 'components/Elements';
import { UPLOADS_URL } from 'config';
import React from 'react';
import { MdFileOpen } from 'react-icons/md';

export function EvidenceOfPayment({ url }) {
  const [show, setShow] = React.useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <Button size="sm" onClick={handleOpen} startIcon={<MdFileOpen />}>
        Preview Evidence
      </Button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">
            Evidence of Payment
          </DialogTitle>
          <div>
            <img
              src={UPLOADS_URL + '/documents/repayments/' + url}
              alt="payment"
              className="w-full h-full"
            />
          </div>
          <div className="flex flex-row items-center space-x-2">
            <button onClick={handleClose} className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl">
              Close
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
