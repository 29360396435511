import { Button } from 'components/Elements';
import { Form, FormDrawer, InputField } from 'components/Form';
import { useLender } from '../api/getLender';
import { useActivateLender } from '../api/activateLender';

export const ActivateLender = ({ id }) => {
  const activateLenderMutation = useActivateLender();

  return (
    <FormDrawer
      isDone={activateLenderMutation.isSuccess}
      size="sm"
      triggerButton={<Button size="sm">Activate</Button>}
      title="Activate Lender Account"
      submitButton={
        <Button
          form="activate-lender"
          type="submit"
          size="sm"
          isLoading={activateLenderMutation.isLoading}
        >
          Submit
        </Button>
      }
    >
      <Form
        id="activate-lender"
        onSubmit={async (values) => {
          await activateLenderMutation.mutateAsync({ data: values, id });
        }}
      >
        {({ register, formState }) => (
          <>
            <InputField
              label="Set a commission (%)"
              required={true}
              error={formState.errors['commission']}
              registration={register('commission')}
            />
          </>
        )}
      </Form>
    </FormDrawer>
  );
};
