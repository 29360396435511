import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { Authorization, ROLES } from 'lib/authorization';
import { TrashIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

import { useDeleteLateFeeRange } from '../api/deleteLateFeeRange';

export const DeleteLateFee = ({ id, lateFeeId }) => {
  const deleteLateFeeMutation = useDeleteLateFeeRange();

  if (deleteLateFeeMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.SUPER_ADMIN]}>
      <ConfirmationDialog
        icon="danger"
        title="Remove Late Fee"
        body="Are you sure you want to remove this late fee?"
        triggerButton={
          <button className="flex flex-row items-center space-x-2">
            <TrashIcon className="h-4 w-4" />
            <span>Remove</span>
          </button>
        }
        confirmButton={
          <Button
            isLoading={deleteLateFeeMutation.isLoading}
            type="button"
            className="bg-red-600"
            onClick={async () =>
              await deleteLateFeeMutation.mutateAsync({ id: id, lateFeeId: lateFeeId })
            }
          >
            Trash
          </Button>
        }
      />
    </Authorization>
  );
};

DeleteLateFee.propTypes = {
  id: PropTypes.string.isRequired,
};
