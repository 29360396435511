import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const activateLender = ({ data, id }) => {
  return axios.post(`/lenders/${id}/activate`, data);
};

export const useActivateLender = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (updatingLender) => {
      await queryClient.cancelQueries(['lender', updatingLender?.id]);

      const previousLender = queryClient.getQueryData[('lender', updatingLender?.id)];

      queryClient.setQueryData(['lender', updatingLender?.id], {
        ...previousLender,
        ...updatingLender.data,
        id: updatingLender.id,
      });

      return { previousLender };
    },
    onError: (_, __, context) => {
      if (context?.previousLender) {
        queryClient.setQueryData(['lender', context.previousLender.id], context.previousLender);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['lender', data._id]);
      queryClient.refetchQueries(['lenders']);
      addNotification({
        type: 'success',
        title: 'Lender Account Activated.',
      });
    },
    ...config,
    mutationFn: activateLender,
  });
};
