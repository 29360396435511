import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const createAsset = ({ data }) => {
  return axios.post(`/collaterals`, data);
};

export const useCreateAsset = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newProduct) => {
      await queryClient.cancelQueries('assets');

      const previousAssets = queryClient.getQueryData('assets');

      queryClient.setQueryData('assets', [...(previousAssets || []), newProduct.data]);

      return { previousAssets };
    },
    onError: (_, __, context) => {
      if (context?.previousAssets) {
        queryClient.setQueryData('assets', context.previousAssets);
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries('assets');
    },
    ...config,
    mutationFn: createAsset,
  });
};
