import { Table, Spinner } from 'components/Elements';
import { formatDate } from 'utils/format';
import { useRepayments } from '../api/getRepayments';
import { Link } from 'react-router-dom';
import { DeleteRepayment } from './DeleteRepayment';
import { useRef, useState } from 'react';

export const RepaymentsList = () => {
  const repaymentsQuery = useRepayments();
  const inputRef = useRef(null);
  const [q, setQ] = useState('');
  const [searchColumns, setSearchColumns] = useState([
    'transRef',
    'user.basicInformation.firstName',
    'user.basicInformation.lastName',
    'user.basicInformation.contactInformation.email',
    'user.basicInformation.contactInformation.phoneNumber',
    'loan.tracking_id',
  ]);

  if (repaymentsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!repaymentsQuery.data) return null;

  const columns = [
    {
      title: 'TxRef',
      field: 'transRef',
      Cell({ entry: { transRef, _id } }) {
        return (
          <Link to={`./${_id}`} className="text-xs">
            {transRef}
          </Link>
        );
      },
    },
    {
      title: 'Paid By',
      field: 'basicInformation',
      Cell({ entry: { user, _id } }) {
        return (
          <Link className="flex flex-col" to={`./${_id}`}>
            <div className="flex items-center">
              <div className="ml-2">
                <div className="font-bold">
                  {user?.basicInformation?.firstName} {user?.basicInformation?.lastName}
                </div>
                <div className="text-xs text-gray-400">
                  {user?.basicInformation?.contactInformation?.email}
                </div>
              </div>
            </div>
          </Link>
        );
      },
    },

    {
      title: 'Amount Received',
      field: 'amountReceived',
      Cell({ entry: { amountReceived } }) {
        return <>UGX {amountReceived?.toLocaleString()}</>;
      },
    },
    {
      title: 'Status',
      field: 'status',
      Cell({ entry: { status } }) {
        return (
          <div
            className={`rounded-full p-1 px-2 bg-${
              status == 'CLEARED' ? 'green' : 'gray'
            }-100 text-${status == 'CLEARED' ? 'green' : 'gray'}-500 font-bold text-center`}
          >
            {status}
          </div>
        );
      },
    },
    {
      title: 'Paid On',
      field: 'createdAt',
      Cell({ entry: { createdAt } }) {
        return <span>{formatDate(createdAt)}</span>;
      },
    },
    {
      title: 'action',
      field: '_id',
      Cell({ entry: { _id } }) {
        return (
          <div className="flex flex-row items-center">
            <DeleteRepayment id={_id} />
          </div>
        );
      },
    },
  ];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some((column) => {
        const value = extractNestedValue(row, column);
        return value.toString().toLowerCase().includes(q.toLowerCase());
      })
    );
  }

  const extractNestedValue = (obj, path) => {
    const keys = path.split('.');
    return keys.reduce((acc, key) => (acc && acc[key] ? acc[key] : ''), obj);
  };

  let repayments = search(repaymentsQuery.data);

  const handleInputChange = (event) => {
    setQ(event.target.value);
  };

  return (
    <>
      <div className="mt-8 mb-3 flex flex-col md:flex-row space-y-2 md:space-y-0 items-center justify-center">
        <input
          ref={inputRef}
          type="text"
          className="border border-gray-700 p-2 rounded-xl w-full md:w-1/2"
          placeholder="Search for Transaction Ref, borrower, Loan tracking ID"
          value={q}
          onChange={handleInputChange}
        />
      </div>
      <Table title="Repayments" data={repayments} columns={columns} />
    </>
  );
};
