import { Table, Spinner, Button } from 'components/Elements';
import { formatDate } from 'utils/format';

import { useUsers } from '../api/getUsers';

import { DeleteUser } from './DeleteUser';

import { useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { useInformNewImports } from '../api/informNewImports';
import { Link } from 'react-router-dom';
import { formatText } from 'utils/formatText';

export const UsersList = ({ role }) => {
  const usersQuery = useUsers();
  const inputRef = useRef(null);
  const [q, setQ] = useState('');
  const [searchColumns, setSearchColumns] = useState([
    'basicInformation.firstName',
    'basicInformation.lastName',
    'basicInformation.contactInformation.email',
    'basicInformation.contactInformation.phoneNumber',
    'authentication.nin',
    'authentication.username',
    'status',
    'createdAt',
    'role',
  ]);

  const informNewImportsMutation = useInformNewImports();

  if (usersQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!usersQuery.data) return null;

  const columns = [
    {
      title: 'Name',
      field: 'basicInformation',
      Cell({ entry: { _id, authentication, basicInformation } }) {
        return (
          <Link to={`/app/accounts/${_id}`}>
            <span className="text-lg font-bold">
              {basicInformation?.firstName} {basicInformation?.lastName}
              {basicInformation?.companyName ?? basicInformation?.companyName}
            </span>
            <br />
            {authentication?.username && (
              <span className="text-green-500 ml-2">{authentication?.username}</span>
            )}
            <br />
            {basicInformation?.firstName ? 'Personal Account' : 'Company Account'}
          </Link>
        );
      },
    },
    {
      title: 'Contact Information',
      field: 'basicInformation',
      Cell({ entry: { basicInformation } }) {
        return (
          <>
            {basicInformation?.contactInformation?.email}
            <br />
            {basicInformation?.contactInformation?.phoneNumber}
          </>
        );
      },
    },
    {
      title: 'Role',
      field: 'role',
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Last Used',
      field: 'createdAt',
      Cell({ entry: { createdAt } }) {
        return <span>{formatDate(createdAt)}</span>;
      },
    },
    {
      title: 'action',
      field: '_id',
      Cell({ entry: { _id } }) {
        return (
          <div className="flex flex-row items-center">
            <DeleteUser id={_id} />
            {/* <button>
              <Slash size={20} />
            </button> */}
          </div>
        );
      },
    },
  ];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some((column) => {
        const value = extractNestedValue(row, column);
        return value.toString().toLowerCase().includes(q.toLowerCase());
      })
    );
  }

  const extractNestedValue = (obj, path) => {
    const keys = path.split('.');
    return keys.reduce((acc, key) => (acc && acc[key] ? acc[key] : ''), obj);
  };

  let users = search(usersQuery.data);

  if (role) users = search(usersQuery.data.filter((user) => user.role === role));

  const handleInputChange = (event) => {
    setQ(event.target.value);
  };

  return (
    <>
      <div className="mt-8 flex flex-col md:flex-row space-y-2 md:space-y-0 items-center justify-center">
        <input
          ref={inputRef}
          type="text"
          className="border border-gray-700 p-2 rounded-xl w-full md:w-1/2"
          placeholder="Search for first or last name..."
          value={q}
          onChange={handleInputChange}
        />
      </div>
      {usersQuery.data.some((user) => user.status === 'IMPORT') && (
        <button
          onClick={async () => {
            await informNewImportsMutation.mutateAsync();
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Successfully notified users to activate their accounts',
            });
          }}
          className="my-4 text-center bg-blue-100 text-blue-500 font-semibold p-3 rounded-xl w-full"
        >
          {informNewImportsMutation.isLoading
            ? `Sending emails...`
            : `You have unverified new imported accounts. Would you like to notify the users to activate
          their accounts?`}
        </button>
      )}
      <div className="mt-8">
        <Table
          title={`${role ? formatText(role) + ` Lists` : `Users Lists`}`}
          data={users}
          columns={columns}
        />
      </div>
    </>
  );
};
