import { Button, Dialog, DialogTitle } from 'components/Elements';
import React from 'react';
import { Form, RegularTextAreaField } from 'components/Form';
import { MessageCircle } from 'react-feather';
import { useSendSMS } from '../api/sendSMS';

export function SendSMS({ trackingId }) {
  const [show, setShow] = React.useState(false);

  const sendSMSMutation = useSendSMS();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <button onClick={handleOpen} className="flex items-center hover:text-primary-100">
        <MessageCircle className="" />
      </button>

      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">Send SMS Message</DialogTitle>
          <div className="mb-4">
            <Form
              id="send-sms"
              onSubmit={async (values) => {
                await sendSMSMutation.mutateAsync({
                  data: {
                    text: values.text,
                  },
                  trackingId: trackingId,
                });
                handleClose();
              }}
            >
              {({ formState, register }) => (
                <>
                  <RegularTextAreaField
                    label="SMS message"
                    error={formState.errors['text']}
                    registration={register('text')}
                  />
                  <div className="flex flex-row justify-center items-center space-x-2">
                    <Button onClick={handleClose} variant="outline">
                      Cancel
                    </Button>
                    <Button type="submit" isLoading={sendSMSMutation.isLoading}>
                      Send Message
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
