import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const createRepayment = ({ data }) => {
  return axios.post(`/repayments`, data);
};

export const useCreateRepayment = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newProduct) => {
      await queryClient.cancelQueries('repayments');

      const previousRepayments = queryClient.getQueryData('repayments');

      queryClient.setQueryData('repayments', [...(previousRepayments || []), newProduct.data]);

      return { previousRepayments };
    },
    onError: (_, __, context) => {
      if (context?.previousRepayments) {
        queryClient.setQueryData('repayments', context.previousRepayments);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('repayments');
      addNotification({
        type: 'success',
        title: 'Repayment Created',
      });
    },
    ...config,
    mutationFn: createRepayment,
  });
};
