import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { Link, useNavigate } from 'react-router-dom';
import { Head } from 'components/Head';

export const Settings = () => {
  const routes = [
    {
      name: 'Product Development',
      path: '/app/products',
    },
    {
      name: 'Product Settings',
      path: '/app/products/settings',
    },
  ];
  const navigate = useNavigate();
  return (
    <ContentLayout
      title="Product Development Settings"
      end={<Button onClick={() => navigate('/app')}>Back Home</Button>}
    >
      <Head title="Product Development Settings" />
      <Breadcrumbs routes={routes} />
      <div className="mt-4">
        <button onClick={() => navigate('/app/products/settings/applicable-fees')} className="bg-white rounded-2xl shadow p-6 text-start w-full">
          <div className="font-bold text-xl">Applicable Fees</div>
          <div className="">Set up the various general fees applicable to all product.</div>
        </button>
      </div>
    </ContentLayout>
  );
};
