import { Table, Spinner, Button } from 'components/Elements';
import { formatDate } from 'utils/format';
import { DeleteDirector } from './DeleteDirector';

import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDirectors } from '../api/getDirectors';

export const DirectorsList = ({ role }) => {
  const directorsQuery = useDirectors();
  const inputRef = useRef(null);
  const [q, setQ] = useState('');
  const [searchColumns, setSearchColumns] = useState([
    'firstName',
    'lastName',
    'email',
    'phone',
    'createdAt',
  ]);

  if (directorsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!directorsQuery.data) return null;

  const columns = [
    {
      title: 'Name',
      field: 'firstName',
      Cell({ entry: { _id, firstName, lastName } }) {
        return (
          <Link to={`/app/directors/${_id}`}>
            <span className="text-lg font-bold">
              {firstName} {lastName}
            </span>
            <br />
          </Link>
        );
      },
    },
    {
      title: 'Contact Information',
      field: 'email',
      Cell({ entry: { email, phone } }) {
        return (
          <>
            {email}
            <br />
            {phone}
          </>
        );
      },
    },

    {
      title: 'Created',
      field: 'createdAt',
      Cell({ entry: { createdAt } }) {
        return <span>{formatDate(createdAt)}</span>;
      },
    },
    {
      title: 'action',
      field: '_id',
      Cell({ entry: { _id } }) {
        return (
          <div className="flex flex-row items-center">
            <DeleteDirector id={_id} />
          </div>
        );
      },
    },
  ];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some((column) => {
        const value = extractNestedValue(row, column);
        return value.toString().toLowerCase().includes(q.toLowerCase());
      })
    );
  }

  const extractNestedValue = (obj, path) => {
    const keys = path.split('.');
    return keys.reduce((acc, key) => (acc && acc[key] ? acc[key] : ''), obj);
  };

  let users = search(directorsQuery.data);

  if (role) users = search(directorsQuery.data.filter((user) => user.role === role));

  const handleInputChange = (event) => {
    setQ(event.target.value);
  };

  return (
    <>
      <div className="mt-8 flex flex-col md:flex-row space-y-2 md:space-y-0 items-center justify-center">
        <input
          ref={inputRef}
          type="text"
          className="border border-gray-700 p-2 rounded-xl w-full md:w-1/2"
          placeholder="Search for first or last name..."
          value={q}
          onChange={handleInputChange}
        />
      </div>

      <div className="mt-8">
        <Table title="Directors List" data={users} columns={columns} />
      </div>
    </>
  );
};
