import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getMyAssets = () => {
  return axios.get('/collaterals/mine');
};

export const useMyAssets = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['myAssets'],
    queryFn: () => getMyAssets(),
  });
};
