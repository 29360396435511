import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button, Spinner } from 'components/Elements';
import { useNavigate, useParams } from 'react-router-dom';
import { Authorization, ROLES } from 'lib/authorization';
import { useUser } from '../api/getUser';
import { UPLOADS_URL } from 'config';

export const User = () => {
  const { id } = useParams();
  const usersQuery = useUser({ id });

  const routes = [
    {
      name: 'Users',
      path: '/app/accounts',
    },
    {
      name: usersQuery.data?.authentication?.username,
      path: `/app/accounts/${id}`,
    },
  ];
  const navigate = useNavigate();

  if (usersQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!usersQuery.data) return null;

  const Entry = ({ label, value }) => (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{value}</dd>
    </div>
  );

  return (
    <ContentLayout title="User Profile">
      <Breadcrumbs routes={routes} />
      <Authorization
        forbiddenFallback={<div>Only admin can view this.</div>}
        allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
      >
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
          <div className="rounded-2xl mb-4 flex flex-col space-y-2 items-center bg-white pt-4 justify-start">
            <img
              src={UPLOADS_URL + '/images/users/' + usersQuery.data?.basicInformation?.avatar}
              className="w-48 h-48 rounded-full shadow-lg"
              alt="avatar"
            />
          </div>
          <div className="md:col-span-3 ">
            <div className="flex flex-col space-y-4">
              <div className="bg-white rounded-2xl shadow-lg p-5">
                <div className="px-4 py-5 sm:px-6">
                  <div className="flex justify-between">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Basic Information
                    </h3>
                    {/* <UpdateProfile /> */}
                  </div>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Personal details of the user.
                  </p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <Entry
                      label="First Name"
                      value={usersQuery.data?.basicInformation?.firstName}
                    />
                    <Entry label="Last Name" value={usersQuery.data?.basicInformation?.lastName} />
                    <Entry
                      label="Email"
                      value={usersQuery.data?.basicInformation?.contactInformation.email}
                    />
                    <Entry
                      label="Phone Number"
                      value={usersQuery.data?.basicInformation?.contactInformation.phoneNumber}
                    />
                    <Entry label="Status" value={usersQuery.data?.status} />
                    <Entry label="Created At" value={usersQuery.data?.createdAt} />
                  </dl>
                </div>
              </div>
              <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">
                <div className="px-4 py-5 sm:px-6">
                  <div className="flex justify-between">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Address Information
                    </h3>
                  </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <Entry
                      label="Residential Address"
                      value={usersQuery.data?.addressInformation?.residentialAddress}
                    />
                    <Entry
                      label="Permanent Address"
                      value={usersQuery.data?.addressInformation?.permanentAddress}
                    />
                  </dl>
                </div>
              </div>

              <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">
                <div className="px-4 py-5 sm:px-6">
                  <div className="flex justify-between">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Financial Information
                    </h3>
                  </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <Entry
                      label="Job Title"
                      value={usersQuery.data?.financialInformation?.jobTitle}
                    />
                    <Entry
                      label="Salary"
                      value={usersQuery.data?.financialInformation?.monthlyIncome}
                    />
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Authorization>
    </ContentLayout>
  );
};
