export function formatRelativeTime(timestamp) {
    const now = new Date();
    const date = new Date(timestamp);
    const elapsed = now - date;

    const seconds = Math.floor(elapsed / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (seconds < 60) {
        return "just now";
    } else if (minutes < 60) {
        return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (hours < 24) {
        return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else {
        return date.toLocaleString();
    }
}
