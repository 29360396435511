import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getDirectors = () => {
  return axios.get(`/directors`);
};

export const useDirectors = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['directors'],
    queryFn: () => getDirectors(),
  });
};
