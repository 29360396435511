import { Table, Spinner, Link } from 'components/Elements';
import { formatDate } from 'utils/format';

import { DeleteProduct } from './DeleteProduct';
import { useProducts } from '../api/getProducts';

export const ProductsList = () => {
  const productsQuery = useProducts();

  if (productsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!productsQuery.data) return null;

  return (
    <Table
      data={productsQuery.data}
      entries="Product Listings"
      columns={[
        {
          title: 'Title',
          field: 'product_title',
          Cell({ entry: { product_title, createdAt, _id } }) {
            return (
              <Link className="flex flex-col" to={`./${_id}`}>
                <span className="text-gray-800 text-lg dark:text-gray-400 hover:text-green-600 dark:hover:text-gray-300">
                  {product_title}
                </span>
                <span className="text-gray-400 dark:text-gray-600 text-xs">
                  {formatDate(createdAt)}
                </span>
              </Link>
            );
          },
        },
        {
          title: 'Status',
          field: 'status',
          Cell({ entry: { status } }) {
            return (
              <>
                {status === 'INACTIVE' && (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                    INACTIVE
                  </span>
                )}
                {status === 'ACTIVE' && (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    ACTIVE
                  </span>
                )}
              </>
            );
          },
        },
        {
          title: '',
          field: '_id',
          Cell({ entry: { _id } }) {
            return <DeleteProduct id={_id} />;
          },
        },
      ]}
    />
  );
};
