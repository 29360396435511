import { Button, Dialog, DialogDescription, DialogTitle, Spinner } from 'components/Elements';
import React, { useState } from 'react';
import PigeonLogo from 'assets/pigeon.svg';
import EflLogo from 'assets/efl.png';
import { useNavigate } from 'react-router-dom';
import { useMyAssets } from 'features/assets';
import BorrowMoneyImg from 'assets/borrow_money.png';

export function OpenPigeon() {
  const [show, setShow] = React.useState(false);
  const [showAssetsModel, setShowAssetsModel] = React.useState(false);

  const assetsQuery = useMyAssets();
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    setShowAssetsModel(false);
  };

  const handleOpen = () => {
    if (assetsQuery.data.length === 0) {
      setShowAssetsModel(true);
    } else {
      setShow(true);
    }
  };

  if (assetsQuery.isLoading) {
    return <></>;
  }

  if (myLoanQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!assetsQuery.data) return null;

  return (
    <div>
      <button
        onClick={handleOpen}
        className="flex flex-col items-start bg-white justify-start space-y-3 border border-secondary-100 rounded-md p-12"
      >
        <img src={BorrowMoneyImg} alt="borrow money" className="h-16" />
        <div className="text-primary-100 text-xl font-bold">Borrow Money</div>
        <div className="text-start">Create a loan request with a few steps.</div>
      </button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">
            New Loan Application
          </DialogTitle>
          <DialogDescription>
            <div className="">
              You are now being redirected to Pigeon Loans Gateway which simplifies your loan
              application process.
            </div>
            <div className="mx-auto">
              <div className="flex flex-row items-center space-x-12 my-6">
                <img src={PigeonLogo} alt="Pigeon Loans" className="h-8 md:h-12" />
                <img src={EflLogo} alt="EFL" className="h-8 md:h-12" />
              </div>
            </div>
          </DialogDescription>
          <div className="flex flex-row items-center space-x-2">
            <button onClick={handleClose} className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl">
              Cancel
            </button>
            <button
              onClick={() => navigate('/app/pigeon-loans')}
              className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl text-white bg-primary-100"
            >
              Proceed
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog isOpen={showAssetsModel} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">
            Collateral Assets Required
          </DialogTitle>
          <DialogDescription>
            <div className="">
              You are required to add some collateral assets before applying for a loan.
            </div>
          </DialogDescription>
          <div className="flex flex-row items-center space-x-2">
            <button onClick={handleClose} className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl">
              Cancel
            </button>
            <button
              onClick={() => navigate('/app/assets/create')}
              className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl text-white bg-primary-100"
            >
              Proceed
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
