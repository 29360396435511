import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { Link, useNavigate } from 'react-router-dom';
import { ProductsList } from '../components/ProductsList';
import { Head } from 'components/Head';
import { useEffect } from 'react';

export const Products = () => {
  const routes = [
    {
      name: 'Product Development',
      path: '/app/products',
    },
  ];
  const navigate = useNavigate();

  return (
    <ContentLayout
      title="Product Development"
      end={<Button onClick={() => navigate('/app')}>Back Home</Button>}
    >
      <Head title="Product Development" />
      <Breadcrumbs routes={routes} />
      <div className="mt-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Link
            to="/app/products/new"
            className="bg-white border border-primary-100 rounded-xl shadow p-6"
          >
            <div className="text-primary-100 font-semibold text-lg mb-2">New Product</div>
            <div className="text-gray-700 text-md">
              Create a new loan product and specify the various valuation fees.
            </div>
          </Link>

          <Link
            to="/app/products/direct"
            className="bg-white border border-primary-100 rounded-xl shadow p-6"
          >
            <div className="text-primary-100 font-semibold text-lg mb-2">Direct Products</div>
            <div className="text-gray-700 text-md">Manage Finablr direct products.</div>
          </Link>

          <Link
            to="/app/products/settings"
            className="bg-white border border-primary-100 rounded-xl shadow p-6"
          >
            <div className="text-primary-100 font-semibold text-lg mb-2">Product Settings</div>
            <div className="text-gray-700 text-md">
              Testing new loan products in a controlled environment before launch.
            </div>
          </Link>
        </div>
      </div>
      <div className="mt-8">
        <ProductsList />
      </div>
    </ContentLayout>
  );
};
