import * as React from 'react';

import { useAuth } from './auth';

export const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  BORROWER: 'BORROWER',
  LENDER: 'LENDER',
  LOAN_OFFICER: 'LOAN_OFFICER',
  CREDIT_SCORER: 'CREDIT_SCORER',
  COMPLIANCE: 'COMPLIANCE',
  SUPPORT: 'SUPPORT',
  COLLECTIONS_OFFICER: 'COLLECTIONS_OFFICER',
  RISK_MANAGER: 'RISK_MANAGER',
  AUDITOR: 'AUDITOR',
  SECURITY_OFFICER: 'SECURITY_OFFICER',
  RECOVERY_OFFICER: 'RECOVERY_OFFICER',
  MARKETING_OFFICER: 'MARKETING_OFFICER',
  DEVELOPER: 'DEVELOPER',
  SURVEYER: 'SURVEYER',
  VALUATION: 'VALUATION',
};

export const POLICIES = {
  'comment:create': (user) => {
    return ['LOAN_OFFICER', 'ADMIN', 'SUPERADMIN', 'SUPPORT', 'BORROWER', 'LENDER'].includes(
      user.role
    );
  },
  'comment:read': (user) => {
    return true; // All roles can read comments
  },
  'comment:update': (user, comment) => {
    return user.id === comment.userId || ['ADMIN', 'SUPERADMIN'].includes(user.role);
  },
  'comment:delete': (user, comment) => {
    return user.id === comment.userId || ['ADMIN', 'SUPERADMIN'].includes(user.role);
  },
  'loan:create': (user) => {
    return ['ADMIN', 'SUPERADMIN', 'LOAN_OFFICER'].includes(user.role);
  },
  'loan:read': (user) => {
    return true; // All roles can read loans
  },
  'loan:update': (user, loan) => {
    return ['ADMIN', 'SUPERADMIN', 'LOAN_OFFICER'].includes(user.role);
  },
  'loan:delete': (user, loan) => {
    return ['ADMIN', 'SUPERADMIN'].includes(user.role);
  },
  // Add more policies as needed...
};

export const useAuthorization = () => {
  const { user } = useAuth();

  if (!user) {
    throw Error('User does not exist!');
  }

  const checkAccess = React.useCallback(
    ({ allowedRoles }) => {
      if (allowedRoles && allowedRoles.length > 0) {
        return allowedRoles?.includes(user.role);
      }

      return true;
    },
    [user.role]
  );

  return { checkAccess, role: user.role };
};

export const Authorization = ({
  policyCheck,
  allowedRoles,
  forbiddenFallback = null,
  children,
}) => {
  const { checkAccess } = useAuthorization();

  let canAccess = false;

  if (allowedRoles) {
    canAccess = checkAccess({ allowedRoles });
  }

  if (typeof policyCheck !== 'undefined') {
    canAccess = policyCheck;
  }

  return <>{canAccess ? children : forbiddenFallback}</>;
};
