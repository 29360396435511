import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const updateApplicableFees = ({ data }) => {
  return axios.post(`/applicable-fees`, data);
};

export const useUpdateApplicableFees = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (updatingApplicableFees) => {
      await queryClient.cancelQueries(['applicableFees']);

      const previousApplicableFees = queryClient.getQueryData(['applicableFees']);

      queryClient.setQueryData(['applicableFees'], {
        ...previousApplicableFees,
        ...updatingApplicableFees.data,
      });

      return { previousApplicableFees };
    },
    onError: (_, __, context) => {
      if (context?.previousApplicableFees) {
        queryClient.setQueryData(['applicableFees'], context.previousApplicableFees);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['applicableFees']);
      addNotification({
        type: 'success',
        title: 'Applicable Fees Updated',
      });
    },
    ...config,
    mutationFn: updateApplicableFees,
  });
};
