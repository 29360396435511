import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { useCheckLoanStatus } from '../api/loanStatus';
import { useState } from 'react';
import { UPLOADS_URL } from 'config';
import { useUsersWithLoans } from 'features/repayments';
import { Spinner } from 'flowbite-react';
import { Form } from 'components/Form';
import Select from 'react-select';

export const LoanStatus = () => {
  const checkLoanStatusMutation = useCheckLoanStatus();

  const usersQuery = useUsersWithLoans();

  const [borrower, setBorrower] = useState();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const navigate = useNavigate();

  // Function to handle input change
  const handleInputChange = (newValue) => {
    setMenuIsOpen(newValue.length >= 4);
  };

  const routes = [
    {
      name: 'Loans',
      path: '/app/loans',
    },
    {
      name: 'Loan Status',
      path: '/app/loans/ronald-isiko',
    },
  ];

  if (usersQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  if (!usersQuery.data) return null;

  return (
    <ContentLayout
      title="Loan Status"
      end={<Button onClick={() => navigate('/app')}>Back Home</Button>}
    >
      <Breadcrumbs routes={routes} />
      <div className="mt-8 flex flex-col items-center justify-center">
        <div className="mt-2 flex flex-row items-center space-x-2 mb-8">
          <Form
            id="check-loan-status"
            onSubmit={async () => {
              await checkLoanStatusMutation.mutateAsync({
                borrower,
              });
            }}
          >
            {({}) => (
              <div className="flex flex-row items-center space-x-2">
                <Select
                  classNamePrefix="select"
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      width: '30vw',
                      height: '44px',
                      fontSize: '1rem',
                      border: '1px solid #E0991C',
                      padding: '0 14px',
                      borderRadius: '13px',
                    }),
                  }}
                  placeholder="Search for user with active loan..."
                  isClearable={true}
                  isSearchable={true}
                  options={usersQuery.data.map((user) => ({
                    value: user._id,
                    label:
                      user?.basicInformation?.firstName +
                      ' ' +
                      user?.basicInformation?.lastName +
                      ', ' +
                      user?.basicInformation?.contactInformation?.phoneNumber,
                  }))}
                  onChange={(selectState) => {
                    setBorrower(selectState.value);
                  }}
                  maxMenuHeight={100}
                  onInputChange={handleInputChange} // Pass the handleInputChange function
                  menuIsOpen={menuIsOpen}
                />

                <Button variant="outline" type="submit" size="sm">
                  Search
                </Button>
              </div>
            )}
          </Form>
        </div>
        {checkLoanStatusMutation.isLoading && <div>Loading...</div>}
        {checkLoanStatusMutation.isError && <div>Error...</div>}
        {checkLoanStatusMutation.isSuccess && (
          <div className="bg-white shadow p-6 rounded-2xl w-[50vw]">
            <div className="flex flex-row items-center justify-between mb-4">
              <div className="">
                Loan tracking ID:{' '}
                <span className="font-bold">{checkLoanStatusMutation.data?.trackingId}</span>
              </div>
              <div className="bg-green-400 text-gray-700 rounded-full p-2 px-4">
                {checkLoanStatusMutation.data?.status}
              </div>
            </div>

            {checkLoanStatusMutation.data?.borrower && (
              <div className="border border-gray-200 p-6 rounded-2xl mb-4">
                <div className="font-semibold text-md mb-6">Borrower</div>
                <div className="flex flex-row items-center space-x-12">
                  <div className="flex flex-row items-center space-x-2">
                    <img
                      src={
                        UPLOADS_URL +
                        '/images/users/' +
                        checkLoanStatusMutation.data?.borrower?.basicInformation?.avatar
                      }
                      alt="User"
                      className="rounded-full h-12"
                    />

                    <div className="">
                      <div className="">
                        {checkLoanStatusMutation.data?.borrower?.authentication?.username}
                      </div>
                      <div className="text-sm">
                        {
                          checkLoanStatusMutation.data?.borrower?.basicInformation
                            ?.contactInformation?.phoneNumber
                        }
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="text-sm text-gray-500">Borrowing</div>
                    <div className="text-md font-semibold text-gray-700">
                      UGX{' '}
                      {checkLoanStatusMutation.data?.loan?.amount?.loanRequired?.toLocaleString()}
                    </div>
                  </div>
                  <div className="">
                    <div className="text-sm text-gray-500">Interest</div>
                    <div className="text-md font-semibold text-gray-700">
                      {checkLoanStatusMutation.data?.loan?.interestRate}%
                    </div>
                  </div>
                  <div className="">
                    <div className="text-sm text-gray-500">Duration</div>
                    <div className="text-md font-semibold text-gray-700">
                      {checkLoanStatusMutation.data?.loan?.term} months
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <div className="border border-gray-200 p-6 rounded-2xl">
              <div className="font-semibold text-md mb-6">Lender</div>
              <div className="flex flex-row items-center space-x-12">
                <div className="flex flex-row items-center space-x-2">
                  <img src={UserImg} alt="User" className="rounded-full h-12" />
                  <div className="">
                    <div className="">Jonathan.CM23454</div>
                    <div className="text-sm">+256 772896782</div>
                  </div>
                </div>
                <div className="">
                  <div className="text-sm text-gray-500">Lending</div>
                  <div className="text-md font-semibold text-gray-700">350,000 UGX</div>
                </div>
                <div className="">
                  <div className="text-sm text-gray-500">Interest</div>
                  <div className="text-md font-semibold text-gray-700">150,000 UGX</div>
                </div>
                <div className="">
                  <div className="text-sm text-gray-500">Expects</div>
                  <div className="text-md font-semibold text-gray-700">500,000 UGX</div>
                </div>
              </div>
            </div> */}
          </div>
        )}
      </div>
    </ContentLayout>
  );
};
