import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getTips = () => {
  return axios.get('/tips');
};

export const useTips = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['tips'],
    queryFn: () => getTips(),
  });
};
