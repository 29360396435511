import { Button, Drawer } from 'components/Elements';
import { useDisclosure } from 'hooks/useDisclosure';
import React, { useEffect, useState } from 'react';
import { useGeneratePaymentSchedule } from '../api/generatePaymentSchedule';
import { Printer } from 'react-feather';

export const PaymentSchedule = ({ amount, interestRate, term, monthlyPayment, paymentMethod }) => {
  const { close, open, isOpen } = useDisclosure();
  const [payments, setPayments] = useState();
  const [period, setPeriod] = useState();

  const generatePaymentScheduleMutation = useGeneratePaymentSchedule();

  const handlePreviewClick = async () => {
    try {
      const response = await generatePaymentScheduleMutation.mutateAsync({
        data: {
          amount,
          interestRate,
          period: term,
          monthlyPayment,
          paymentMethod,
        },
      });
      setPayments(response.payments.schedule);
      setPeriod(response.term);
      open();
    } catch (error) {
      // Handle error
      console.error('Error generating payment schedule:', error);
    }
  };

  const handlePrintClick = () => {
    const printContent = document.getElementById('paymentSchedule').outerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContent;

    // Apply custom print styles
    const style = document.createElement('style');
    style.innerHTML = `
        @media print {
            #paymentSchedule {
                transform: scale(0.9);
                transform-origin: top left;
            }
        }
    `;
    document.head.appendChild(style);

    window.print();
    document.body.innerHTML = originalContents;
  };

  return (
    <>
      <div className="flex flex-row justify-center items-center my-4">
        <Button
          size="sm"
          onClick={handlePreviewClick}
          isLoading={generatePaymentScheduleMutation.isLoading}
        >
          Preview Payment Schedule
        </Button>
      </div>
      <Drawer
        title={`Payment Schedule`}
        isOpen={isOpen}
        onClose={close}
        renderFooter={() => <></>}
        size="full"
      >
        <div className="flex flex-row  space-x-2 justify-between mb-4">
          <Button size="sm" startIcon={<Printer />} onClick={handlePrintClick} className="ml-4">
            Print Payment Schedule
          </Button>
          <Button size="sm" onClick={close} className="mr-4">
            Close
          </Button>
        </div>
        <div id="paymentSchedule" className="border border-gray-200 rounded-2xl p-3">
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div className="mx-4">
              <div className="font-bold">Principal</div>
              <div>{amount.toLocaleString()} UGX</div>
            </div>
            <div className="mx-4">
              <div className="font-bold">Loan Term</div>
              <div>{period} months</div>
            </div>
            <div className="mx-4">
              <div className="font-bold">Monthly Payment</div>
              <div>{monthlyPayment.toLocaleString()} UGX</div>
            </div>
          </div>
          <div className="font-bold mb-4">Scheduled Payments:</div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-200">
                  <th className="px-4 py-2">Installment</th>
                  <th className="px-4 py-2">Installment Amount</th>
                  <th className="px-4 py-2">Principal Due</th>
                  <th className="px-4 py-2">Interest Due</th>
                  <th className="px-4 py-2">Balance</th>
                  {/* <th className="px-4 py-2">Expected Date</th> */}
                </tr>
              </thead>
              <tbody>
                {payments?.map((payment, index) => (
                  <PaymentItem
                    key={index}
                    installmentCount={payment?.installmentNumber || 'N/a'}
                    installmentAmount={payment?.installmentAmount || 'N/a'}
                    principalDue={payment?.principalDue || 'N/a'}
                    interestDue={payment?.interestDue || 'N/a'}
                    balance={payment?.balance}
                    date_expected={payment?.dateExpected || 'N/a'}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Drawer>
    </>
  );
};

const PaymentItem = ({
  installmentCount,
  installmentAmount,
  principalDue,
  interestDue,
  balance,
  date_expected,
}) => {
  return (
    <tr>
      <td className="border px-4 py-2">{installmentCount}</td>
      <td className="border px-4 py-2">
        {installmentAmount.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
      </td>
      <td className="border px-4 py-2">
        {principalDue.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
      </td>
      <td className="border px-4 py-2">
        {interestDue.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
      </td>
      <td className="border px-4 py-2">
        {balance.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
      </td>
      {/* <td className="border px-4 py-2">{date_expected}</td> */}
    </tr>
  );
};
