import { Button, Drawer } from 'components/Elements';
import { useDisclosure } from 'hooks/useDisclosure';
import React, { useEffect, useState, useRef } from 'react';
import { Eye, Printer } from 'react-feather';
import { useReactToPrint } from 'react-to-print';
import { formatDate } from 'utils/format';
import { useGenerateRepaymentSchedule } from '../api/generateRepaymentSchedule';
import { ContentLayout } from 'components/Layout';
import { Form, InputField, SelectField } from 'components/Form';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

export const GenerateRepaymentSchedule = ({
  borrower,
  amount,
  interestRate,
  monthlyPayment,
  paymentMethod = 'DECLINING_BALANCE',
  status,
  period,
}) => {
  const { close, open, isOpen } = useDisclosure();
  const [payments, setPayments] = useState();
  const [term, setTerm] = useState();
  const [totalPayable, setTotalPayable] = useState();
  const [totalInterest, setTotalInterest] = useState();

  const generatePaymentScheduleMutation = useGenerateRepaymentSchedule();

  const paymentScheduleToPrint = useRef();

  const date = new Date();

  const handlePrint = useReactToPrint({
    content: () => paymentScheduleToPrint.current,
    documentTitle: `Repayment Schedule_${date.toLocaleString()}`,
  });

  const routes = [
    {
      name: 'Loans',
      path: '/app/loans',
    },
    {
      name: 'Generate Schedule',
      path: `/app/loans/generate-schedule`,
    },
  ];

  return (
    <ContentLayout title="Generate Schedule">
      <Breadcrumbs routes={routes} />
      <div className="bg-white rounded-lg shadow p-4">
        <Form
          id="repayment-schedule"
          onSubmit={async (values) => {
            try {
              const response = await generatePaymentScheduleMutation.mutateAsync({
                data: {
                  amount: values.amount,
                  interestRate: values.interestRate,
                  monthlyPayment: values.monthlyPayment,
                  paymentMethod: values.paymentMethod,
                  period: values.period,
                },
              });

              setPayments(response.payments.schedule);
              setTotalPayable(response.payments.totalPayable);
              setTotalInterest(response.payments.interestCharged);
              setTerm(response.term);
              open();
            } catch (error) {
              // Handle error
              console.error('Error generating payment schedule:', error);
            }
          }}
        >
          {({ register, formState }) => (
            <div>
              <InputField
                label="Amount"
                error={formState.errors['amount']}
                registration={register('amount')}
              />
              <InputField
                label="Interest Rate"
                error={formState.errors['interestRate']}
                registration={register('interestRate')}
              />
              <InputField
                label="Monthly Payment"
                error={formState.errors['monthlyPayment']}
                registration={register('monthlyPayment')}
              />
              <SelectField
                label="Payment Method"
                error={formState.errors['paymentMethod']}
                registration={register('paymentMethod')}
                options={[
                  { label: 'Declining Balance', value: 'DECLINING_BALANCE' },
                  // { label: 'Fixed Flat', value: 'FIXED_FLAT' },
                  { label: 'Interest Only', value: 'INTEREST_ONLY' },
                ]}
              />
              <InputField
                label="Period"
                error={formState.errors['period']}
                registration={register('period')}
              />
              <div className="flex flex-row items-center space-x-2">
                <Button
                  startIcon={<Eye size={20} />}
                  type="submit"
                  isLoading={generatePaymentScheduleMutation.isLoading}
                  className="whitespace-nowrap"
                  size="sm"
                >
                  Schedule
                </Button>
              </div>
            </div>
          )}
        </Form>
      </div>
      <Drawer title="" isOpen={isOpen} onClose={close} renderFooter={() => <></>} size="full">
        <div className="flex flex-row  space-x-2 justify-between mb-4">
          <Button size="sm" startIcon={<Printer />} onClick={handlePrint} className="ml-4">
            Print Repayment Schedule
          </Button>
          <Button size="sm" onClick={close} className="mr-4">
            Close
          </Button>
        </div>
        <div ref={paymentScheduleToPrint} className="bg-white border-gray-200 rounded-2xl p-3">
          <div className=" border-b pb-4">
            <div className="text-lg text-start font-semibold uppercase mb-4">
              Finablr Repayment Schedule
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-4 border-b pb-4">
            <div className="mx-4">
              <div className="font-bold">Borrower</div>
              <div>{borrower}</div>
            </div>
            <div className="mx-4">
              <div className="font-bold">Principal</div>
              <div>UGX {amount?.toLocaleString()}</div>
            </div>
            <div className="mx-4">
              <div className="font-bold">Total Interest</div>
              <div>
                {totalInterest?.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })} (
                {interestRate}%)
              </div>
            </div>
            <div className="mx-4">
              <div className="font-bold">Total Payable</div>
              <div>
                {totalPayable?.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
              </div>
            </div>

            <div className="mx-4">
              <div className="font-bold">Loan Term</div>
              <div>{term} months</div>
            </div>
          </div>
          {status !== 'ACTIVE' && (
            <div className="bg-orange-100 p-3 rounded-2xl text-orange-600 text-sm mb-4">
              Please note that this is not the final repayment schedule. You'll receive a final
              repayment schedule once you've signed your loan contract and Finablr has initiated
              your contract.{' '}
            </div>
          )}
          <div className="font-semibold mb-4">Scheduled Repayments.</div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-200">
                  <th className="px-4 py-2">Installment</th>
                  <th className="px-4 py-2">Installment Amount</th>
                  <th className="px-4 py-2">Principal Due</th>
                  <th className="px-4 py-2">Interest Due</th>
                  <th className="px-4 py-2">Balance</th>
                  {/* <th className="px-4 py-2">Expected Date</th> */}
                </tr>
              </thead>
              <tbody>
                {payments?.map((payment, index) => (
                  <PaymentItem
                    key={index}
                    installmentCount={payment?.installmentNumber || ''}
                    installmentAmount={payment?.installmentAmount || 0}
                    principalDue={payment?.principalDue || 0}
                    interestDue={payment?.interestDue || 0}
                    balance={payment?.balance || 0}
                    date_expected={payment?.dateExpected || 'N/a'}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div
            className="mt-4 mb-2 text-center"
            style={{
              fontSize: '0.5rem',
            }}
          >
            Generated from <span className="text-primary-100">finablr.app</span> on{' '}
            {formatDate(new Date())}. Contact Us on{' '}
            <span className="text-primary-100">+256 706 400 057</span> or reach out to{' '}
            <span className="text-primary-100">operations@finablr.app</span> for more information.
          </div>
        </div>
      </Drawer>
    </ContentLayout>
  );
};

const PaymentItem = ({
  installmentCount,
  installmentAmount,
  principalDue,
  interestDue,
  balance,
  date_expected,
}) => {
  return (
    <tr>
      <td className="border px-4 py-2">{installmentCount}</td>
      <td className="border px-4 py-2">
        {installmentAmount > 0 &&
          installmentAmount?.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
      </td>
      <td className="border px-4 py-2">
        {principalDue?.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
      </td>
      <td className="border px-4 py-2">
        {interestDue?.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
      </td>
      <td className="border px-4 py-2">
        {balance?.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
      </td>
      {/* <td className="border px-4 py-2">{date_expected}</td> */}
    </tr>
  );
};
