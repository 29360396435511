import { Table, Spinner } from 'components/Elements';
import { formatDate } from 'utils/format';
import { useServiceProviders } from '../api/getServiceProviders';
import { DeleteUser } from './DeleteUser';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

export const ServiceProvidersList = () => {
  const serviceProvidersQuery = useServiceProviders();
  const inputRef = useRef(null);
  const [q, setQ] = useState('');
  const [searchColumns, setSearchColumns] = useState([
    'basicInformation.firstName',
    'basicInformation.lastName',
    'basicInformation.contactInformation.email',
    'basicInformation.contactInformation.phoneNumber',
    'status',
    'createdAt',
  ]);

  if (serviceProvidersQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!serviceProvidersQuery.data) return null;

  const columns = [
    {
      title: 'Name',
      field: 'basicInformation',
      Cell({ entry: { _id, basicInformation } }) {
        return (
          <Link to={`/app/service-providers/${_id}`}>
            <span className="text-lg font-bold">
              {basicInformation?.firstName} {basicInformation?.lastName}
            </span>
            <br />
            {basicInformation?.companyName ? 'Company Account' : 'Personal Account'}
          </Link>
        );
      },
    },
    {
      title: 'Contact Information',
      field: 'basicInformation',
      Cell({ entry: { basicInformation } }) {
        return (
          <>
            {basicInformation?.contactInformation?.email}
            <br />
            {basicInformation?.contactInformation?.phoneNumber}
          </>
        );
      },
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Last Used',
      field: 'createdAt',
      Cell({ entry: { createdAt } }) {
        return <span>{formatDate(createdAt)}</span>;
      },
    },
    {
      title: 'Action',
      field: '_id',
      Cell({ entry: { _id } }) {
        return (
          <div className="flex flex-row items-center">
            <DeleteUser id={_id} />
          </div>
        );
      },
    },
  ];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some((column) => {
        const value = extractNestedValue(row, column);
        return value.toString().toLowerCase().includes(q.toLowerCase());
      })
    );
  }

  const extractNestedValue = (obj, path) => {
    const keys = path.split('.');
    return keys.reduce((acc, key) => (acc && acc[key] ? acc[key] : ''), obj);
  };

  const serviceProviders = search(serviceProvidersQuery.data);

  const handleInputChange = (event) => {
    setQ(event.target.value);
  };

  return (
    <>
      <div className="mt-8 flex flex-col md:flex-row space-y-2 md:space-y-0 items-center justify-center">
        <input
          ref={inputRef}
          type="text"
          className="border border-gray-700 p-2 rounded-xl w-full md:w-1/2"
          placeholder="Search for first or last name..."
          value={q}
          onChange={handleInputChange}
        />
      </div>
      <div className="mt-8">
        <Table title="Service Provider Lists" data={serviceProviders} columns={columns} />
      </div>
    </>
  );
};
