import { Spinner } from 'components/Elements';
import { Head } from 'components/Head';
import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { useApplicableFees } from '../api/getApplicableFees';
import { UpdateApplicableFees } from '../components/UpdateApplicableFees';

export const ApplicableFees = () => {
  const applicableFeesQuery = useApplicableFees();

  const Entry = ({ label, value }) => (
    <div className="flex flex-row items-center justify-between p-2 px-8">
      <dt className="text-xs font-medium text-gray-500 whitespace-nowrap">{label}</dt>
      <dd className="text-sm text-gray-900">{value}</dd>
    </div>
  );

  const routes = [
    {
      name: 'Product Development',
      path: '/app/products',
    },
    {
      name: 'Product Settings',
      path: '/app/products/settings',
    },
    {
      name: 'Applicable Fees',
      path: `/app/products/settings/applicable-fees`,
    },
  ];

  if (applicableFeesQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  // if (!applicableFeesQuery.data) return null;

  return (
    <>
      <Head title="Applicable Fees" />
      <ContentLayout title="Applicable Fees" end={<UpdateApplicableFees />}>
        <Breadcrumbs routes={routes} />
        {applicableFeesQuery.data ? (
          <div className="border-gray-200 px-4 py-5 sm:p-0 mt-4">
            <div className="grid grid-cols-1 gap-4">
              <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-3 h-fit">
                <div className="font-bold my-2 px-6">Real Estate</div>
                <Entry
                  label="Due Deligence Fee"
                  value={`UGX ${applicableFeesQuery.data?.real_estate?.due_deligence_fee?.toLocaleString()}`}
                />
                <Entry
                  label="Stamp Duty Percentage"
                  value={`${applicableFeesQuery.data?.real_estate?.mortgage_placement?.stamp_duty_fees?.toLocaleString()} %`}
                />
                <Entry
                  label="CRB Fee"
                  value={`UGX ${applicableFeesQuery.data?.real_estate?.mortgage_placement?.registration_fees?.toLocaleString()}`}
                />
                <Entry
                  label="Bank Charges"
                  value={`UGX ${applicableFeesQuery.data?.real_estate?.mortgage_placement?.bank_charges?.toLocaleString()}`}
                />
                <Entry
                  label="Registrar"
                  value={`UGX ${applicableFeesQuery.data?.real_estate?.mortgage_placement?.registrar_fees?.toLocaleString()}`}
                />
                <Entry
                  label="Legal Fees"
                  value={`UGX ${applicableFeesQuery.data?.real_estate?.mortgage_placement?.legal_fees?.toLocaleString()}`}
                />
              </dl>
              <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-3 h-fit">
                <div className="font-bold my-2 px-6">Valuation for Vehicles</div>
                <Entry
                  label="Caveat Placement Fee"
                  value={`UGX ${applicableFeesQuery.data?.vehicles?.caveat_placement_fee?.toLocaleString()}`}
                />
                <Entry
                  label="Insurance Fee"
                  value={`UGX ${applicableFeesQuery.data?.vehicles?.insurance_fee?.toLocaleString()}`}
                />
                <Entry
                  label="Tracker Fee (Hire)"
                  value={`UGX ${applicableFeesQuery.data?.vehicles?.tracker_fee?.hire?.toLocaleString()}`}
                />
                <Entry
                  label="Tracker Fee (Buy)"
                  value={`UGX ${applicableFeesQuery.data?.vehicles?.tracker_fee?.buy?.toLocaleString()}`}
                />

                <Entry
                  label="Commercial Light Vehicles"
                  value={`UGX ${applicableFeesQuery.data?.vehicles?.valuation?.commercial_light_vehicles?.toLocaleString()}`}
                />
                <Entry
                  label="Commercial Heavy Vehicles"
                  value={`UGX ${applicableFeesQuery.data?.vehicles?.valuation?.commercial_heavy_vehicles?.toLocaleString()}`}
                />

                <Entry
                  label="Personal Vehicles"
                  value={`UGX ${applicableFeesQuery.data?.vehicles?.valuation?.personal_vehicles?.toLocaleString()}`}
                />
                <Entry
                  label="Bikes"
                  value={`UGX ${applicableFeesQuery.data?.vehicles?.valuation?.bikes?.toLocaleString()}`}
                />
              </dl>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5">
                <div className="font-bold my-4 px-6">Computers</div>
                <Entry
                  label="Assessment Fees"
                  value={`UGX ${applicableFeesQuery.data?.computers?.assessment_fees?.toLocaleString()}`}
                />
                <Entry
                  label="Tracker Fees"
                  value={`UGX ${applicableFeesQuery.data?.computers?.assessment_fees?.toLocaleString()}`}
                />
              </dl>
              <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5">
                <div className="font-bold my-4 px-6">Television</div>
                <Entry
                  label="Assessment Fees"
                  value={`UGX ${applicableFeesQuery.data?.television?.assessment_fees?.toLocaleString()}`}
                />
                <Entry
                  label="Tracker Fees"
                  value={`UGX ${applicableFeesQuery.data?.computers?.assessment_fees?.toLocaleString()}`}
                />
              </dl>
              <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5">
                <div className="font-bold my-4 px-6">Smart Phones</div>
                <Entry
                  label="Assessment Fees"
                  value={`UGX ${applicableFeesQuery.data?.smart_phone?.assessment_fees?.toLocaleString()}`}
                />
              </dl>
              <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5">
                <div className="font-bold my-4 px-6">Other Electronics</div>
                <Entry
                  label="Assessment Fees"
                  value={`UGX ${applicableFeesQuery.data?.other_electronics?.assessment_fees?.toLocaleString()}`}
                />
              </dl>
            </div>
          </div>
        ) : null}
      </ContentLayout>
    </>
  );
};
