import { Button } from 'components/Elements';
import { Form, FormDrawer, InputField, SelectField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import * as z from 'zod';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useCreateUser } from '../api/createUser';

import { useState } from 'react';

// const schema = z.object({
//   firstName: z.string().min(1, 'Required'),
//   lastName: z.string().min(1, 'Required'),
//   email: z.string().min(1, 'Required'),
//   phoneNumber: z.string().min(1, 'Required'),
//   role: z.string().min(1, 'Required'),
//   nin: z.string().min(1, 'Required'),
// });

export const CreateUser = ({ triggerButton }) => {
  const createUserMutation = useCreateUser();
  const [phoneNumber, setPhoneNumber] = useState('');
  const rolesQuery = [
    { label: 'Admin', value: ROLES.ADMIN },
    { label: 'Super Admin', value: ROLES.SUPER_ADMIN },
    { label: 'Auditor', value: ROLES.AUDITOR },
    { label: 'Collections Officer', value: ROLES.COLLECTIONS_OFFICER },
    { label: 'Marketing Officer', value: ROLES.MARKETING_OFFICER },
    { label: 'Recovery Officer', value: ROLES.RECOVERY_OFFICER },
    { label: 'Loan Officer', value: ROLES.LOAN_OFFICER },
    { label: 'Risk Manager', value: ROLES.RISK_MANAGER },
    { label: 'Developer', value: ROLES.DEVELOPER },
  ];

  return (
    <Authorization allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.DEVELOPER]}>
      <FormDrawer
        size="sm"
        isDone={createUserMutation.isSuccess}
        triggerButton={triggerButton}
        title="Register new user"
        submitButton={
          <Button
            form="create-user"
            type="submit"
            size="sm"
            isLoading={createUserMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="create-user"
          onSubmit={async (values) => {
            values.phoneNumber = phoneNumber;
            await createUserMutation.mutateAsync({ data: values });
          }}
          // schema={schema}
        >
          {({ register, formState }) => (
            <>
              <InputField
                type="text"
                label="First Name"
                error={formState.errors['firstName']}
                registration={register('firstName')}
              />
              <InputField
                type="text"
                label="Last Name"
                error={formState.errors['lastName']}
                registration={register('lastName')}
              />
              <InputField
                type="email"
                label="Email"
                error={formState.errors['email']}
                registration={register('email')}
              />
              <PhoneInput
                country={'ug'}
                value={phoneNumber}
                inputStyle={{
                  width: '100%',
                  height: '60px',
                  fontSize: '1rem',
                  border: '1px solid #e5e5e5',
                  borderRadius: '8px',
                }}
                onChange={(phone) => setPhoneNumber(`+${phone}`)}
              />
              <SelectField
                label="Role"
                error={formState.errors['role']}
                registration={register('role')}
                options={rolesQuery?.map((role) => ({
                  label: role.label,
                  value: role.value,
                }))}
              />
              <InputField
                label="NIN"
                type="text"
                error={formState.errors['nin']}
                registration={register('nin')}
              />
              <div className="pb-12 text-gray-500">
                Creating a new account will send an email to the user with the default password.{' '}
                <code>finablr123</code>
              </div>
            </>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};
