import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import { Authorization, ROLES } from 'lib/authorization';
import { LendersList } from '../../components/LendersList';

export const Lenders = () => {
  const routes = [
    {
      name: 'Accounts & Portfolios',
      path: '/app/accounts',
    },
    {
      name: 'Lender Profiles',
      path: '/app/accounts/portfolios',
    },
  ];
  const navigate = useNavigate();

  return (
    <ContentLayout title="Lender Profiles">
      <Breadcrumbs routes={routes} />
      <Authorization
        forbiddenFallback={<div>Only admin can view this.</div>}
        allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.DEVELOPER]}
      >
        <div className="mt-8">
          <LendersList />
        </div>
      </Authorization>
    </ContentLayout>
  );
};
