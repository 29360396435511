import { Navigate, Route, Routes } from 'react-router-dom';

import { ServiceProviders } from './ServiceProviders';
import { ServiceProvider } from './ServiceProvider';

export const ServiceProvidersRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<ServiceProviders />} />
      <Route path=":id" element={<ServiceProvider />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
