import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button, Spinner } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'lib/auth';
import { Form, ReactCurrencyInputField, SelectField } from 'components/Form';
import { useAssets } from 'features/assets';
import { useProducts } from 'features/products';
import { useComputeNetYouReceive } from '../api/computeNetYouReceive';
import { useState } from 'react';

export const ComputeNetYouReceive = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [amount, setAmount] = useState('');

  const computeNetYouReceiveMutation = useComputeNetYouReceive();
  const assetsQuery = useAssets();
  const productsQuery = useProducts();
  const [netYouReceive, setNetYouReceive] = useState(false);
  const [detail, setDetail] = useState();

  if (assetsQuery.isLoading || productsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  const handleAmountChange = (value) => {
    setAmount(value);
  };

  if (!assetsQuery.data || !productsQuery.data) return null;

  console.log(detail);

  const routes = [
    {
      name: 'Loans',
      path: '/app/loans',
    },
    {
      name: 'Net You Receive',
      path: '/app/loans/net-you-receive',
    },
  ];
  return (
    <ContentLayout
      title="Compute Net You Receive"
      end={<Button onClick={() => navigate('/app')}>Back Home</Button>}
    >
      <Breadcrumbs routes={routes} />

      <div className="my-8 bg-white shadow-md p-8 rounded-md">
        <Form
          id="compute-net-you-receive"
          onSubmit={async (values) => {
            const result = await computeNetYouReceiveMutation.mutateAsync({
              data: {
                amount: amount,
                product: values.product,
                collateral: values.collateral,
              },
            });
            if (result) {
              setNetYouReceive(true);
              setDetail(result);
            }
          }}
        >
          {({ register, formState }) => (
            <>
              <div className="text-sm mb-3">Provide the expected loan amount</div>
              <ReactCurrencyInputField
                prefix="UGX "
                value={amount}
                onChange={handleAmountChange}
                required
                className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <SelectField
                  label="Select Product"
                  error={formState.errors['product']}
                  registration={register('product')}
                  options={productsQuery?.data?.map((p) => ({
                    label: p.product_title,
                    value: p._id,
                  }))}
                />
                <SelectField
                  label="Select Collateral"
                  error={formState.errors['collateral']}
                  registration={register('collateral')}
                  options={assetsQuery?.data?.map((asset) => ({
                    label: asset.title,
                    value: asset._id,
                  }))}
                />
              </div>
              <Button type="submit" isLoading={computeNetYouReceiveMutation.isLoading}>
                Compute Now
              </Button>
            </>
          )}
        </Form>
      </div>
      {netYouReceive && (
        <div className="my-8 bg-white shadow-md p-8 rounded-md">
          <h2 className="text-xl font-semibold mb-4">Net You Receive Details</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="p-4 border rounded-md">
              <h3 className="font-semibold">
                Loan Required: UGX {parseInt(detail?.amount)?.toLocaleString()}
              </h3>
              <p>Service Fee: UGX {detail?.serviceFee?.toLocaleString()}</p>
              {detail?.dueDiligenceFee && (
                <p>Due Diligence Fee: UGX {detail?.dueDiligenceFee?.toLocaleString()}</p>
              )}
              {detail?.mortgagePlacement && (
                <p>Mortgage Placement: UGX {detail?.mortgagePlacement?.toLocaleString()}</p>
              )}
              {detail?.caveatPlacementFee && (
                <p>Caveat Placement Fee: UGX {detail?.caveatPlacementFee?.toLocaleString()}</p>
              )}
              {detail?.trackerFeeHire && (
                <p>Tracker Fee (Hire): UGX {detail?.trackerFeeHire?.toLocaleString()}</p>
              )}
              {detail?.trackerFeeBuy && (
                <p>Tracker Fee (Buy): UGX {detail?.trackerFeeBuy?.toLocaleString()}</p>
              )}
              <p>Insurance Fee: UGX {detail?.insuranceFee?.toLocaleString() || 0}</p>

              {detail?.assessmentFees && (
                <p>Assessment Fees: UGX {detail?.assessmentFees?.toLocaleString()}</p>
              )}
              {(!detail?.total?.withTrackerForBuy || !detail?.total?.withTrackerForHire) && (
                <p className="text-md font-semibold mt-2">
                  Net You Receive: UGX {detail?.total?.toLocaleString()}
                </p>
              )}
              {detail?.total?.withTrackerForHire && (
                <div>
                  <h4 className="text-md font-semibold mt-2">
                    <span>
                      Net You Receive: UGX {detail?.total?.withTrackerForHire?.toLocaleString()}
                    </span>
                    <span className="bg-orange-100 text-orange-600 text-xs rounded-full p-1">
                      Tracker For Hire
                    </span>
                  </h4>
                </div>
              )}
              {detail?.total?.withTrackerForBuy && (
                <div>
                  <h4 className="text-md font-semibold mt-2">
                    <span>
                      Net You Receive: UGX {detail?.total?.withTrackerForBuy?.toLocaleString()}{' '}
                    </span>
                    <span className="bg-orange-100 text-orange-600 text-xs rounded-full p-1">
                      Tracker For Buy
                    </span>
                  </h4>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </ContentLayout>
  );
};
