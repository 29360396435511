import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const markAllNotificationsAsUnread = () => {
  return axios.post(`/notifications/mark-as-unread`);
};

export const useMarkAllNotificationsAsUnread = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('notifications');
      const previousNotifications = queryClient.getQueryData('notifications');

      queryClient.setQueryData(
        'notifications',
        previousNotifications.map((notification) => ({ ...notification, read: false }))
      );

      return { previousNotifications };
    },
    onError: (_, __, context) => {
      if (context?.previousNotifications) {
        queryClient.setQueryData('notifications', context.previousNotifications);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('notifications');
      addNotification({
        type: 'success',
        title: 'Marked as Unread',
      });
    },
    ...config,
    mutationFn: markAllNotificationsAsUnread,
  });
};
