import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const inviteServiceProvider = ({ data }) => {
  return axios.post(`/service-providers/invite`, data);
};

export const useInviteServiceProvider = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newProvider) => {
      await queryClient.cancelQueries('serviceProviders');

      const previousProviders = queryClient.getQueryData('serviceProviders');

      queryClient.setQueryData('serviceProviders', [
        ...(previousProviders || []),
        newProvider.data,
      ]);

      return { previousProviders };
    },
    onError: (_, __, context) => {
      if (context?.previousProviders) {
        queryClient.setQueryData('serviceProviders', context.previousProviders);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('serviceProviders');
      addNotification({
        type: 'success',
        title: 'Invitation Sent',
      });
    },
    ...config,
    mutationFn: inviteServiceProvider,
  });
};
