export function formatDuration(days) {
  if (days < 1) {
    return 'Invalid input';
  }

  if (days === 1) {
    return '1 day';
  }

  if (days < 30) {
    return days + ' days';
  }

  if (days < 365) {
    const months = Math.floor(days / 30);
    const remainingDays = days % 30;
    if (remainingDays === 0) {
      return months === 1 ? '1 month' : months + ' months';
    } else {
      return months === 1
        ? '1 month and ' + remainingDays + ' days'
        : months + ' months and ' + remainingDays + ' days';
    }
  }

  const years = Math.floor(days / 365);
  const remainingDays = days % 365;

  if (remainingDays === 0) {
    return years === 1 ? '1 year' : years + ' years';
  } else {
    const pluralYears = years === 1 ? '1 year' : years + ' years';
    const months = Math.floor(remainingDays / 30);
    const remainingDaysInMonths = remainingDays % 30;
    if (months === 0) {
      return pluralYears + ' and ' + remainingDays + ' days';
    } else {
      const pluralMonths = months === 1 ? '1 month' : months + ' months';
      if (remainingDaysInMonths === 0) {
        return pluralYears + ' and ' + pluralMonths;
      } else {
        return pluralYears + ', ' + pluralMonths + ', and ' + remainingDaysInMonths + ' days';
      }
    }
  }
}
