import logo from 'assets/logo.svg';
import { Link } from 'react-router-dom';

export const Logo = () => {
  return (
    <Link className="flex items-center text-white" to="./">
      <img className="h-12 w-auto" src={logo} alt="Workflow" />
    </Link>
  );
};
