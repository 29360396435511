import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const createDirector = ({ data }) => {
  return axios.post(`/directors`, data);
};

createDirector.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
};

export const useCreateDirector = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newDirector) => {
      await queryClient.cancelQueries('directors');

      const previousDirectors = queryClient.getQueryData('directors');

      queryClient.setQueryData('directors', [...(previousDirectors || []), newDirector.data]);

      return { previousDirectors };
    },
    onError: (_, __, context) => {
      if (context?.previousDirectors) {
        queryClient.setQueryData('directors', context.previousDirectors);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('directors');
      addNotification({
        type: 'success',
        title: 'Director Created',
      });
    },
    ...config,
    mutationFn: createDirector,
  });
};
