import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useAuth } from 'lib/auth';

export const updateAvatar = ({ data, id }) => {
  return axios.patch(`/users/${id}/avatar`, data);
};

updateAvatar.propTypes = {
  id: PropTypes.string.isRequired,
};

export const useUpdateAvatar = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  const { refetchUser } = useAuth();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Profile Photo Updated',
      });
      refetchUser();
    },
    ...config,
    mutationFn: updateAvatar,
  });
};
