import { Button, Dialog, DialogTitle } from 'components/Elements';
import { Form, RegularTextAreaField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import React from 'react';
import { MdFileOpen } from 'react-icons/md';
import { useApproveRepayment } from '../api/approveRepayment';
import { useParams } from 'react-router-dom';

export function ApproveRepayment() {
  const { id } = useParams();
  const [show, setShow] = React.useState(false);

  const approveRepaymentMutation = useApproveRepayment();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <Authorization allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.COLLECTIONS_OFFICER]}>
      <Button size="sm" onClick={handleOpen} startIcon={<MdFileOpen />}>
        Approve Repayment
      </Button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">
            Approve Repayment
          </DialogTitle>
          <Form
            id="approve-repayment"
            onSubmit={async (values) => {
              await approveRepaymentMutation.mutateAsync({ data: values, id });
              setShow(false);
            }}
          >
            {({ register, formState }) => (
              <>
                <div className="my-2">
                  <div className="text-sm bg-red-100 rounded-2xl p-3 font-bold text-red-500">
                    This action is irreversible. Please make sure you've confirmed payment by
                    Previewing the Evidence of Payment before you proceed!
                  </div>
                </div>
                <div>
                  <RegularTextAreaField
                    label="Leave a comment (Optional)"
                    error={formState.errors['comment']}
                    registration={register('comment')}
                    caption="This comment will be visible in user's notifications."
                  />
                </div>
                <div className="flex flex-row justify-center items-center space-x-2">
                  <Button
                    id="approve-repayment"
                    type="submit"
                    size="sm"
                    className="w-full"
                    isLoading={approveRepaymentMutation.isLoading}
                  >
                    Approve Repayment
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
      </Dialog>
    </Authorization>
  );
}
