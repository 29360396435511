import { Table, Spinner, Link } from 'components/Elements';
import { formatDate } from 'utils/format';

import { useLoans } from '../api/getLoans';
import { UPLOADS_URL } from 'config';
import { DeleteLoan } from './DeleteLoan';
import { useRef, useState } from 'react';
import { formatText } from 'utils/formatText';

export const LoansList = () => {
  const loansQuery = useLoans();
  const inputRef = useRef(null);
  const [q, setQ] = useState('');
  const [searchColumns, setSearchColumns] = useState([
    'client.basicInformation.firstName',
    'client.basicInformation.lastName',
    'client.basicInformation.contactInformation.email',
    'client.basicInformation.contactInformation.phoneNumber',
    'tracking_id',
    'status',
    'createdAt',
  ]);

  if (loansQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!loansQuery.data) return null;

  const columns = [
    {
      title: 'Contact',
      field: 'title',
      Cell({ entry: { client, _id, status } }) {
        return (
          <Link className="flex flex-row items-center" to={`./${_id}`}>
            <div className="flex items-center">
              <img
                src={UPLOADS_URL + '/images/users/' + client?.basicInformation?.avatar}
                alt={client?.basicInformation.firstName}
                className="w-8 h-8 rounded-full"
              />
              <div className="ml-2">
                <div className="font-bold">
                  {client?.basicInformation.firstName} {client?.basicInformation?.lastName}
                </div>
                <div className="text-xs text-gray-500">
                  {client?.basicInformation?.contactInformation?.email}
                </div>
                {status === 'EFL_APPROVED' && (
                  <div className="bg-orange-100 text-orange-600 font-semibold  mt-2 rounded-full p-1 px-2 ml-2">
                    Due Diligence Required
                  </div>
                )}
              </div>
            </div>
          </Link>
        );
      },
    },
    {
      title: 'Loan Details',
      field: 'draft',
      Cell({ entry: { term, interestRate } }) {
        return (
          <>
            {term} months at {interestRate} % rate
          </>
        );
      },
    },
    {
      title: 'Status',
      field: 'status',
      Cell({ entry: { status } }) {
        return <>{status}</>;
      },
    },
    {
      title: 'Submitted',
      field: 'createdAt',
      Cell({ entry: { createdAt } }) {
        return <>{formatDate(createdAt)}</>;
      },
    },
    {
      title: '',
      field: '_id',
      Cell({ entry: { _id } }) {
        return (
          <>
            <DeleteLoan id={_id} />
          </>
        );
      },
    },
  ];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some((column) => {
        const value = extractNestedValue(row, column);
        return value.toString().toLowerCase().includes(q.toLowerCase());
      })
    );
  }

  const extractNestedValue = (obj, path) => {
    const keys = path.split('.');
    return keys.reduce((acc, key) => (acc && acc[key] ? acc[key] : ''), obj);
  };

  let loans = search(loansQuery.data);

  const handleInputChange = (event) => {
    setQ(event.target.value);
  };

  return (
    <>
      <div className="mt-8 mb-3 flex flex-col md:flex-row space-y-2 md:space-y-0 items-center justify-center">
        <input
          ref={inputRef}
          type="text"
          className="border border-gray-700 p-2 rounded-xl w-full md:w-1/2"
          placeholder="Search for client name, loan tracking ID..."
          value={q}
          onChange={handleInputChange}
        />
      </div>
      <Table title="Requests" data={loans} entries="Loan listings" columns={columns} />
    </>
  );
};
