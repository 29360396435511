import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const submitComplaint = ({ data }) => {
  return axios.post(`/complaints/submit`, data);
};

submitComplaint.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string.isRequired,
  }).isRequired,
};

export const useSubmitComplaint = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newComplaint) => {
      await queryClient.cancelQueries('complaints');

      const previousComplaints = queryClient.getQueryData('complaints');

      queryClient.setQueryData('complaints', [...(previousComplaints || []), newComplaint.data]);

      return { previousComplaints };
    },
    onError: (_, __, context) => {
      if (context?.previousComplaints) {
        queryClient.setQueryData('complaints', context.previousComplaints);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('complaints');
      addNotification({
        type: 'success',
        title: 'Complaint has been submitted',
      });
    },
    ...config,
    mutationFn: submitComplaint,
  });
};
