import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';

import { useMutation } from 'react-query';

export const informNewImports = () => {
  return axios.post('/imports/users/inform');
};

export const useInformNewImports = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {},
    onError: (_, __, context) => {},
    onSuccess: (data) => {
      queryClient.invalidateQueries('users');
    },
    ...config,
    mutationFn: informNewImports,
  });
};
