import { useAuth } from 'lib/auth';
import { axios } from 'lib/axios';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const updateSignature = ({ data }) => {
  return axios.patch(`/users/profile/signature`, data);
};

export const useUpdateSignature = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  const { refetchUser } = useAuth();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Signature Updated',
      });
      refetchUser();
    },
    ...config,
    mutationFn: updateSignature,
  });
};
