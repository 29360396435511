import React from 'react';
import { Spinner, MDPreview, Button } from 'components/Elements';
import { Head } from 'components/Head';
import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DeleteNotification } from '../components/DeleteNotification';

import { useMarkNotificationAsRead } from '../api/markAsRead';
import { useMarkNotificationAsUnread } from '../api/markAsUnread';

import { useNotification } from '../api/getNotification';
import { Eye, EyeOff } from 'react-feather';
import { formatRelativeTime } from 'utils/formatRelativeTime';

export const Notification = () => {
  const { id } = useParams();
  const notificationQuery = useNotification({ id });

  const markAsReadMutation = useMarkNotificationAsRead();
  const markAsUnreadMutation = useMarkNotificationAsUnread();
  const navigate = useNavigate();

  const handleMarkAsRead = () => {
    markAsReadMutation.mutate({ id });
  };

  const handleMarkAsUnread = () => {
    markAsUnreadMutation.mutate({ id });
  };

  const routes = [
    {
      name: 'Notifications',
      path: '/app/notifications',
    },
    {
      name: 'Details',
      path: `/app/notifications/${id}`,
    },
  ];

  if (notificationQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  console.log(notificationQuery.data);

  if (!notificationQuery.data) return null;

  const formatBodyWithNumbers = (body) => {
    const items = body.split('\n').filter((item) => item.trim() !== '');
    return items.map((item, index) => `${index + 1}. ${item}`).join('\n');
  };

  const formattedBody = notificationQuery.data?.body
    ? formatBodyWithNumbers(notificationQuery.data.body)
    : '';

  return (
    <>
      <Head title="Details" />
      <ContentLayout title="Details">
        <Breadcrumbs routes={routes} />
        <div className="mt-6 ">
          <div className="bg-white p-6 rounded-lg shadow-md flex flex-row items-start justify-between">
            {notificationQuery.data.route ? (
              <div className="flex flex-col md:flex-row items-start justify-between w-full">
                <div className="">
                  <MDPreview value={formattedBody} />
                  {notificationQuery.data?.route === 'my-loan' && (
                    <div className="">
                      <Button onClick={() => navigate(`/app/my-loan`)}>My Loan</Button>
                    </div>
                  )}
                  {notificationQuery.data?.route?.startsWith('loans/') && (
                    <div className="">
                      <Button onClick={() => navigate(`/app/${notificationQuery.data?.route}`)}>
                        Go to loan application
                      </Button>
                    </div>
                  )}

                  <br />
                </div>
                <p className="text-gray-500 mt-2 text-xs">
                  {formatRelativeTime(notificationQuery.data?.createdAt)}
                </p>
              </div>
            ) : (
              <div className="">
                <h3 className="text-md">{notificationQuery.data?.message}</h3>
                <p className="text-gray-500 mt-2">
                  {formatRelativeTime(notificationQuery.data?.createdAt)}
                </p>
              </div>
            )}
          </div>
          <div className="mt-4 space-x-4 flex flex-row justify-end mb-3">
            {notificationQuery.data?.read ? (
              <button
                onClick={handleMarkAsUnread}
                className="btn bg-gray-500 rounded-full p-1 px-2 text-white flex flex-row items-center space-x-2"
              >
                <EyeOff size={12} /> <span>Mark as Unread</span>
              </button>
            ) : (
              <button
                onClick={handleMarkAsRead}
                className="btn bg-primary-100 rounded-full p-1 px-2 text-white flex flex-row items-center space-x-2"
              >
                <Eye size={12} /> <span>Mark as Read</span>
              </button>
            )}
            <DeleteNotification id={id} />
          </div>
        </div>
      </ContentLayout>
    </>
  );
};
