import clsx from 'clsx';

import { FieldWrapper } from './FieldWrapper';

export const SelectField = (props) => {
  const {
    label,
    options,
    error,
    className,
    onSelect,
    onChange,
    caption,
    defaultValue,
    registration,
    placeholder,
    required,
  } = props;

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    if (onSelect) {
      onSelect(selectedValue);
    }
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <FieldWrapper label={label} error={error}>
      <select
        placeholder={placeholder}
        onChange={handleSelectChange}
        name="location"
        required={required}
        className={clsx(
          'appearance-none block w-full px-3 py-3 border  border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
          className
        )}
        defaultValue={defaultValue}
        {...registration}
      >
        {options.map(({ label, value }) => (
          <option key={label?.toString()} value={value}>
            {label}
          </option>
        ))}
      </select>
      <span className="text-xs">{caption}</span>
    </FieldWrapper>
  );
};
