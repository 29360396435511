import { Button } from 'components/Elements';
import { Form, FormDrawer, InputField, SelectField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useInviteServiceProvider } from '../api/inviteServiceProvider';
import { useState } from 'react';

export const InviteServiceProvider = ({ triggerButton }) => {
  const inviteServiceProviderMutation = useInviteServiceProvider();
  const [phoneNumber, setPhoneNumber] = useState('');
  const rolesQuery = [
    { label: 'Credit Scorer', value: ROLES.CREDIT_SCORER },
    { label: 'Compliance', value: ROLES.COMPLIANCE },
    { label: 'Surveyer', value: ROLES.SURVEYER },
    { label: 'Valuation', value: ROLES.VALUATION },
  ];

  return (
    <Authorization allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}>
      <FormDrawer
        size="sm"
        isDone={inviteServiceProviderMutation.isSuccess}
        triggerButton={triggerButton}
        title="Invite Service Provider"
        submitButton={
          <Button
            form="invite-service-provider"
            type="submit"
            size="sm"
            isLoading={inviteServiceProviderMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="invite-service-provider"
          onSubmit={async (values) => {
            values.phoneNumber = phoneNumber;
            await inviteServiceProviderMutation.mutateAsync({ data: values });
          }}
          // schema={schema}
        >
          {({ register, formState }) => (
            <>
              <InputField
                type="text"
                label="First Name"
                error={formState.errors['firstName']}
                registration={register('firstName')}
              />
              <InputField
                type="text"
                label="Last Name"
                error={formState.errors['lastName']}
                registration={register('lastName')}
              />
              <InputField
                type="email"
                label="Email"
                error={formState.errors['email']}
                registration={register('email')}
              />
              <PhoneInput
                country={'ug'}
                value={phoneNumber}
                inputStyle={{
                  width: '100%',
                  height: '60px',
                  fontSize: '1rem',
                  border: '1px solid #e5e5e5',
                  borderRadius: '8px',
                }}
                onChange={(phone) => setPhoneNumber(`+${phone}`)}
              />
              <SelectField
                label="Role"
                error={formState.errors['role']}
                registration={register('role')}
                options={rolesQuery?.map((role) => ({
                  label: role.label,
                  value: role.value,
                }))}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};
