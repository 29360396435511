import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, FormDrawer, InputField, ReactPhoneNumberFormatInputField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PencilIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { useReallocateRepayment } from '../api/reallocateRepayment';
import { useRepayment } from '../api/getRepayment';

export const ReallocateRepayment = ({ id }) => {
  const repaymentQuery = useRepayment({ id });
  const reallocateRepaymentMutation = useReallocateRepayment();

  if (reallocateRepaymentMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.SUPER_ADMIN]}>
      <FormDrawer
        isDone={reallocateRepaymentMutation.isSuccess}
        triggerButton={
          <Button startIcon={<PencilIcon className="h-4 w-4" />} size="sm">
            Reallocate
          </Button>
        }
        title="Reallocate Repayment Details"
        submitButton={
          <Button
            form="reallocate-repayment-details"
            type="submit"
            size="sm"
            isLoading={reallocateRepaymentMutation.isLoading}
          >
            Submit Reallocation
          </Button>
        }
      >
        <Form
          id="reallocate-repayment-details"
          onSubmit={async (values) => {
            console.log(values);
            await reallocateRepaymentMutation.mutateAsync({ data: values, id });
          }}
          options={{
            defaultValues: {
              amountExpected: repaymentQuery.data?.amountExpected,
              scheduledInstallment: repaymentQuery.data?.scheduledInstallment,
              amountReceived: repaymentQuery.data?.amountReceived,
              actualInstallment: repaymentQuery.data?.actualInstallment,
              principalLent: repaymentQuery.data?.principalLent,
              principalPaid: repaymentQuery.data?.principalPaid,
              principalDue: repaymentQuery.data?.principalDue,
              principalBalance: repaymentQuery.data?.principalBalance,
              interestCharged: repaymentQuery.data?.interestCharged,
              interestPaid: repaymentQuery.data?.interestPaid,
              interestDue: repaymentQuery.data?.interestDue,
              interestBalance: repaymentQuery.data?.interestBalance,
              lateDays: repaymentQuery.data?.lateDays,
              lateFeesDue: repaymentQuery.data?.lateFeesDue,
              lateFeesPaid: repaymentQuery.data?.lateFeesPaid,
              penaltiesDue: repaymentQuery.data?.penaltiesDue,
              penaltiesPaid: repaymentQuery.data?.penaltiesPaid,
              totalOutstandingBalance: repaymentQuery.data?.totalOutstandingBalance,
              eflCommission: repaymentQuery.data?.eflCommission,
              lendersNetEarnings: repaymentQuery.data?.lendersNetEarnings,
              lendersNetCredited: repaymentQuery.data?.lendersNetCredited,
              serviceFee: repaymentQuery.data?.serviceFee,
              monthlyInterest: repaymentQuery.data?.monthlyInterest,
              netMonthlyInterest: repaymentQuery.data?.netMonthlyInterest,
              principalInterest: repaymentQuery.data?.principalInterest,
              arrears: repaymentQuery.data?.arrears,
              principalInArrears: repaymentQuery.data?.principalInArrears,
              realPortfolioAtRisk: repaymentQuery.data?.realPortfolioAtRisk,
              lifeTimePortfolioAtRisk: repaymentQuery.data?.lifeTimePortfolioAtRisk,
              earlyRepaymentFee: repaymentQuery.data?.earlyRepaymentFee,
            },
          }}
        >
          {({ register, formState }) => (
            <>
              <InputField
                label="Amount Expected"
                error={formState.errors['amountExpected']}
                registration={register('amountExpected')}
              />
              <InputField
                label="Scheduled Installment"
                error={formState.errors['scheduledInstallment']}
                registration={register('scheduledInstallment')}
              />
              <InputField
                label="Amount Received"
                error={formState.errors['amountReceived']}
                registration={register('amountReceived')}
              />
              <InputField
                label="Actual Installment"
                error={formState.errors['actualInstallment']}
                registration={register('actualInstallment')}
              />
              <InputField
                label="Principal Lent"
                error={formState.errors['principalLent']}
                registration={register('principalLent')}
              />
              <InputField
                label="Principal Paid"
                error={formState.errors['principalPaid']}
                registration={register('principalPaid')}
              />
              <InputField
                label="Principal Due"
                error={formState.errors['principalDue']}
                registration={register('principalDue')}
              />

              <InputField
                label="Principal Balance"
                error={formState.errors['principalBalance']}
                registration={register('principalBalance')}
              />
              <InputField
                label="Interest Charged"
                error={formState.errors['interestCharged']}
                registration={register('interestCharged')}
              />
              <InputField
                label="Interest Paid"
                error={formState.errors['interestPaid']}
                registration={register('interestPaid')}
              />
              <InputField
                label="Interest Due"
                error={formState.errors['interestDue']}
                registration={register('interestDue')}
              />

              <InputField
                label="Interest Balance"
                error={formState.errors['interestBalance']}
                registration={register('interestBalance')}
              />
              <InputField
                label="Late Days"
                error={formState.errors['lateDays']}
                registration={register('lateDays')}
              />
              <InputField
                label="Late Fees Due"
                error={formState.errors['lateFeesDue']}
                registration={register('lateFeesDue')}
              />
              <InputField
                label="Late Fees Paid"
                error={formState.errors['lateFeesPaid']}
                registration={register('lateFeesPaid')}
              />

              <InputField
                label="Penalties Due"
                error={formState.errors['penaltiesDue']}
                registration={register('penaltiesDue')}
              />
              <InputField
                label="Penalties Paid"
                error={formState.errors['penaltiesPaid']}
                registration={register('penaltiesPaid')}
              />
              <InputField
                label="EFL Commission"
                error={formState.errors['eflCommission']}
                registration={register('eflCommission')}
              />

              <InputField
                label="Lenders Net Earnings"
                error={formState.errors['lendersNetEarnings']}
                registration={register('lendersNetEarnings')}
              />
              <InputField
                label="Lenders Net Credited"
                error={formState.errors['lendersNetCredited']}
                registration={register('lendersNetCredited')}
              />
              <InputField
                label="Total Outstanding Balance"
                error={formState.errors['totalOutstandingBalance']}
                registration={register('totalOutstandingBalance')}
              />
              <InputField
                label="Service Fee"
                error={formState.errors['serviceFee']}
                registration={register('serviceFee')}
              />

              <InputField
                label="Monthly Interest"
                error={formState.errors['monthlyInterest']}
                registration={register('monthlyInterest')}
              />
              <InputField
                label="Net Monthly Interest"
                error={formState.errors['netMonthlyInterest']}
                registration={register('netMonthlyInterest')}
              />
              <InputField
                label="Principal Interest"
                error={formState.errors['principalInterest']}
                registration={register('principalInterest')}
              />
              <InputField
                label="Arrears"
                error={formState.errors['arrears']}
                registration={register('arrears')}
              />
              <InputField
                label="Principal In Arrears"
                error={formState.errors['principalInArrears']}
                registration={register('principalInArrears')}
              />
              <InputField
                label="Real Portfolio at risk"
                error={formState.errors['realPortfolioAtRisk']}
                registration={register('realPortfolioAtRisk')}
              />
              <InputField
                label="Life Time Portfolio At Risk"
                error={formState.errors['lifeTimePortfolioAtRisk']}
                registration={register('lifeTimePortfolioAtRisk')}
              />
              <InputField
                label="Early Repayment Fee"
                error={formState.errors['earlyRepaymentFee']}
                registration={register('earlyRepaymentFee')}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};

ReallocateRepayment.propTypes = {
  id: PropTypes.string.isRequired,
};
