import { useNavigate } from 'react-router-dom';
import { Layout } from '../components/Layout';

export const Session = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/auth/login');
  };

  return (
    <Layout title="Session Timeout">
      <div className="flex flex-col items-center justify-center ">
        <p className="text-lg mb-8">Your session has timed out. Please sign in again.</p>
        <button 
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={handleRedirect}
        >
          Sign In
        </button>
      </div>
    </Layout>
  );
};
