import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getRepayments = () => {
  return axios.get(`/repayments`);
};

export const useRepayments = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['repayments'],
    queryFn: () => getRepayments(),
  });
};
