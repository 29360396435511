import React, { useState } from 'react';
import { Spinner, Link } from 'components/Elements';
import { formatDate } from 'utils/format';
import { DeleteNotification } from './DeleteNotification';
import { useNotifications } from '../api/getNotifications';
import { useMarkNotificationAsRead } from '../api/markAsRead';
import { useNavigate } from 'react-router-dom';
import { FileText } from 'react-feather';
import { formatRelativeTime } from 'utils/formatRelativeTime';

export const NotificationsList = () => {
  const [displayedNotifications, setDisplayedNotifications] = useState(5);
  const notificationsQuery = useNotifications();

  const markAsReadMutation = useMarkNotificationAsRead();
  const navigate = useNavigate();

  const handleViewMore = () => {
    setDisplayedNotifications((prev) => prev + 5);
  };

  if (notificationsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!notificationsQuery.data || notificationsQuery.data.length === 0) {
    return <div className="text-gray-600">No notifications found.</div>;
  }

  return (
    <div className="flex flex-col space-y-2">
      {notificationsQuery.data.slice(0, displayedNotifications).map((notification, index) => (
        <button
          onClick={() => {
            if (!notification.read) {
              markAsReadMutation.mutate({ id: notification._id });
            }
            navigate(`/app/notifications/${notification._id}`);
          }}
          key={notification.id}
          className={`${
            notification.read ? `` : ``
          } bg-white rounded-3xl flex flex-row space-x-4 items-center p-2 px-4 shadow-md`}
        >
          {notification.read ? (
            <div className="bg-gray-500 rounded-full h-2 w-2"></div>
          ) : (
            <div className="bg-green-500 rounded-full h-2 w-2"></div>
          )}
          <FileText className="h-6 w-6" />
          <div className="flex flex-col text-start items-start">
            <div>{notification.message}</div>
            <div className="text-xs text-gray-400">
              {formatRelativeTime(notification.createdAt)}
            </div>
          </div>
        </button>
      ))}
      {displayedNotifications < notificationsQuery.data.length && (
        <button onClick={handleViewMore} className="text-primary-500 font-semibold mt-4">
          View More
        </button>
      )}
    </div>
  );
};
