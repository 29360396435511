import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const approveExecutiveGuarantee = ({ data, id }) => {
  return axios.patch(`/executive_guarantee/${id}/approve`, data);
};

export const useApproveExecutiveGuarantee = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (approvingUser) => {
      await queryClient.cancelQueries(['executive_guarantee_user', approvingUser?.id]);

      const previousUser =
        queryClient.getQueryData[('executive_guarantee_user', approvingUser?.id)];

      queryClient.setQueryData(['executive_guarantee_user', approvingUser?.id], {
        ...previousUser,
        ...approvingUser.data,
        id: approvingUser.id,
      });

      return { previousUser };
    },
    onError: (_, __, context) => {
      if (context?.previousUser) {
        queryClient.setQueryData(
          ['executive_guarantee_user', context.previousUser.id],
          context.previousUser
        );
      }
    },
    onSuccess: (data) => {
      // queryClient.refetchQueries(['executive_guarantee_users']);
      queryClient.invalidateQueries('executive_guarantee_users');
      addNotification({
        type: 'success',
        title: 'Approved Successfully',
      });
    },
    ...config,
    mutationFn: approveExecutiveGuarantee,
  });
};
