import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getLendersPortfolios = () => {
  return axios.get(`/lenders`);
};

export const useLenders = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['lenders'],
    queryFn: () => getLendersPortfolios(),
  });
};
