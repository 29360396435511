import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const createLoan = ({ data }) => {
  return axios.post(`/loans`, data);
};

export const useCreateLoan = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newLoan) => {
      await queryClient.cancelQueries('loans');

      const previousLoans = queryClient.getQueryData('loans');

      queryClient.setQueryData('loans', [...(previousLoans || []), newLoan.data]);

      return { previousLoans };
    },
    onError: (_, __, context) => {
      if (context?.previousLoans) {
        queryClient.setQueryData('loans', context.previousLoans);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('loans');
      addNotification({
        type: 'success',
        title: 'Loan Created',
      });
    },
    ...config,
    mutationFn: createLoan,
  });
};
