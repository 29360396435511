import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { FilePlus } from 'react-feather';
import { ServiceProvidersList } from '../components/ServiceProvidersList';
import { Authorization, ROLES } from 'lib/authorization';
import { InviteServiceProvider } from '../components/InviteServiceProvider';

export const ServiceProviders = () => {
  const routes = [
    {
      name: 'Service Providers',
      path: '/app/service-providers',
    },
  ];
  const navigate = useNavigate();

  return (
    <ContentLayout
      title="Service Providers"
      end={
        <InviteServiceProvider
          triggerButton={
            <Button variant="outline" startIcon={<FilePlus size={16} />}>
              Invite Provider
            </Button>
          }
        />
      }
    >
      <Breadcrumbs routes={routes} />
      <Authorization
        forbiddenFallback={<div>Only admin can view this.</div>}
        allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
      >
        <ServiceProvidersList />
      </Authorization>
    </ContentLayout>
  );
};
