import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { Authorization, ROLES } from 'lib/authorization';
import { TrashIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

import { useDeleteServiceFeeRange } from '../api/deleteServiceFeeRange';

export const DeleteServiceFee = ({ id, serviceFeeId }) => {
  const deleteServiceFeeMutation = useDeleteServiceFeeRange();

  if (deleteServiceFeeMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.SUPER_ADMIN]}>
      <ConfirmationDialog
        icon="danger"
        title="Remove Service Fee"
        body="Are you sure you want to remove this service fee?"
        triggerButton={
          <button className="flex flex-row items-center space-x-2">
            <TrashIcon className="h-4 w-4" />
            <span>Remove</span>
          </button>
        }
        confirmButton={
          <Button
            isLoading={deleteServiceFeeMutation.isLoading}
            type="button"
            className="bg-red-600"
            onClick={async () =>
              await deleteServiceFeeMutation.mutateAsync({ id: id, serviceFeeId: serviceFeeId })
            }
          >
            Trash
          </Button>
        }
      />
    </Authorization>
  );
};

DeleteServiceFee.propTypes = {
  id: PropTypes.string.isRequired,
};
