import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button, Spinner } from 'components/Elements';
import { useNavigate, useParams } from 'react-router-dom';
import { Authorization, ROLES } from 'lib/authorization';
import { useDirector } from '../api/getDirector';

export const Director = () => {
  const { id } = useParams();
  const directorsQuery = useDirector({ id });

  const routes = [
    {
      name: 'Directors',
      path: '/app/directors',
    },
    {
      name: directorsQuery.data?.firstName,
      path: `/app/directors/${id}`,
    },
  ];
  const navigate = useNavigate();

  if (directorsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!directorsQuery.data) return null;

  const Entry = ({ label, value }) => (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{value}</dd>
    </div>
  );

  return (
    <ContentLayout title="Profile">
      <Breadcrumbs routes={routes} />
      <Authorization
        forbiddenFallback={<div>Only admin can view this.</div>}
        allowedRoles={[ROLES.SUPER_ADMIN]}
      >
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
          <div className="md:col-span-3 ">
            <div className="flex flex-col space-y-4">
              <div className="bg-white rounded-2xl shadow-lg p-5">
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <Entry label="First Name" value={directorsQuery.data?.firstName} />
                    <Entry label="Last Name" value={directorsQuery.data?.lastName} />
                    <Entry label="Email" value={directorsQuery.data?.email} />
                    <Entry label="Phone Number" value={directorsQuery.data?.phone} />
                    <Entry label="Borrowers" value={directorsQuery.data?.borrowers?.length} />
                    <Entry label="Created At" value={directorsQuery.data?.createdAt} />
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Authorization>
    </ContentLayout>
  );
};
