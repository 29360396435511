import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, FormDrawer, RegularTextAreaField, SelectField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PencilIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { useUpdateLoanStatus } from '../api/updateLoanStatus';
import { useLoan } from '../api/getLoan';
import { useState } from 'react';

export const UpdateLoanStatus = ({ id }) => {
  const loanQuery = useLoan({ id });
  const updateLoanStatusMutation = useUpdateLoanStatus();

  if (updateLoanStatusMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN, ROLES.LOAN_OFFICER, ROLES.SUPER_ADMIN]}>
      <FormDrawer
        isDone={updateLoanStatusMutation.isSuccess}
        size="sm"
        triggerButton={
          <Button variant="outline" startIcon={<PencilIcon className="h-4 w-4" />} size="sm">
            Loan Status
          </Button>
        }
        title="Update Loan Status"
        submitButton={
          <Button
            form="update-loan-status"
            type="submit"
            size="sm"
            isLoading={updateLoanStatusMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="update-loan-status"
          onSubmit={async (values) => {
            await updateLoanStatusMutation.mutateAsync({ data: values, id });
          }}
          options={{
            defaultValues: {
              status: loanQuery.data?.status,
            },
          }}
        >
          {({ formState, register }) => (
            <>
              <SelectField
                label="Loan Status"
                registration={register('status')}
                options={[
                  { label: 'In Review', value: 'IN_REVIEW' },
                  { label: 'Declined', value: 'DECLINED' },
                ]}
              />
              <RegularTextAreaField
                label="Message"
                error={formState.errors['message']}
                registration={register('message')}
                caption="Provide a message for cases of review and declined loans."
                maxLength={300}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};

UpdateLoanStatus.propTypes = {
  id: PropTypes.string.isRequired,
};
