import { Button, Dialog, DialogTitle } from 'components/Elements';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'components/Form';
import { useCounterVideoCall } from '../api/counterVideoCall';

export function CounterVideoCall({ id }) {
  const [show, setShow] = React.useState(false);

  const navigate = useNavigate();

  const counterVideoCallMutation = useCounterVideoCall();

  const [videoDates, setVideoDates] = useState([]);
  const [timeSlots, setTimeSlots] = useState([{ hours: 9, minutes: 0, meridiem: 'AM' }]);

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  const handleAddVideoDate = () => {
    if (videoDates.length < 2) {
      setVideoDates([...videoDates, '']);
    }
  };

  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, { hours: 0, minutes: 0 }]);
  };

  const handleTimeSlotChange = (index, field, value) => {
    const updatedTimeSlots = [...timeSlots];
    updatedTimeSlots[index][field] = value;
    setTimeSlots(updatedTimeSlots);
  };

  return (
    <div>
      <Button onClick={handleOpen}>Schedule Counter Video Call</Button>

      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">
            Set counter video call meeting
          </DialogTitle>
          <div className="mb-4">
            <Form
              id="confirm-video-call"
              onSubmit={async (values) => {
                await counterVideoCallMutation.mutateAsync({
                  data: {
                    introductory_video_call_scheduled_dates: videoDates.map((date, index) => ({
                      date,
                      time_slots: timeSlots[index],
                    })),
                  },
                  id,
                });
                handleClose();
              }}
            >
              {({ formState, register }) => (
                <>
                  <div className="flex flex-row items-center justify-center space-x-2">
                    <Button size="sm" variant="outline" onClick={handleAddVideoDate}>
                      Add Date For Introductory Call
                    </Button>
                  </div>
                  {videoDates.map((date, index) => (
                    <div className="flex flex-row space-x-2" key={index}>
                      <input
                        type="date"
                        error={formState.errors['videoDates']}
                        registration={register(`videoDates[${index}]`)}
                        onChange={(e) => {
                          const updatedDates = [...videoDates];
                          updatedDates[index] = e.target.value;
                          setVideoDates(updatedDates);
                        }}
                        className="border border-gray-200 rounded-md shadow-sm p-2 w-full mb-2"
                      />
                      {timeSlots[index] ? (
                        <div className="flex flex-row items-center space-x-2">
                          <select
                            value={timeSlots[index].hours}
                            onChange={(e) => handleTimeSlotChange(index, 'hours', e.target.value)}
                            className="border border-gray-200 rounded-md shadow-sm p-2"
                          >
                            {Array.from({ length: 12 }, (_, i) => i + 1).map((hour) => (
                              <option key={hour} value={hour}>
                                {hour}
                              </option>
                            ))}
                          </select>
                          <span>:</span>
                          <select
                            value={timeSlots[index].minutes}
                            onChange={(e) => handleTimeSlotChange(index, 'minutes', e.target.value)}
                            className="border border-gray-200 rounded-md shadow-sm p-2"
                          >
                            {Array.from({ length: 60 }, (_, i) => i).map((minute) => (
                              <option key={minute} value={minute}>
                                {minute < 10 ? `0${minute}` : minute}
                              </option>
                            ))}
                          </select>
                          <select
                            value={timeSlots[index].meridiem}
                            onChange={(e) =>
                              handleTimeSlotChange(index, 'meridiem', e.target.value)
                            }
                            className="border border-gray-200 rounded-md shadow-sm p-2"
                          >
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </select>
                        </div>
                      ) : (
                        <Button size="sm" variant="outline" onClick={handleAddTimeSlot}>
                          Set Available Time
                        </Button>
                      )}
                    </div>
                  ))}
                  <div className="flex flex-row items-center space-x-2 justify-center">
                    <Button size="sm" variant="inverse" type="button" onClick={handleClose}>
                      Close
                    </Button>
                    <Button size="sm" type="submit" isLoading={counterVideoCallMutation.isLoading}>
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
