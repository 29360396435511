import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button, Spinner } from 'components/Elements';
import { Link, useNavigate, useParams } from 'react-router-dom';
import UserImg from 'assets/user.png';
import { Download, Eye } from 'react-feather';
import { useAsset } from '../api/getAsset';
import { formatDate } from 'utils/format';
import { UPLOADS_URL } from 'config';

export const Asset = () => {
  const { id } = useParams();

  const assetQuery = useAsset({ id });
  const navigate = useNavigate();

  if (assetQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!assetQuery.data) return null;

  const routes = [
    {
      name: 'Assets',
      path: '/app/assets',
    },
    {
      name: assetQuery.data?.title,
      path: `/app/assets/${id}`,
    },
  ];

  return (
    <ContentLayout
      title={assetQuery.data?.title}
      end={<Button onClick={() => navigate('/app')}>Back Home</Button>}
    >
      <Breadcrumbs routes={routes} />
      <div className="mt-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="flex flex-col space-y-8">
            <div className="shadow bg-white rounded-2xl flex flex-col items-center space-y-1 pb-8">
              <div className="rounded-full border bg-white border-primary-100 p-2 px-4 -mt-2 mb-6">
                Owner
              </div>
              <div className="w-20 h-20 rounded-full bg-primary-100 flex text-center items-center justify-center">
                <div className="text-white text-lg">
                  {assetQuery.data?.owner.basicInformation.firstName?.charAt(0)}
                </div>
              </div>
              <div className="text-2xl font-semibold">
                {assetQuery.data?.owner?.authentication.username}{' '}
              </div>
              <div className="font-light">
                {assetQuery.data?.owner?.basicInformation.contactInformation.email}
              </div>
              <div className="font-light">
                {assetQuery.data?.owner?.basicInformation.contactInformation.phoneNumber}
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="bg-white rounded-2xl shadow mb-4">
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Title</div>
                <div className="font-semibold text-md col-span-2">{assetQuery.data?.title}</div>
              </div>
              <hr />
            </div>
            {assetQuery.data?.vehicles && (
              <div className="bg-white rounded-2xl shadow mb-4">
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Brand</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.vehicles?.brand}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Mileage</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.vehicles?.mileage}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Model</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data.vehicles?.model}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Year of Manufacture</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data.vehicles?.year_of_manufacture}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Parking Status</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data.vehicles?.parking_status}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Color</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data.vehicles?.color}
                  </div>
                </div>
                <hr />
              </div>
            )}
            {assetQuery.data?.real_estate && (
              <div className="bg-white rounded-2xl shadow mb-4">
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Size of Land</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.real_estate?.size_of_land}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Whats on Land</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.real_estate?.whats_on_land}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Land Location</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.real_estate?.land_location}
                  </div>
                </div>
                {assetQuery.data?.real_estate?.specify_others !== 'undefined' && (
                  <>
                    <hr />
                    <div className="grid grid-cols-3 gap-8 p-6">
                      <div className="text-gray-600 text-sm">Other Details</div>
                      <div className="font-semibold text-md col-span-2">
                        {assetQuery.data?.real_estate?.specify_others}
                      </div>
                    </div>
                  </>
                )}
                <hr />
              </div>
            )}
            <div className="bg-white rounded-2xl shadow mb-4">
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Puchase Amount</div>
                <div className="font-semibold text-md col-span-2">
                  UGX {assetQuery.data?.purchase_amount?.toLocaleString()}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Puchase Date</div>
                <div className="font-semibold text-md col-span-2">
                  {formatDate(assetQuery.data?.purchase_date)}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Current Worth</div>
                <div className="font-semibold text-md col-span-2">
                  UGX {assetQuery.data?.current_worth?.toLocaleString()}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Product</div>
                <div className="font-semibold text-md col-span-2">
                  {assetQuery.data.product?.product_title}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Assessment Fees</div>
                <div className="font-semibold text-md col-span-2">
                  UGX {assetQuery.data?.total_assessment_fees?.toLocaleString()}
                </div>
              </div>
              <hr />
            </div>
            <div className="bg-white rounded-2xl shadow mb-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {assetQuery.data?.pictures?.map((picture, index) => (
                  <div
                    key={index}
                    className="relative overflow-hidden bg-white rounded-lg shadow-md"
                  >
                    <img
                      src={`${UPLOADS_URL}/collateral/${picture}`}
                      alt={`Picture ${index + 1}`}
                      className="w-full h-64 object-cover transition-transform duration-300 transform hover:scale-105"
                    />
                    <div className="absolute top-0 left-0 bg-black bg-opacity-50 text-white py-1 px-3 text-sm font-semibold">
                      Picture {index + 1}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
