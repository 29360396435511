import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { Link, useNavigate } from 'react-router-dom';
import { ProductsList } from '../components/ProductsList';
import { Head } from 'components/Head';
import { useEffect } from 'react';
import { UsersList } from '../components/UsersList';

export const ExecutiveGuarantee = () => {
  const routes = [
    {
      name: 'Product Development',
      path: '/app/products',
    },
    {
      name: 'Direct Products',
      path: '/app/products/direct',
    },
    {
      name: 'Executive Guarantee',
      path: '/app/products/direct/executive-guarantee',
    },
  ];
  const navigate = useNavigate();

  return (
    <ContentLayout
      title="Executive Guarantee"
      end={<Button onClick={() => navigate('/app')}>Back Home</Button>}
    >
      <Head title="Executive Guarantee" />
      <Breadcrumbs routes={routes} />
      <div className="mt-4">
        <UsersList />
      </div>
    </ContentLayout>
  );
};
