import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/outline';
import { RepaymentsList } from '../components/RepaymentsList';
import { Authorization, ROLES } from 'lib/authorization';
import Swal from 'sweetalert2';
import { CreateRepayment } from '../components/CreateRepayment';

export const Repayments = () => {
  const routes = [
    {
      name: 'Repayments',
      path: '/app/repayments',
    },
  ];
  const navigate = useNavigate();

  const handleDelinquenciesClick = () => {
    Swal.fire({
      title: 'Feature in Development',
      text: 'This feature is currently in development. Please check back later.',
      icon: 'info',
      confirmButtonText: 'OK',
    });
  };

  return (
    <ContentLayout
      title="Repayment Listings"
      end={
        <div className="flex flex-col md:flex-row items-center space-x-2 space-y-2 md:space-y-0">
          {/* <Button onClick={handleDelinquenciesClick}>Delinquences</Button> */}
          <CreateRepayment
            triggerButton={
              <Button size="sm" startIcon={<PlusIcon className="h-4 w-4" />}>
                New Repayment
              </Button>
            }
          />
        </div>
      }
    >
      <Breadcrumbs routes={routes} />
      <Authorization
        forbiddenFallback={<div>Only admin can view this.</div>}
        allowedRoles={[
          ROLES.ADMIN,
          ROLES.SUPER_ADMIN,
          ROLES.COLLECTIONS_OFFICER,
          ROLES.LOAN_OFFICER,
        ]}
      >
        <div className="mt-8">
          <RepaymentsList />
        </div>
      </Authorization>
    </ContentLayout>
  );
};
