import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, FormDrawer, InputField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PencilAltIcon } from '@heroicons/react/solid';
import { useUpdateLateFeeRange } from '../api/updateLateFeeRange';

export const UpdateLateFeeRange = ({ id, name, lateFeeId, min, max, percentage }) => {
  const updateLateFeeMutation = useUpdateLateFeeRange();

  if (updateLateFeeMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.SUPER_ADMIN]}>
      <FormDrawer
        isDone={updateLateFeeMutation.isSuccess}
        size="md"
        triggerButton={
          <button className="flex flex-row items-center space-x-2">
            <PencilAltIcon className="h-4 w-4" />
            <span>Edit</span>
          </button>
        }
        title="Update Late Fee Range"
        submitButton={
          <Button
            form="update-late-fee-range"
            type="submit"
            size="sm"
            isLoading={updateLateFeeMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="update-late-fee-range"
          onSubmit={async (values) => {
            await updateLateFeeMutation.mutateAsync({
              id,
              lateFeeId,
              data: {
                name: values.name,
                min: values.min,
                max: values.max,
                percentage: values.percentage,
              },
            });
          }}
          options={{
            defaultValues: {
              name,
              min,
              max,
              percentage,
            },
          }}
        >
          {({ register, formState }) => (
            <div className="mb-8">
              <InputField
                label="Name"
                error={formState.errors['name']}
                registration={register('name')}
              />
              <InputField
                label="Minimum Amount"
                error={formState.errors['min']}
                registration={register('min')}
              />
              <InputField
                label="Maximum Amount"
                error={formState.errors['max']}
                registration={register('max')}
              />
              <InputField
                label="Late Fee Percentage"
                error={formState.errors['percentage']}
                registration={register('percentage')}
              />
            </div>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};
