import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, FormDrawer, InputField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PlusIcon } from '@heroicons/react/outline';

import { useUpdateApplicableFees } from '../api/updateApplicableFees';
import { useApplicableFees } from '../api/getApplicableFees';

export const UpdateApplicableFees = () => {
  const applicableFeesQuery = useApplicableFees();
  const updateApplicableFeesMutation = useUpdateApplicableFees();

  if (updateApplicableFeesMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.SUPER_ADMIN]}>
      <FormDrawer
        isDone={updateApplicableFeesMutation.isSuccess}
        size="md"
        triggerButton={
          <Button size="sm" startIcon={<PlusIcon className="h-4 w-4" />}>
            Set Applicable Fees
          </Button>
        }
        title="Update Applicable Fees"
        submitButton={
          <Button
            form="update-applicable-fees"
            type="submit"
            size="sm"
            isLoading={updateApplicableFeesMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="update-applicable-fees"
          onSubmit={async (values) => {
            await updateApplicableFeesMutation.mutateAsync({ data: values });
          }}
          options={{
            defaultValues: {
              due_deligence_fee: applicableFeesQuery?.data?.real_estate?.due_deligence_fee,
              stamp_duty_fees:
                applicableFeesQuery?.data?.real_estate?.mortgage_placement?.stamp_duty_fees,
              registration_fees:
                applicableFeesQuery?.data?.real_estate?.mortgage_placement?.registration_fees,
              bank_charges:
                applicableFeesQuery?.data?.real_estate?.mortgage_placement?.bank_charges,
              registrar_fees:
                applicableFeesQuery?.data?.real_estate?.mortgage_placement?.registrar_fees,
              legal_fees: applicableFeesQuery?.data?.real_estate?.mortgage_placement?.legal_fees,
              caveat_placement_fee: applicableFeesQuery?.data?.vehicles?.caveat_placement_fee,
              insurance_fee: applicableFeesQuery?.data?.vehicles?.insurance_fee,
              vehicle_tracker_hire: applicableFeesQuery?.data?.vehicles?.tracker_fee?.hire,
              vehicle_tracker_buy: applicableFeesQuery?.data?.vehicles?.tracker_fee?.buy,
              personal_vehicles: applicableFeesQuery?.data?.vehicles?.valuation?.personal_vehicles,
              bikes: applicableFeesQuery?.data?.vehicles?.valuation?.bikes,
              heavy_vehicles: applicableFeesQuery?.data?.vehicles?.valuation?.heavy_vehicles,
              commercial_light_vehicles:
                applicableFeesQuery?.data?.vehicles?.valuation?.commercial_light_vehicles,
              commercial_heavy_vehicles:
                applicableFeesQuery?.data?.vehicles?.valuation?.commercial_heavy_vehicles,
              computer_assessment_fees: applicableFeesQuery?.data?.computers?.assessment_fees,
              computer_tracker_fees: applicableFeesQuery?.data?.computers?.tracker_fees,
              television_assessment_fees: applicableFeesQuery?.data?.television?.assessment_fees,
              television_tracker_fees: applicableFeesQuery?.data?.television?.tracker_fees,
              smart_phone_assessment_fees: applicableFeesQuery?.data?.smart_phone?.assessment_fees,
              other_electronics_assessment_fees:
                applicableFeesQuery?.data?.other_electronics?.assessment_fees,
            },
          }}
        >
          {({ register, formState }) => (
            <div className="mb-8">
              <div className="bg-gray-100 p-4 rounded-md shadow-lg grid grid-cols-1 gap-3 mb-4">
                <div className="font-bold text-xl mb-3">Real Estate</div>
                <InputField
                  label="Due Diligence Fee"
                  error={formState.errors['due_deligence_fee']}
                  registration={register('due_deligence_fee')}
                />
                <div className="font-bold text-lg mb-3">Mortgage Placement</div>
                <InputField
                  label="Stamp Duty Percentage (%)"
                  error={formState.errors['stamp_duty_fees']}
                  registration={register('stamp_duty_fees')}
                />
                <InputField
                  label="CRB Fees"
                  error={formState.errors['registration_fees']}
                  registration={register('registration_fees')}
                />
                <InputField
                  label="Bank Charges (UGX)"
                  error={formState.errors['bank_charges']}
                  registration={register('bank_charges')}
                />
                <InputField
                  label="Registrar Fees (UGX)"
                  error={formState.errors['registrar_fees']}
                  registration={register('registrar_fees')}
                />
                <InputField
                  label="Legal Fees (UGX)"
                  error={formState.errors['legal_fees']}
                  registration={register('legal_fees')}
                />
              </div>

              <div className="bg-gray-100 p-4 rounded-md shadow-lg grid grid-cols-1 gap-3 mb-4">
                <div className="font-bold text-xl mb-3">Vehicles</div>
                <InputField
                  label="Caveat Placement Fee"
                  error={formState.errors['caveat_placement_fee']}
                  registration={register('caveat_placement_fee')}
                />
                <InputField
                  label="Insurance Fee"
                  error={formState.errors['insurance_fee']}
                  registration={register('insurance_fee')}
                />
                <div className="font-bold text-lg mb-3">Vehicle Tracker</div>
                <InputField
                  label="Hire"
                  error={formState.errors['vehicle_tracker_hire']}
                  registration={register('vehicle_tracker_hire')}
                />
                <InputField
                  label="Buy"
                  error={formState.errors['vehicle_tracker_buy']}
                  registration={register('vehicle_tracker_buy')}
                />
                <div className="font-bold text-lg mb-3">Valuation for Vehicles</div>
                <div className="grid grid-cols-1 gap-3 mb-3">
                  <div className="">
                    <InputField
                      label="Personal Vehicles (UGX)"
                      error={formState.errors['personal_vehicles']}
                      registration={register('personal_vehicles')}
                    />
                    <InputField
                      label="Bikes (UGX)"
                      error={formState.errors['bikes']}
                      registration={register('bikes')}
                    />
                    <InputField
                      label="Commercial Light Vehicles (UGX)"
                      error={formState.errors['commercial_light_vehicles']}
                      registration={register('commercial_light_vehicles')}
                    />
                    <InputField
                      label="Commercial Heavy Vehicles (UGX)"
                      error={formState.errors['commercial_heavy_vehicles']}
                      registration={register('commercial_heavy_vehicles')}
                    />
                  </div>
                </div>
              </div>
              <div className="bg-gray-100 p-4 rounded-md shadow-lg grid grid-cols-1 gap-3 mb-4">
                <div className="font-bold text-xl mb-3">Computers</div>
                <InputField
                  label="Computer Assessment Fees (UGX)"
                  error={formState.errors['computer_assessment_fees']}
                  registration={register('computer_assessment_fees')}
                />
                <InputField
                  label="Computer Tracker Fees (UGX)"
                  error={formState.errors['computer_tracker_fees']}
                  registration={register('computer_tracker_fees')}
                />
              </div>
              <div className="bg-gray-100 p-4 rounded-md shadow-lg grid grid-cols-1 gap-3 mb-4">
                <div className="font-bold text-xl mb-3">Television</div>
                <InputField
                  label="Television Assessment Fees (UGX)"
                  error={formState.errors['television_assessment_fees']}
                  registration={register('television_assessment_fees')}
                />
                <InputField
                  label="Television Tracker Fees (UGX)"
                  error={formState.errors['television_tracker_fees']}
                  registration={register('television_tracker_fees')}
                />
              </div>
              <div className="bg-gray-100 p-4 rounded-md shadow-lg grid grid-cols-1 gap-3 mb-4">
                <div className="font-bold text-xl mb-3">Smart Phone</div>
                <InputField
                  label="Smart Phones Assessment Fees (UGX)"
                  error={formState.errors['smart_phone_assessment_fees']}
                  registration={register('smart_phone_assessment_fees')}
                />
              </div>
              <div className="bg-gray-100 p-4 rounded-md shadow-lg grid grid-cols-1 gap-3 mb-4">
                <div className="font-bold text-xl mb-3">Other Electronics</div>
                <InputField
                  label="Other Electronics Assessment Fees (UGX)"
                  error={formState.errors['other_electronics_assessment_fees']}
                  registration={register('other_electronics_assessment_fees')}
                />
              </div>
            </div>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};
