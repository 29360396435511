import React from 'react';
import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { NotificationsList } from '../components/NotificationsList';
import { useMarkAllNotificationsAsRead } from '../api/markAllAsRead';
import { useMarkAllNotificationsAsUnread } from '../api/markAllAsUnread';

export const Notifications = () => {
  const markAllAsReadMutation = useMarkAllNotificationsAsRead();
  const markAllAsUnreadMutation = useMarkAllNotificationsAsUnread();

  const handleMarkAllAsRead = () => {
    markAllAsReadMutation.mutate();
  };

  const handleMarkAllAsUnread = () => {
    markAllAsUnreadMutation.mutate();
  };

  const routes = [
    {
      name: 'Notifications',
      path: '/app/notifications',
    },
  ];

  return (
    <ContentLayout
      title="Notifications"
      end={
        <div className="">
          <button
            onClick={handleMarkAllAsRead}
            className="btn bg-primary-100 rounded-full p-1 px-2 text-white mr-2"
          >
            Read All
          </button>
        </div>
      }
    >
      <Breadcrumbs routes={routes} />
      <div className="mt-4">
        <NotificationsList />
      </div>
    </ContentLayout>
  );
};

export default Notifications;
