import { Button } from 'components/Elements';
import { Form, FormDrawer, InputField, PhoneInputField, SelectField } from 'components/Form';
import { useAuth } from 'lib/auth';
import { PencilIcon } from '@heroicons/react/solid';
import * as z from 'zod';

import { useUpdateProfile } from '../api/updateProfile';

export const UpdateProfile = () => {
  const { user } = useAuth();
  const updateProfileMutation = useUpdateProfile();

  return (
    <FormDrawer
      isDone={updateProfileMutation.isSuccess}
      triggerButton={
        <Button startIcon={<PencilIcon className="h-4 w-4" />} size="sm">
          Edit Profile
        </Button>
      }
      title="Edit Profile Details"
      submitButton={
        <Button
          form="update-profile"
          type="submit"
          size="sm"
          isLoading={updateProfileMutation.isLoading}
        >
          Submit
        </Button>
      }
    >
      <Form
        id="update-profile"
        onSubmit={async (values) => {
          user.role === 'SUPER_ADMIN' && values.role === 'USER';
          await updateProfileMutation.mutateAsync({ data: values });
        }}
        options={{
          defaultValues: {
            firstName: user?.basicInformation?.firstName,
            lastName: user?.basicInformation?.lastName,
            username: user?.authentication?.username,
            email: user?.basicInformation?.contactInformation?.email,
            phoneNumber: user?.basicInformation?.contactInformation?.phoneNumber,
            residentialAddress: user?.basicInformation?.residentialAddress,
            permanentAddress: user?.basicInformation?.permanentAddress,
            jobTitle: user?.financialInformation?.jobTitle,
            dateOfBirth: user?.basicInformation?.dateOfBirth,
            gender: user?.basicInformation?.gender,
            role: user?.role,
          },
        }}
      >
        {({ register, formState }) => (
          <>
            <InputField
              label="First Name"
              error={formState.errors['firstName']}
              registration={register('firstName')}
            />
            <InputField
              label="Last Name"
              error={formState.errors['lastName']}
              registration={register('lastName')}
            />
            {user.role === 'SUPER_ADMIN' && (
              <InputField
                label="Username"
                error={formState.errors['username']}
                registration={register('username')}
              />
            )}
            <PhoneInputField
              label="Phone Number"
              error={formState.errors['phoneNumber']}
              registration={register('phoneNumber')}
            />
            <InputField
              label="Email Address"
              type="email"
              error={formState.errors['email']}
              registration={register('email')}
            />
            {user.role === 'SUPER_ADMIN' && (
              <SelectField
                label="User role"
                registration={register('role')}
                defaultValue={user?.role}
                options={[
                  { label: 'User', value: 'USER' },
                  { label: 'Admin', value: 'ADMIN' },
                  { label: 'Super Admin', Value: 'SUPER_ADMIN' },
                  { label: 'Borrower', value: 'BORROWER' },
                  { label: 'Lender', value: 'LENDER' },
                  { label: 'Auditor', value: 'AUDITOR' },
                  { label: 'Collections Officer', value: 'COLLECTIONS_OFFICER' },
                  { label: 'Loan Officer', value: 'LOAN_OFFICER' },
                  { label: 'Risk Manager', value: 'RISK_MANAGER' },
                  { label: 'Developer', value: 'DEVELOPER' },
                ]}
              />
            )}
            <InputField
              label="Residential Address"
              error={formState.errors['residentialAddress']}
              registration={register('residentialAddress')}
            />
            <InputField
              label="Permanent Address"
              error={formState.errors['permanentAddress']}
              registration={register('permanentAddress')}
            />
            <InputField
              label="Job Title"
              error={formState.errors['jobTitle']}
              registration={register('jobTitle')}
            />
            {/* <InputField
              label="Salary"
              error={formState.errors['monthlyIncome']}
              registration={register('monthlyIncome')}
            /> */}
            <InputField
              label="Date of Birth"
              type="date"
              error={formState.errors['dateOfBirth']}
              registration={register('dateOfBirth')}
            />
            <SelectField
              label="Gender"
              registration={register('role')}
              defaultValue={user?.role}
              options={[
                { label: 'Female', value: 'Female' },
                { label: 'Male', value: 'Male' },
              ]}
            />
          </>
        )}
      </Form>
    </FormDrawer>
  );
};
