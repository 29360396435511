import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const counterVideoCall = ({ id, data }) => {
  return axios.post(`/loans/introductory-video-call/${id}/counter`, data);
};

export const useCounterVideoCall = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {},
    onError: (_, __, context) => {},
    onSuccess: (data) => {
      queryClient.refetchQueries(['loan', data._id]);
      addNotification({
        type: 'success',
        title: 'Counter video call sent.',
      });
    },
    ...config,
    mutationFn: counterVideoCall,
  });
};
