import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const createUser = ({ data }) => {
  return axios.post(`/users/register-admin`, data);
};

export const useCreateUser = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newUser) => {
      await queryClient.cancelQueries('users');

      const previousUsers = queryClient.getQueryData('users');

      queryClient.setQueryData('users', [...(previousUsers || []), newUser.data]);

      return { previousUsers };
    },
    onError: (_, __, context) => {
      if (context?.previousUsers) {
        queryClient.setQueryData('users', context.previousUsers);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      addNotification({
        type: 'success',
        title: 'User Created',
      });
    },
    ...config,
    mutationFn: createUser,
  });
};
