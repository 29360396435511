import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const checkLoanStatus = async ({ borrower }) => {
  try {
    return await axios.post(`/loans/search/${borrower}`);
  } catch (error) {
    throw error.response.data.message;
  }
};

checkLoanStatus.propTypes = {
  borrower: PropTypes.string.isRequired,
};

export const useCheckLoanStatus = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (deletedLoan) => {},
    onError: (_, __, context) => {},
    onSuccess: () => {},
    ...config,
    mutationFn: checkLoanStatus,
  });
};
