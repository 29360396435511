import { Button } from 'components/Elements';
import { Form, InputField, PhoneInputField } from 'components/Form';
import { useAuth } from 'lib/auth';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as z from 'zod';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// const schema = z.object({
//   phoneNumber: z.string().min(1, 'Required'),
//   password: z.string().min(1, 'Required'),
// });

export const LoginForm = ({ onSuccess }) => {
  const { login, isLoggingIn } = useAuth();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [showPasswordInput, setShowPasswordInput] = useState(false);

  return (
    <div>
      {!showPasswordInput ? (
        <div className="flex flex-col space-y-5 mt-4">
          {/* <button
            type="button"
            className={`bg-white border ${
              accessLevel === 'ADMINISTRATOR' ? 'bg-[#edfbff] border-secondary' : 'border-gray-300'
            } p-4 rounded-md hover:cursor-pointer hover:bg-[#edfbff] hover:border-secondary`}
            onClick={() => setAccessLevel('ADMINISTRATOR')}
          >
            <div className="text-start">
              <div className="font-bold mb-2">Administrator</div>
              <div className="text-sm">
                Super user that performs tasks requiring unrestricted access.
              </div>
            </div>
          </button> */}
          {/* <button
            type="button"
            className={`bg-white border ${
              accessLevel === 'LOAN_OFFICER' ? 'bg-[#edfbff] border-secondary' : 'border-gray-300'
            } p-4 rounded-md hover:cursor-pointer hover:bg-[#edfbff] hover:border-secondary`}
            onClick={() => setAccessLevel('LOAN_OFFICER')}
          >
            <div className="text-start">
              <div className="font-bold mb-2">Loan Officer</div>
              <div className="text-sm">
                Has limited access to specific modules assigned by administrator.
              </div>
            </div>
          </button> */}
          <PhoneInput
            country={'ug'}
            value={phoneNumber}
            inputStyle={{
              width: '100%',
              height: '60px',
              fontSize: '1rem',
              border: '1px solid #e5e5e5',
              borderRadius: '8px',
            }}
            onChange={(phone) => setPhoneNumber(`+${phone}`)}
          />
          <div className="bg-orange-100 p-2 rounded-2xl text-center text-orange-500 text-xs uppercase">
            This portal is for Company use only
          </div>
          <div>
            <Button
              onClick={() => {
                setShowPasswordInput(true);
                console.log(phoneNumber);
              }}
              className="w-full"
            >
              Next
            </Button>
          </div>
        </div>
      ) : (
        <Form
          onSubmit={async (values) => {
            values.phoneNumber = phoneNumber;
            await login(values);
            onSuccess();
          }}
          // schema={schema}
        >
          {({ register, formState }) => (
            <>
              <div className="mt-4">
                {phoneNumber && (
                  <div className="text-sm text-gray-900">Phone Number: {phoneNumber}</div>
                )}
              </div>
              <InputField
                type="password"
                label="Password"
                error={formState.errors['password']}
                registration={register('password')}
              />
              <div>
                <Button isLoading={isLoggingIn} type="submit" className="w-full">
                  Sign in
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
    </div>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
