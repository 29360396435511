import { Navigate, Route, Routes } from 'react-router-dom';

import { Loan } from './Loan';
import { Loans } from './Loans';
import { LoanStatus } from './LoanStatus';
import { GenerateRepaymentSchedule } from './GenerateRepaymentSchedule';
import { ComputeNetYouReceive } from './ComputeNetYouReceive';

export const LoansRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Loans />} />
      <Route path="check-status" element={<LoanStatus />} />
      <Route path="generate-schedule" element={<GenerateRepaymentSchedule />} />
      <Route path="net-you-receive" element={<ComputeNetYouReceive />} />
      <Route path=":id" element={<Loan />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
