import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getLoans = () => {
  return axios.get('/loans');
};

export const useLoans = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['loans'],
    queryFn: () => getLoans(),
  });
};
