import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getUsersWithLoans = () => {
  return axios.get(`/users/with-loans`);
};

export const useUsersWithLoans = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['usersWithLoans'],
    queryFn: () => getUsersWithLoans(),
  });
};
