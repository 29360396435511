import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const confirmVideoCall = ({ id, data }) => {
  return axios.post(`/loans/confirm-video-call/${id}`, data);
};

export const useConfirmVideoCall = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {},
    onError: (_, __, context) => {},
    onSuccess: (data) => {
      queryClient.refetchQueries(['loan', data.loan._id]);
      addNotification({
        type: 'success',
        title: 'Call Confirmed',
      });
    },
    ...config,
    mutationFn: confirmVideoCall,
  });
};
