import { Button, Dialog, Spinner } from 'components/Elements';
import { Form, InputField } from 'components/Form';
import React from 'react';
import { useAuth } from 'lib/auth';
import { useState } from 'react';
import { useCreateDirector } from '../api/createDirector';

export function CreateDirector() {
  const [show, setShow] = useState(false);

  const createDirectorMutation = useCreateDirector();

  const { user } = useAuth();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <Button onClick={handleOpen}>Create Director</Button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="text-lg mb-4 border-b pb-3">Create New Director</div>
          <Form
            id="create-director"
            onSubmit={async (values) => {
              const result = await createDirectorMutation.mutateAsync({ data: values });
              if (result) {
                handleClose();
              }
            }}
          >
            {({ register, formState }) => (
              <div>
                <InputField
                  label="First Name"
                  error={formState.errors['firstName']}
                  registration={register('firstName')}
                />
                <InputField
                  label="Last Name"
                  error={formState.errors['lastName']}
                  registration={register('lastName')}
                />
                <InputField
                  label="Email Address"
                  error={formState.errors['email']}
                  registration={register('email')}
                />
                <InputField
                  label="Phone Number"
                  error={formState.errors['phone']}
                  registration={register('phone')}
                />
                <div className="flex flex-row items-center space-x-2">
                  <button
                    onClick={handleClose}
                    type="button"
                    className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="mt-4 p-2 px-4 bg-gray-100 w-full flex flex-row justify-center items-center space-x-2 rounded-2xl text-white bg-primary-100"
                  >
                    {createDirectorMutation.isLoading && <Spinner size="sm" />}
                    <div className="">Proceed</div>
                  </button>
                </div>
              </div>
            )}
          </Form>
        </div>
      </Dialog>
    </div>
  );
}
