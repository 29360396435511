import React, { useRef, useState } from 'react';
import { Table, Spinner } from 'components/Elements';
import { useAssets } from '../api/getAssets';
import { Eye } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteAsset } from './DeleteAsset';

export const AssetsList = () => {
  const assetsQuery = useAssets();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [q, setQ] = useState('');
  const [searchColumns, setSearchColumns] = useState(['title', 'description']);

  if (assetsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!assetsQuery.data) return null;

  const columns = [
    {
      title: 'Asset',
      field: 'title',
      Cell: ({ entry: { title, _id } }) => (
        <Link className="flex flex-col" to={`./${_id}`}>
          <div className="font-bold">{title}</div>
          <code className="text-xs text-gray-500">{_id}</code>
        </Link>
      ),
    },
    {
      title: 'Owner',
      field: 'owner',
      Cell: ({ entry: { owner, _id } }) => (
        <Link className="flex flex-col" to={`./${_id}`}>
          <div className="flex items-center">
            <div className="rounded-full w-12 h-12 items-center justify-center text-center p-4 bg-red-100">
              {owner?.basicInformation?.firstName?.charAt(0)}
            </div>
            <div className="ml-2">
              <div className="font-bold">{owner?.authentication?.username}</div>
              <div className="text-xs text-gray-500">
                {owner?.basicInformation?.contactInformation?.email}
              </div>
            </div>
          </div>
        </Link>
      ),
    },
    {
      title: 'Category',
      field: 'category',
      Cell: ({ entry: { category } }) => <>{category}</>,
    },
    {
      title: 'Current Worth',
      field: 'current_worth',
      Cell: ({ entry: { current_worth } }) => <>UGX {current_worth?.toLocaleString()}</>,
    },
    {
      title: 'Assessment Fees',
      field: 'total_assessment_fees',
      Cell: ({ entry: { total_assessment_fees } }) => (
        <>UGX {total_assessment_fees?.toLocaleString()}</>
      ),
    },
    {
      title: '',
      field: '',
      Cell: ({ entry: { _id } }) => (
        <div className="flex flex-row items-center space-x-2">
          <button onClick={() => navigate(`/app/assets/${_id}`)}>
            <Eye className="h-4 w-4" />
          </button>
          <DeleteAsset id={_id} />
        </div>
      ),
    },
  ];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some((column) => {
        const value = extractNestedValue(row, column);
        return value.toString().toLowerCase().includes(q.toLowerCase());
      })
    );
  }

  const extractNestedValue = (obj, path) => {
    const keys = path.split('.');
    return keys.reduce((acc, key) => (acc && acc[key] ? acc[key] : ''), obj);
  };

  let assets = search(assetsQuery.data);

  const handleInputChange = (event) => {
    setQ(event.target.value);
  };

  return (
    <>
      <div className="mt-8 mb-3 flex flex-col md:flex-row space-y-2 md:space-y-0 items-center justify-center">
        <input
          ref={inputRef}
          type="text"
          className="border border-gray-700 p-2 rounded-xl w-full md:w-1/2"
          placeholder="Search for asset title, detail..."
          value={q}
          onChange={handleInputChange}
        />
      </div>
      <div className="text-sm text-gray-600">Displaying assets from all users.</div>
      <Table title="Asset Listings" data={assets} columns={columns} />
    </>
  );
};
