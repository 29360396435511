import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getProduct = ({ id }) => {
  return axios.get(`/products/${id}`);
};

export const useProduct = ({ id, config }) => {
  return useQuery({
    ...config,
    queryKey: ['product', id],
    queryFn: () => getProduct({ id }),
  });
};
