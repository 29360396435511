import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const createProduct = ({ data }) => {
  return axios.post(`/products`, data);
};

createProduct.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};

export const useCreateProduct = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newProduct) => {
      await queryClient.cancelQueries('products');

      const previousProducts = queryClient.getQueryData('products');

      queryClient.setQueryData('products', [...(previousProducts || []), newProduct.data]);

      return { previousProducts };
    },
    onError: (_, __, context) => {
      if (context?.previousProducts) {
        queryClient.setQueryData('products', context.previousProducts);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      addNotification({
        type: 'success',
        title: 'Product Created',
      });
    },
    ...config,
    mutationFn: createProduct,
  });
};
