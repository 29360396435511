import { Dialog, DialogDescription, DialogTitle } from 'components/Elements';
import { useAuth } from 'lib/auth';
import React from 'react';
import { splitAndCapitalize } from 'utils/splitAndCapitalize';

export function AdminMode() {
  const [show, setShow] = React.useState(false);

  const { user } = useAuth();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <button type="button" onClick={handleOpen}>
        {splitAndCapitalize(user.role)} Mode
      </button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">
            {splitAndCapitalize(user.role)} Mode
          </DialogTitle>
          <DialogDescription>
            {user.role === 'SUPER_ADMIN'
              ? `You are currently in Super Admin mode which grants all permissions to the entire system.`
              : `You are currently in ${splitAndCapitalize(
                  user.role
                )} which allows you to view and edit
            data with the permissions assigned to your role.`}
          </DialogDescription>
          <div className="flex flex-row items-center space-x-2">
            <button
              onClick={handleClose}
              className="mt-4 p-2 px-4 bg-primary-100 w-full text-white rounded-2xl"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
