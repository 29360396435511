import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { Button } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { FilePlus } from 'react-feather';
import { UsersList } from '../components/UsersList';
import { Authorization, ROLES } from 'lib/authorization';
import { CreateUser } from '../components/CreateUser';

export const Users = () => {
  const routes = [
    {
      name: 'Accounts & Portfolios',
      path: '/app/accounts',
    },
  ];
  const navigate = useNavigate();

  return (
    <ContentLayout
      title="Accounts & Portfolios"
      end={
        <div className="flex flex-row items-center space-x-2">
          <Button size="sm" onClick={() => navigate('/app/accounts/portfolios')}>
            Lender Portfolios
          </Button>
          <CreateUser
            triggerButton={
              <div className="flex flex-row items-center space-x-2">
                <Button size="sm" startIcon={<FilePlus size={16} />}>
                  Create New Account
                </Button>
              </div>
            }
          />
        </div>
      }
    >
      <Breadcrumbs routes={routes} />
      <Authorization
        forbiddenFallback={<div>Only admin can view this.</div>}
        allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.DEVELOPER]}
      >
        <div className="mt-8">
          <UsersList />
        </div>
      </Authorization>
    </ContentLayout>
  );
};
