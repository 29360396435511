import { Navigate, Route, Routes } from 'react-router-dom';
import { Directors } from './Directors';
import { Director } from './Director';

export const DirectorsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Directors />} />
      <Route path=":id" element={<Director />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
