import { Navigate, Route, Routes } from 'react-router-dom';
import { Repayments } from './Repayments';
import { Repayment } from './Repayment';


export const RepaymentsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Repayments />} />
      <Route path=":id" element={<Repayment />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
