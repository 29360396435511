import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, FormDrawer, InputField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PlusIcon } from '@heroicons/react/outline';

import { useCreateServiceFee } from '../api/createServiceFee';

export const CreateServiceFee = ({ id }) => {
  const createServiceFeeMutation = useCreateServiceFee();

  if (createServiceFeeMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.SUPER_ADMIN]}>
      <FormDrawer
        isDone={createServiceFeeMutation.isSuccess}
        size="md"
        triggerButton={
          <Button size="sm" startIcon={<PlusIcon className="h-4 w-4" />}>
            Add Service Fee
          </Button>
        }
        title="Create Service Fee"
        submitButton={
          <Button
            form="create-service-fee"
            type="submit"
            size="sm"
            isLoading={createServiceFeeMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="create-service-fee"
          onSubmit={async (values) => {
            await createServiceFeeMutation.mutateAsync({ data: values, id });
          }}
        >
          {({ register, formState }) => (
            <>
              <InputField
                label="Name"
                error={formState.errors['name']}
                registration={register('name')}
              />
              <InputField
                label="Minimum Amount"
                error={formState.errors['min']}
                registration={register('min')}
              />
              <InputField
                label="Maximum Amount"
                error={formState.errors['max']}
                registration={register('max')}
              />
              <InputField
                label="Service Fee Percentage"
                error={formState.errors['percentage']}
                registration={register('percentage')}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};
