export function generatePaymentSummary(payments, amount) {
  const totalInstallments = payments.length;
  let totalPaid = 0;
  let paidInstallments = 0;

  payments.forEach((payment) => {
    if (payment.status === 'PAID') {
      totalPaid += payment.installmentAmount;
      paidInstallments++;
    }
  });

  const remainingInstallments = totalInstallments - paidInstallments;
  const remainingAmount = amount - totalPaid;

  return `${paidInstallments} of ${totalInstallments}, UGX ${remainingAmount.toLocaleString()}, ${totalPaid.toLocaleString()} paid`;
}
