import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { Authorization, ROLES } from 'lib/authorization';
import { TrashIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

import { useDeleteDirector } from '../api/deleteDirector';

export const DeleteDirector = ({ id }) => {
  const deleteDirectorMutation = useDeleteDirector();

  if (deleteDirectorMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.SUPER_ADMIN]}>
      <ConfirmationDialog
        icon="danger"
        title="Remove Director"
        body="Are you sure you want to remove this director?"
        triggerButton={
          <button>
            <TrashIcon className="h-4 w-4" />
          </button>
        }
        confirmButton={
          <Button
            isLoading={deleteDirectorMutation.isLoading}
            type="button"
            className="bg-red-600"
            onClick={async () => await deleteDirectorMutation.mutateAsync({ id: id })}
          >
            Trash
          </Button>
        }
      />
    </Authorization>
  );
};

DeleteDirector.propTypes = {
  id: PropTypes.string.isRequired,
};
