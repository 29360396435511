import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getLoan = ({ id }) => {
  return axios.get(`/loans/${id}`);
};

export const useLoan = ({ id, config }) => {
  return useQuery({
    ...config,
    queryKey: ['loan', id],
    queryFn: () => getLoan({ id }),
  });
};
