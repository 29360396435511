import { Button, Spinner } from 'components/Elements';
import {
  Form,
  InputField,
  LocationField,
  ReactSelectField,
  RegularTextAreaField,
  SelectField,
} from 'components/Form';
import { Head } from 'components/Head';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useCreateAsset } from '../api/createAsset';
import { useNavigate } from 'react-router-dom';
import { useProducts } from 'features/products';
import { dataURItoBlob } from 'utils/dataURIToBlob';
import phoneBrands from '../data/phoneBrands.json';
import phoneModels from '../data/phoneModels.json';

export const CreateAsset = () => {
  const [category, setCategory] = useState('');
  const productsQuery = useProducts();
  const [assetDetails, setAssetDetails] = useState(false);
  const [pictures, setPictures] = useState(false);
  const [valuation, setValuation] = useState(false);
  const createAssetMutation = useCreateAsset();
  const [assetImages, setAssetImages] = useState(Array(8).fill(null));
  const [vehicleSubCategory, setVehicleSubCategory] = useState('private_vehicles');
  const [landLocation, setLandLocation] = useState('');
  const [whatsOnLand, setWhatsOnLand] = useState();
  const [brand, setBrand] = useState();
  const [product, setProduct] = useState();
  const [nature_of_electronic, setNatureOfElectronic] = useState();
  const navigate = useNavigate();

  const handleImageChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const newImages = [...assetImages];
        newImages[index] = reader.result;
        setAssetImages(newImages);
      };
      reader.readAsDataURL(file);
    }
  };

  if (productsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!productsQuery.data) return null;

  return (
    <div className="mx-12 md:mx-48 mt-8">
      <Head title="Create Asset" />
      <div className="mb-4 flex flex-row items-center justify-between">
        <div className="">
          <div className="font-semibold text-sm md:text-lg">New Collateral Asset</div>
          <div className="text-sm">
            Please note that you are creating an asset using an account with administrative
            previlages.
          </div>
        </div>
        <div className="flex flex-row items-center space-x-2">
          <Button
            size="sm"
            onClick={() => {
              navigate('/app/assets');
            }}
          >
            Close
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-start">
        <div className="bg-white rounded-2xl shadow p-6 flex flex-row md:flex-col w-full">
          <div className="grid grid-cols-1 gap-2">
            <button
              disabled={!category}
              onClick={() => {
                Swal.fire({
                  title: 'Are you sure?',
                  text: 'You will lose all your progress!',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, close it!',
                  cancelButtonText: 'No, keep it',
                }).then((result) => {
                  if (result.isConfirmed) {
                    setCategory('');
                    setPictures(false);
                    setValuation(false);
                    setAssetDetails(false);
                  }
                });
              }}
              className={`${!category && `bg-gray-100`} text-primary-100 p-4 rounded-2xl`}
            >
              <span className="block md:hidden">1</span>
              <span className="hidden md:block">Choose Category</span>
            </button>
          </div>
          <div className="grid grid-cols-1 gap-2">
            <button
              disabled={!category}
              onClick={() => {
                setPictures(false);
                setValuation(false);
                setAssetDetails(true);
              }}
              className={`${assetDetails && `bg-gray-100`} text-gray-500 p-4 rounded-2xl`}
            >
              <span className="block md:hidden">2</span>
              <span className="hidden md:block">Asset Details</span>
            </button>
          </div>
          <div className="grid grid-cols-1 gap-2">
            <button
              disabled={!category}
              onClick={() => {
                setAssetDetails(false);
                setValuation(false);
                setPictures(true);
              }}
              className={`${pictures && `bg-gray-100`} text-gray-500 p-4 rounded-2xl`}
            >
              <span className="block md:hidden">3</span>
              <span className="hidden md:block">Pictures</span>
            </button>
          </div>
          <div className="grid grid-cols-1 gap-2">
            <button
              disabled={!category}
              onClick={() => {
                setAssetDetails(false);
                setValuation(true);
                setPictures(false);
              }}
              className={`${valuation && `bg-gray-100`} text-gray-500 p-4 rounded-2xl`}
            >
              <span className="block md:hidden">4</span>
              <span className="hidden md:block">Valuation</span>
            </button>
          </div>
        </div>
        <div className="md:col-span-3">
          {!category && (
            <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
              <div className="mb-4">Choose Category</div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <button
                  onClick={() => {
                    setCategory('REAL_ESTATE_WITH_LAND_TITLE');
                    setAssetDetails(true);
                  }}
                  className="border border-gray-200 rounded-md p-5"
                >
                  Real Estate With Land Title
                </button>
                <button
                  onClick={() => {
                    setCategory('VEHICLES');
                    setAssetDetails(true);
                  }}
                  className="border border-gray-200 rounded-md p-5"
                >
                  Vehicles
                </button>
                <button
                  onClick={() => {
                    setCategory('TELEVISION');
                    setAssetDetails(true);
                  }}
                  className="border border-gray-200 rounded-md p-5"
                >
                  Television
                </button>
                <button
                  onClick={() => {
                    setCategory('COMPUTERS');
                    setAssetDetails(true);
                  }}
                  className="border border-gray-200 rounded-md p-5"
                >
                  Computer
                </button>
                <button
                  onClick={() => {
                    setCategory('SMART_PHONES');
                    setAssetDetails(true);
                  }}
                  className="border border-gray-200 rounded-md p-5"
                >
                  Smart Phones
                </button>
                <button
                  onClick={() => {
                    setCategory('OTHER_ELECTRONICS');
                    setAssetDetails(true);
                  }}
                  className="border border-gray-200 rounded-md p-5"
                >
                  Other Electronics
                </button>
              </div>
            </div>
          )}
          {category === 'REAL_ESTATE_WITH_LAND_TITLE' && (
            <Form
              id="create-post"
              onSubmit={async (values) => {
                const bodyFormData = new FormData();
                bodyFormData.append('title', values.title);
                bodyFormData.append('category', category);
                bodyFormData.append('land_location', landLocation);
                bodyFormData.append('product', product);
                bodyFormData.append('type_of_title', values.type_of_title);
                bodyFormData.append('size_of_land', values.size_of_land);
                bodyFormData.append('whats_on_land', values.whats_on_land);
                bodyFormData.append('specify_others', values.specify_others);
                bodyFormData.append('description', values.description);
                bodyFormData.append('purchase_amount', values.purchase_amount);
                bodyFormData.append('current_worth', values.current_worth);
                bodyFormData.append('purchase_date', values.purchase_date);

                assetImages.forEach((image, index) => {
                  if (image) {
                    bodyFormData.append(`pictures`, dataURItoBlob(image));
                  }
                });
                await createAssetMutation.mutateAsync({ data: bodyFormData });
                navigate('/app/assets/create/success');
              }}
            >
              {({ register, formState, watch, setValue }) => (
                <>
                  {assetDetails && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Asset Details for real estate</div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="space-y-5">
                          <InputField
                            label="Title of Asset"
                            error={formState.errors['title']}
                            registration={register('title')}
                            caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                          />
                          <ReactSelectField
                            label="Please select Product"
                            type="text"
                            editorContent={watch('product')}
                            setValue={setValue}
                            name="product"
                            defaultValue={product}
                            error={formState.errors['product']}
                            options={productsQuery.data?.map((p) => ({
                              label: p.product_title,
                              value: p._id,
                            }))}
                            onChange={(e) => setProduct(e.value)}
                          />
                          <LocationField
                            label="Land Location"
                            onChange={(value) => setLandLocation(value)}
                            error={formState.errors['land_location']}
                            defaultValue={landLocation}
                          />
                          <SelectField
                            label="Type of Title"
                            registration={register('type_of_title')}
                            options={[
                              { label: 'Private Mailo', value: 'PRIVATE_MAILO' },
                              { label: 'Freehold', value: 'FREEHOLD' },
                              { label: 'Leasehold', value: 'LEASEHOLD' },
                            ]}
                          />
                          <SelectField
                            label="Size of Land"
                            registration={register('size_of_land')}
                            options={[
                              { label: '50*100ft (12.5 decimals)', value: '12_5_DECIMALS' },
                              {
                                label: '50*100ft to half acre (12.5 - 50 decimals)',
                                value: '12_5_50_DECIMALS',
                              },
                              {
                                label: 'Greater than half acre (51 decimals +)',
                                value: '51_+_DECIMALS',
                              },
                            ]}
                          />
                          <SelectField
                            label="What's on the Land"
                            registration={register('whats_on_land')}
                            options={[
                              { label: 'Rentals', value: 'RENTALS' },
                              { label: 'Main House', value: 'MAIN_HOUSE' },
                              { label: 'Main House and Rentals', value: 'MAIN_HOUSE_AND_RENTALS' },
                              { label: 'Apartments', value: 'APARTMENTS' },
                              { label: 'Hotel or Bar', value: 'HOTEL_BAR' },
                              { label: 'School or Market', value: 'SCHOOL_MARKET' },
                              { label: 'Empty Land', value: 'EMPTY_LAND' },
                              { label: 'Others', value: 'OTHERS' },
                            ]}
                            onChange={(selectedValue) => setWhatsOnLand(selectedValue)}
                          />
                          {whatsOnLand === 'OTHERS' && (
                            <InputField
                              label="Specify Others"
                              error={formState.errors['specify_others']}
                              registration={register('specify_others')}
                              caption="You can provide some extra details about the land that can help in the valuation"
                            />
                          )}
                        </div>
                        <div className="space-y-5">
                          <RegularTextAreaField
                            label="Asset Description"
                            error={formState.errors['description']}
                            registration={register('description')}
                            caption="Describe your land in detail. Share any unique features, history, or potential uses. This information will help us understand your property better and tailor our services to meet your needs."
                          />
                        </div>
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() => {
                            Swal.fire({
                              title: 'Are you sure?',
                              text: 'You will lose all your progress!',
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonText: 'Yes, close it!',
                              cancelButtonText: 'No, keep it',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                setCategory('');
                                setAssetDetails(false);
                              }
                            });
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            setAssetDetails(false);
                            setPictures(true);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}
                  {pictures && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Pictures</div>
                      <div className="mb-2">
                        Please ensure the uploaded images are clear and follow the guidelines on
                        what picture angles to take.
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {[...Array(15).keys()].map((index) => (
                          <div key={index} className="relative">
                            <input
                              type="file"
                              id={`image-input-${index}`}
                              accept="image/*"
                              className="hidden"
                              onChange={(event) => handleImageChange(index, event)}
                            />
                            <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                              <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                {assetImages[index] ? (
                                  <img
                                    src={assetImages[index]}
                                    alt={`Uploaded Image ${index + 1}`}
                                    className="max-w-full max-h-full rounded-lg"
                                  />
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-12 w-12 text-gray-500"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                )}
                              </div>
                              <span className="block text-center mt-2 text-gray-500">
                                {index === 0 && 'Wide landscape view of land'}
                                {index === 1 && 'Land with access road visible'}
                                {index === 2 && 'Neighborhood view from land'}
                                {index === 3 && 'Additional picture of neighbourhood'}
                                {index === 4 && 'Markstone/boundary point on land'}
                                {index === 5 && 'Land title - Front page'}
                                {index === 6 && 'Land title - Print page'}
                                {index === 7 && 'Land title - Encumbrances'}
                                {index === 8 && 'Additional pages of land title'}
                                {index === 9 && 'Exterior landscape of building'}
                                {index === 10 && 'Living room portrait'}
                                {index === 11 && 'Dining room portrait'}
                                {index === 12 && 'Kitchen portrait'}
                                {index >= 13 && `Bedroom portrait ${index - 12}`}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            setAssetDetails(true);
                            setPictures(false);
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            setPictures(false);
                            setValuation(true);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}

                  {valuation && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Valuation</div>
                      <div className="mb-2">
                        To enhance your prospects of securing a loan, it's imperative to furnish
                        precise and comprehensive information.
                      </div>
                      <div className="md:mx-48">
                        <InputField
                          label="How much did you buy it at?"
                          error={formState.errors['purchase_amount']}
                          registration={register('purchase_amount')}
                        />
                        <InputField
                          label="How much is it worth currently?"
                          error={formState.errors['current_worth']}
                          registration={register('current_worth')}
                        />
                        <InputField
                          type="date"
                          label="When did you buy it?"
                          error={formState.errors['purchase_date']}
                          registration={register('purchase_date')}
                        />
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            setValuation(false);
                            setPictures(true);
                          }}
                        >
                          Back
                        </Button>
                        <Button isLoading={createAssetMutation.isLoading} type="submit">
                          Finish
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Form>
          )}
          {category === 'VEHICLES' && (
            <Form
              id="create-post"
              onSubmit={async (values) => {
                const bodyFormData = new FormData();
                bodyFormData.append('title', values.title);
                bodyFormData.append('category', category);
                bodyFormData.append('vehicle_sub_category', values.vehicle_sub_category);
                bodyFormData.append('brand', values.brand);
                bodyFormData.append('product', values.product);
                bodyFormData.append('mileage', values.mileage);
                bodyFormData.append('model', values.model);
                bodyFormData.append('year_of_manufacture', values.year_of_manufacture);
                bodyFormData.append('color', values.color);
                bodyFormData.append('description', values.description);
                bodyFormData.append('purchase_amount', values.purchase_amount);
                bodyFormData.append('current_worth', values.current_worth);
                bodyFormData.append('purchase_date', values.purchase_date);
                bodyFormData.append('specify_others', values.specify_others);
                bodyFormData.append('tracker', values.tracker);
                bodyFormData.append('color', values.color);
                bodyFormData.append('parking_status', values.parking_status);
                assetImages.forEach((image, index) => {
                  if (image) {
                    bodyFormData.append(`pictures`, dataURItoBlob(image));
                  }
                });
                await createAssetMutation.mutateAsync({ data: bodyFormData });
                navigate('/app/assets/create/success');
              }}
            >
              {({ register, formState, watch, setValue }) => (
                <>
                  {assetDetails && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Asset Details for Vehicle</div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="space-y-5">
                          <InputField
                            label="Title of Asset"
                            error={formState.errors['title']}
                            registration={register('title')}
                            caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                          />
                          <ReactSelectField
                            label="Please select Product"
                            type="text"
                            editorContent={watch('product')}
                            setValue={setValue}
                            name="product"
                            defaultValue={watch('product')}
                            error={formState.errors['product']}
                            options={productsQuery.data?.map((p) => ({
                              label: p.product_title,
                              value: p._id,
                            }))}
                          />
                          <SelectField
                            label="Select Vehicle Category"
                            registration={register('vehicle_sub_category')}
                            options={[
                              { label: 'Private Vehicles', value: 'personal_vehicles' },
                              { label: 'Bikes', value: 'bikes' },
                              { label: 'Heavy Vehicles', value: 'heavy_vehicles' },
                              { label: 'Commercial Vehicles', value: 'commercial_vehicles' },
                            ]}
                            onChange={(e) => setVehicleSubCategory(e.target.value)}
                          />
                          {vehicleSubCategory && (
                            <>
                              {vehicleSubCategory !== 'bikes' ? (
                                <>
                                  <ReactSelectField
                                    label="Select Car Brand"
                                    type="text"
                                    editorContent={watch('brand')}
                                    setValue={setValue}
                                    name="brand"
                                    defaultValue={watch('brand')}
                                    error={formState.errors['brand']}
                                    options={[
                                      { label: 'Toyota', value: 'TOYOTA' },
                                      { label: 'Nissan', value: 'NISSAN' },
                                      { label: 'Subaru', value: 'SUBARU' },
                                      { label: 'Mitsubishi', value: 'MITSUBISHI' },
                                      { label: 'Honda', value: 'HONDA' },
                                      { label: 'Mercedes Benz', value: 'MERCEDES_BENZ' },
                                      { label: 'BMW', value: 'BMW' },
                                      { label: 'Audi', value: 'AUDI' },
                                      { label: 'Volkswagen', value: 'VOLKSWAGEN' },
                                      { label: 'Peugeot', value: 'PEUGEOT' },
                                      { label: 'Suzuki', value: 'SUZUKI' },
                                      { label: 'Hyundai', value: 'HYUNDAI' },
                                      { label: 'Kia', value: 'KIA' },
                                      { label: 'Ford', value: 'FORD' },
                                      { label: 'Chevrolet', value: 'CHEVROLET' },
                                      { label: 'Jeep', value: 'JEEP' },
                                      { label: 'Range Rover', value: 'RANGE_ROVER' },
                                      { label: 'Land Rover', value: 'LAND_ROVER' },
                                      { label: 'Lexus', value: 'LEXUS' },
                                      { label: 'Mazda', value: 'MAZDA' },
                                      { label: 'Volvo', value: 'VOLVO' },
                                      { label: 'Porsche', value: 'PORSCHE' },
                                      { label: 'Jaguar', value: 'JAGUAR' },
                                      { label: 'Mini', value: 'MINI' },
                                      { label: 'Fiat', value: 'FIAT' },
                                      { label: 'Skoda', value: 'SKODA' },
                                      { label: 'Other', value: 'OTHER' },
                                    ]}
                                    onChange={(e) => setBrand(e.value)}
                                  />
                                </>
                              ) : (
                                <ReactSelectField
                                  label="Select Bike Brand"
                                  type="text"
                                  editorContent={watch('brand')}
                                  setValue={setValue}
                                  name="brand"
                                  defaultValue={watch('brand')}
                                  error={formState.errors['brand']}
                                  options={[
                                    { label: 'Bajaj', value: 'BAJAJ' },
                                    { label: 'TVS', value: 'TVS' },
                                    { label: 'Honda', value: 'HONDA' },
                                    { label: 'Yamaha', value: 'YAMAHA' },
                                    { label: 'Suzuki', value: 'SUZUKI' },
                                    { label: 'Other', value: 'OTHER' },
                                  ]}
                                  onChange={(e) => setBrand(e.target.value)}
                                />
                              )}
                              {brand === 'OTHER' && (
                                <InputField
                                  label="Specify Other Brand"
                                  error={formState.errors['specify_others']}
                                  registration={register('specify_others')}
                                  caption="Specify a brand in case youl can't find yours in the list"
                                />
                              )}
                              <ReactSelectField
                                label="Tracker"
                                type="text"
                                editorContent={watch('tracker')}
                                setValue={setValue}
                                name="tracker"
                                defaultValue={watch('tracker')}
                                error={formState.errors['tracker']}
                                options={[
                                  { label: 'Hire', value: 'HIRE' },
                                  { label: 'Buy', value: 'BUY' },
                                ]}
                              />
                              {vehicleSubCategory !== 'bikes' ? (
                                <ReactSelectField
                                  label="Select Car Model"
                                  type="text"
                                  editorContent={watch('model')}
                                  setValue={setValue}
                                  name="model"
                                  defaultValue={watch('model')}
                                  error={formState.errors['model']}
                                  options={[
                                    { label: 'Saloon', value: 'SALOON' },
                                    { label: 'SUV', value: 'SUV' },
                                    { label: 'Station Wagon', value: 'STATION_WAGON' },
                                    { label: 'Pickup', value: 'PICKUP' },
                                    { label: 'Van', value: 'VAN' },
                                    { label: 'Truck', value: 'TRUCK' },
                                    { label: 'Bus', value: 'BUS' },
                                    { label: 'Other', value: 'OTHER' },
                                  ]}
                                />
                              ) : (
                                <>
                                  <ReactSelectField
                                    label="Select Bike Model"
                                    type="text"
                                    editorContent={watch('model')}
                                    setValue={setValue}
                                    name="model"
                                    defaultValue={watch('model')}
                                    error={formState.errors['model']}
                                    options={[
                                      { label: 'Boda Boda', value: 'BODA_BODA' },
                                      { label: 'Sport Bike', value: 'SPORT_BIKE' },
                                      { label: 'Cruiser', value: 'CRUISER' },
                                      { label: 'Dirt Bike', value: 'DIRT_BIKE' },
                                      { label: 'Other', value: 'OTHER' },
                                    ]}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <div className="space-y-5">
                          <SelectField
                            label="Select Mileage"
                            registration={register('mileage')}
                            options={[
                              { label: '0 - 90000', value: '0_90000' },
                              {
                                label: '91000 - 180000',
                                value: '91000_180000',
                              },
                              {
                                label: '181000 - 300000',
                                value: '181000_300000',
                              },
                            ]}
                          />
                          <SelectField
                            label="Parking Status"
                            registration={register('parking_status')}
                            options={[
                              { label: 'Not Parked', value: 'NOT_PARKED' },
                              { label: 'Parked', value: 'PARKED' },
                            ]}
                          />

                          <InputField
                            label="Year of Manufacture"
                            error={formState.errors['year_of_manufacture']}
                            registration={register('year_of_manufacture')}
                          />
                          {/* <InputField
                            label="Color"
                            error={formState.errors['color']}
                            registration={register('color')}
                          /> */}
                          <RegularTextAreaField
                            label="Asset Description"
                            error={formState.errors['description']}
                            registration={register('description')}
                            caption="Any extra information you’d like us to know about your vehicle. This will
                        guide us on serving you better."
                          />
                        </div>
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() => {
                            Swal.fire({
                              title: 'Are you sure?',
                              text: 'You will lose all your progress!',
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonText: 'Yes, close it!',
                              cancelButtonText: 'No, keep it',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                setCategory('');
                                setAssetDetails(false);
                              }
                            });
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            setAssetDetails(false);
                            setPictures(true);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}
                  {pictures && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Pictures</div>
                      <div className="mb-2">
                        Please ensure the uploaded vehicle images are clear and follow the
                        guidelines on what picture angles to take.
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {[...Array(12).keys()].map((index) => (
                          <div key={index} className="relative">
                            <input
                              type="file"
                              id={`image-input-${index}`}
                              accept="image/*"
                              className="hidden"
                              onChange={(event) => handleImageChange(index, event)}
                            />
                            <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                              <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                {assetImages[index] ? (
                                  <img
                                    src={assetImages[index]}
                                    alt={`Uploaded Image ${index + 1}`}
                                    className="max-w-full max-h-full rounded-lg"
                                  />
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-12 w-12 text-gray-500"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                )}
                              </div>
                              <span className="block text-center mt-2 text-gray-500">
                                {index === 0 && 'Front view (show number plate)'}
                                {index === 1 && 'Side view (both sides)'}
                                {index === 2 && 'Back view'}
                                {index === 3 &&
                                  'Close-ups of any existing damage or unique features'}
                                {index === 4 && "Driver's seat area"}
                                {index === 5 && 'Passenger seating area'}
                                {index === 6 && 'Dashboard and control panel'}
                                {index === 7 && 'Close-ups of odometer, indicating mileage'}
                                {index === 8 && 'Underside of vehicle'}
                                {index === 9 && 'Close-up of Wheels and Tyres'}
                                {index === 10 && 'Engine'}
                                {index === 11 && 'Logbook'}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          variant="outline"
                          onClick={() => {
                            setAssetDetails(true);
                            setPictures(false);
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            setPictures(false);
                            setValuation(true);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}

                  {valuation && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Valuation</div>
                      <div className="mb-2">
                        To enhance your prospects of securing a loan, it's imperative to furnish
                        precise and comprehensive information.
                      </div>
                      <div className="md:mx-48">
                        <InputField
                          label="How much did you buy the vehicle?"
                          error={formState.errors['purchase_amount']}
                          registration={register('purchase_amount')}
                        />
                        <InputField
                          label="How much is it worth currently?"
                          error={formState.errors['current_worth']}
                          registration={register('current_worth')}
                        />
                        <InputField
                          type="date"
                          label="When did you buy it?"
                          error={formState.errors['purchase_date']}
                          registration={register('purchase_date')}
                        />
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          variant="outline"
                          onClick={() => {
                            setValuation(false);
                            setPictures(true);
                          }}
                        >
                          Back
                        </Button>
                        <Button isLoading={createAssetMutation.isLoading} type="submit">
                          Finish
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Form>
          )}
          {category === 'SMART_PHONES' && (
            <Form
              id="create-post"
              onSubmit={async (values) => {
                const bodyFormData = new FormData();
                bodyFormData.append('title', values.title);
                bodyFormData.append('category', category);
                bodyFormData.append('brand', brand);
                bodyFormData.append('specify_others', values.specify_others);
                bodyFormData.append('ram_size', values.ram_size);
                bodyFormData.append('storage_space', values.storage_space);
                bodyFormData.append('model', values.model);
                bodyFormData.append('product', values.product);
                bodyFormData.append('description', values.description);
                bodyFormData.append('purchase_amount', values.purchase_amount);
                bodyFormData.append('purchase_date', values.purchase_date);
                bodyFormData.append('current_worth', values.current_worth);
                assetImages.forEach((image, index) => {
                  if (image) {
                    bodyFormData.append(`pictures`, dataURItoBlob(image));
                  }
                });
                await createAssetMutation.mutateAsync({ data: bodyFormData });
                navigate('/app/assets/create/success');
              }}
            >
              {({ register, formState, watch, setValue }) => (
                <>
                  {assetDetails && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Asset Details for Smart Phone</div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="space-y-5">
                          <InputField
                            label="Title of Asset"
                            error={formState.errors['title']}
                            registration={register('title')}
                            caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                          />
                          <ReactSelectField
                            label="Please select Product"
                            type="text"
                            editorContent={watch('product')}
                            setValue={setValue}
                            name="product"
                            defaultValue={watch('product')}
                            error={formState.errors['product']}
                            options={productsQuery.data?.map((p) => ({
                              label: p.product_title,
                              value: p._id,
                            }))}
                          />
                          <ReactSelectField
                            label="Select Mobile Phone Brand"
                            type="text"
                            editorContent={watch('brand')}
                            setValue={setValue}
                            name="brand"
                            defaultValue={watch('brand')}
                            error={formState.errors['brand']}
                            options={phoneBrands}
                            onChange={(e) => setBrand(e.value)}
                          />
                          {brand === 'OTHER' && (
                            <InputField
                              label="Specify Other Brand"
                              error={formState.errors['specify_others']}
                              registration={register('specify_others')}
                              caption="Specify a brand in case you can't find yours in the list"
                            />
                          )}
                          <SelectField
                            label="RAM Size"
                            registration={register('ram_size')}
                            options={[
                              { label: '1 - 2 GB', value: '1-2' },
                              { label: '2 - 4 GB', value: '2-4' },
                              { label: '4 - 6 GB', value: '4-6' },
                              { label: '6+ GB', value: '6+' },
                            ]}
                          />
                          <ReactSelectField
                            label="Select Phone Model"
                            type="text"
                            editorContent={watch('model')}
                            setValue={setValue}
                            name="model"
                            defaultValue={watch('model')}
                            error={formState.errors['model']}
                            options={phoneModels}
                          />
                          <SelectField
                            label="Storage Space"
                            type="text"
                            editorContent={watch('storage_space')}
                            setValue={setValue}
                            name="storage_space"
                            defaultValue={watch('storage_space')}
                            registration={register('storage_space')}
                            error={formState.errors['storage_space']}
                            options={[
                              { value: '16-32', label: '16-32' },
                              { value: '32-64', label: '32-64' },
                              { value: '64-128', label: '64-128' },
                              { value: '128-256', label: '128-256' },
                            ]}
                          />
                        </div>
                        <div className="space-y-5">
                          <RegularTextAreaField
                            label="Asset Description"
                            error={formState.errors['description']}
                            registration={register('description')}
                            caption="Any extra information you’d like us to know about your smart phone. This will
                        guide us on serving you better."
                          />
                        </div>
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() => {
                            Swal.fire({
                              title: 'Are you sure?',
                              text: 'You will lose all your progress!',
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonText: 'Yes, close it!',
                              cancelButtonText: 'No, keep it',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                setCategory('');
                                setAssetDetails(false);
                              }
                            });
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            setAssetDetails(false);
                            setPictures(true);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}
                  {pictures && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Pictures</div>
                      <div className="mb-2">
                        Please ensure the uploaded images of your mobile phone are clear and follow
                        the guidelines on what picture angles to take.
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        {[...Array(4).keys()].map((index) => (
                          <div key={index} className="relative">
                            <input
                              type="file"
                              id={`image-input-${index}`}
                              accept="image/*"
                              className="hidden"
                              onChange={(event) => handleImageChange(index, event)}
                            />
                            <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                              <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                {assetImages[index] ? (
                                  <img
                                    src={assetImages[index]}
                                    alt={`Uploaded Image ${index + 1}`}
                                    className="max-w-full max-h-full rounded-lg"
                                  />
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-12 w-12 text-gray-500"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                )}
                              </div>
                              <span className="block text-center mt-2 text-gray-500">
                                {assetImages[index]
                                  ? `Change Image ${index + 1}`
                                  : `Upload Image ${index + 1}`}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          variant="outline"
                          onClick={() => {
                            setAssetDetails(true);
                            setPictures(false);
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            setPictures(false);
                            setValuation(true);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}

                  {valuation && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Valuation</div>
                      <div className="mb-2">
                        To enhance your prospects of securing a loan, it's imperative to furnish
                        precise and comprehensive information.
                      </div>
                      <div className="md:mx-48">
                        <InputField
                          label="How much did you buy the mobile phone?"
                          error={formState.errors['purchase_amount']}
                          registration={register('purchase_amount')}
                        />
                        <InputField
                          label="How much is it worth currently?"
                          error={formState.errors['current_worth']}
                          registration={register('current_worth')}
                        />
                        <InputField
                          type="date"
                          label="When did you buy it?"
                          error={formState.errors['purchase_date']}
                          registration={register('purchase_date')}
                        />
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          variant="outline"
                          onClick={() => {
                            setValuation(false);
                            setPictures(true);
                          }}
                        >
                          Back
                        </Button>
                        <Button isLoading={createAssetMutation.isLoading} type="submit">
                          Finish
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Form>
          )}
          {category === 'TELEVISION' && (
            <Form
              id="create-post"
              onSubmit={async (values) => {
                const bodyFormData = new FormData();
                bodyFormData.append('title', values.title);
                bodyFormData.append('category', category);
                bodyFormData.append('brand', brand);
                bodyFormData.append('specify_others', values.specify_others);
                bodyFormData.append('dimension', values.dimension);
                bodyFormData.append('smart_tv', values.smart_tv);
                bodyFormData.append('product', values.product);
                bodyFormData.append('description', values.description);
                bodyFormData.append('purchase_amount', values.purchase_amount);
                bodyFormData.append('purchase_date', values.purchase_date);
                bodyFormData.append('current_worth', values.current_worth);
                assetImages.forEach((image, index) => {
                  if (image) {
                    bodyFormData.append(`pictures`, dataURItoBlob(image));
                  }
                });
                await createAssetMutation.mutateAsync({ data: bodyFormData });
                navigate('/app/assets/create/success');
              }}
            >
              {({ register, formState, watch, setValue }) => (
                <>
                  {assetDetails && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Asset Details for Television</div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="space-y-5">
                          <InputField
                            label="Title of Asset"
                            error={formState.errors['title']}
                            registration={register('title')}
                            caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                          />
                          <ReactSelectField
                            label="Please select Product"
                            type="text"
                            editorContent={watch('product')}
                            setValue={setValue}
                            name="product"
                            defaultValue={watch('product')}
                            error={formState.errors['product']}
                            options={productsQuery.data?.map((p) => ({
                              label: p.product_title,
                              value: p._id,
                            }))}
                          />
                          <ReactSelectField
                            label="Select Television Brand"
                            type="text"
                            editorContent={watch('brand')}
                            setValue={setValue}
                            name="brand"
                            defaultValue={watch('brand')}
                            error={formState.errors['brand']}
                            options={[
                              { label: 'Samsung', value: 'SAMSUNG' },
                              { label: 'Apple', value: 'APPLE' },
                              { label: 'Huawei', value: 'HUAWEI' },
                              { label: 'Xiaomi', value: 'XIAOMI' },
                              { label: 'Oppo', value: 'OPPO' },
                              { label: 'Vivo', value: 'VIVO' },
                              { label: 'Tecno', value: 'TECNO' },
                              { label: 'Hisense', value: 'HISENSE' },
                              { label: 'Nokia', value: 'NOKIA' },
                              { label: 'Sony', value: 'SONY' },
                              { label: 'LG', value: 'LG' },
                              { label: 'Motorola', value: 'MOTOROLA' },
                              { label: 'OnePlus', value: 'ONEPLUS' },
                              { label: 'Google', value: 'GOOGLE' },
                              { label: 'Blackberry', value: 'BLACKBERRY' },
                              { label: 'Other', value: 'OTHER' },
                            ]}
                            onChange={(e) => setBrand(e.value)}
                          />
                          {brand === 'OTHER' && (
                            <InputField
                              label="Specify Other Brand"
                              error={formState.errors['specify_others']}
                              registration={register('specify_others')}
                              caption="Specify a brand in case you can't find yours in the list"
                            />
                          )}
                          <SelectField
                            label="Dimensions"
                            registration={register('dimension')}
                            options={[
                              { label: '24', value: '24' },
                              { label: '32', value: '32' },
                              { label: '40', value: '40' },
                              { label: '43', value: '43' },
                              { label: '50', value: '50' },
                              { label: '55', value: '55' },
                              { label: '65', value: '65' },
                              { label: '75', value: '75' },
                              { label: '85', value: '85' },
                              { label: '100', value: '100' },
                            ]}
                          />
                          <SelectField
                            label="Smart TV?"
                            registration={register('smart_tv')}
                            options={[
                              { label: 'Yes', value: true },
                              { label: 'No', value: false },
                            ]}
                          />
                        </div>
                        <div className="space-y-5">
                          <RegularTextAreaField
                            label="Asset Description"
                            error={formState.errors['description']}
                            registration={register('description')}
                            caption="Any extra information you’d like us to know about your television. This will
                        guide us on serving you better."
                          />
                        </div>
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() => {
                            Swal.fire({
                              title: 'Are you sure?',
                              text: 'You will lose all your progress!',
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonText: 'Yes, close it!',
                              cancelButtonText: 'No, keep it',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                setCategory('');
                                setAssetDetails(false);
                              }
                            });
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            setAssetDetails(false);
                            setPictures(true);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}
                  {pictures && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Pictures</div>
                      <div className="mb-2">
                        Please ensure the uploaded images of your television are clear and follow
                        the guidelines on what picture angles to take.
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        {[...Array(4).keys()].map((index) => (
                          <div key={index} className="relative">
                            <input
                              type="file"
                              id={`image-input-${index}`}
                              accept="image/*"
                              className="hidden"
                              onChange={(event) => handleImageChange(index, event)}
                            />
                            <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                              <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                {assetImages[index] ? (
                                  <img
                                    src={assetImages[index]}
                                    alt={`Uploaded Image ${index + 1}`}
                                    className="max-w-full max-h-full rounded-lg"
                                  />
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-12 w-12 text-gray-500"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                )}
                              </div>
                              <span className="block text-center mt-2 text-gray-500">
                                {assetImages[index]
                                  ? `Change Image ${index + 1}`
                                  : `Upload Image ${index + 1}`}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          variant="outline"
                          onClick={() => {
                            setAssetDetails(true);
                            setPictures(false);
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            setPictures(false);
                            setValuation(true);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}

                  {valuation && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Valuation</div>
                      <div className="mb-2">
                        To enhance your prospects of securing a loan, it's imperative to furnish
                        precise and comprehensive information.
                      </div>
                      <div className="md:mx-48">
                        <InputField
                          label="How much did you buy the mobile phone?"
                          error={formState.errors['purchase_amount']}
                          registration={register('purchase_amount')}
                        />
                        <InputField
                          label="How much is it worth currently?"
                          error={formState.errors['current_worth']}
                          registration={register('current_worth')}
                        />
                        <InputField
                          type="date"
                          label="When did you buy it?"
                          error={formState.errors['purchase_date']}
                          registration={register('purchase_date')}
                        />
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          variant="outline"
                          onClick={() => {
                            setValuation(false);
                            setPictures(true);
                          }}
                        >
                          Back
                        </Button>
                        <Button isLoading={createAssetMutation.isLoading} type="submit">
                          Finish
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Form>
          )}
          {category === 'COMPUTERS' && (
            <Form
              id="create-post"
              onSubmit={async (values) => {
                const bodyFormData = new FormData();
                bodyFormData.append('title', values.title);
                bodyFormData.append('category', category);
                bodyFormData.append('brand', brand);
                bodyFormData.append('specify_others', values.specify_others);
                bodyFormData.append('ram_size', values.ram_size);
                bodyFormData.append('hard_disk_space', values.hard_disk_space);
                bodyFormData.append('processor_speed', values.processor_speed);
                bodyFormData.append('product', values.product);
                bodyFormData.append('description', values.description);
                bodyFormData.append('purchase_amount', values.purchase_amount);
                bodyFormData.append('purchase_date', values.purchase_date);
                bodyFormData.append('current_worth', values.current_worth);
                assetImages.forEach((image, index) => {
                  if (image) {
                    bodyFormData.append(`pictures`, dataURItoBlob(image));
                  }
                });
                await createAssetMutation.mutateAsync({ data: bodyFormData });
                navigate('/app/assets/create/success');
              }}
            >
              {({ register, formState, watch, setValue }) => (
                <>
                  {assetDetails && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Asset Details for Computer</div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="space-y-5">
                          <InputField
                            label="Title of Asset"
                            error={formState.errors['title']}
                            registration={register('title')}
                            caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                          />
                          <ReactSelectField
                            label="Please select Product"
                            type="text"
                            editorContent={watch('product')}
                            setValue={setValue}
                            name="product"
                            defaultValue={watch('product')}
                            error={formState.errors['product']}
                            options={productsQuery.data?.map((p) => ({
                              label: p.product_title,
                              value: p._id,
                            }))}
                          />
                          <ReactSelectField
                            label="Select Computer Brand"
                            type="text"
                            editorContent={watch('brand')}
                            setValue={setValue}
                            name="brand"
                            defaultValue={watch('brand')}
                            error={formState.errors['brand']}
                            options={[
                              { label: 'Apple', value: 'APPLE' },
                              { label: 'Dell', value: 'DELL' },
                              { label: 'HP', value: 'HP' },
                              { label: 'Lenovo', value: 'LENOVO' },
                              { label: 'Acer', value: 'ACER' },
                              { label: 'Asus', value: 'ASUS' },
                              { label: 'Microsoft', value: 'MICROSOFT' },
                              { label: 'Toshiba', value: 'TOSHIBA' },
                              { label: 'Samsung', value: 'SAMSUNG' },
                              { label: 'LG', value: 'LG' },
                              { label: 'Sony', value: 'SONY' },
                              { label: 'Other', value: 'OTHER' },
                            ]}
                            onChange={(e) => setBrand(e.value)}
                          />
                          {brand === 'OTHER' && (
                            <InputField
                              label="Specify Other Brand"
                              error={formState.errors['specify_others']}
                              registration={register('specify_others')}
                              caption="Specify a brand in case you can't find yours in the list"
                            />
                          )}
                          <SelectField
                            label="RAM Size"
                            registration={register('ram_size')}
                            options={[
                              { value: '', label: 'Select..' },
                              { label: '1 - 2 GB', value: '1-2' },
                              { label: '2 - 4 GB', value: '2-4' },
                              { label: '4 - 6 GB', value: '4-6' },
                              { label: '6+ GB', value: '6+' },
                            ]}
                          />
                          <ReactSelectField
                            label="Select Processor Speed"
                            type="text"
                            editorContent={watch('processor_speed')}
                            setValue={setValue}
                            name="processor_speed"
                            defaultValue={watch('processor_speed')}
                            error={formState.errors['processor_speed']}
                            options={[
                              { value: '', label: 'Select..' },
                              { value: '1-2', label: '1-2' },
                              { value: '2-3', label: '2-3' },
                              { value: '3+', label: '3+' },
                            ]}
                          />
                          <SelectField
                            label="Hard Disk Space"
                            type="text"
                            editorContent={watch('hard_disk_space')}
                            setValue={setValue}
                            name="hard_disk_space"
                            registration={register('hard_disk_space')}
                            error={formState.errors['hard_disk_space']}
                            options={[
                              { value: '', label: 'Select..' },
                              { value: '100-250', label: '100-250' },
                              { value: '260-500', label: '260-500' },
                              { value: '500-1TB', label: '500-1TB' },
                              { value: '1TB+', label: '1TB+' },
                            ]}
                          />
                        </div>
                        <div className="space-y-5">
                          <RegularTextAreaField
                            label="Asset Description"
                            error={formState.errors['description']}
                            registration={register('description')}
                            caption="Any extra information you’d like us to know about your computer. This will
                        guide us on serving you better."
                          />
                        </div>
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() => {
                            Swal.fire({
                              title: 'Are you sure?',
                              text: 'You will lose all your progress!',
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonText: 'Yes, close it!',
                              cancelButtonText: 'No, keep it',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                setCategory('');
                                setAssetDetails(false);
                              }
                            });
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            setAssetDetails(false);
                            setPictures(true);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}
                  {pictures && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Pictures</div>
                      <div className="mb-2">
                        Please ensure the uploaded images of your computer are clear and follow the
                        guidelines on what picture angles to take.
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        {[...Array(4).keys()].map((index) => (
                          <div key={index} className="relative">
                            <input
                              type="file"
                              id={`image-input-${index}`}
                              accept="image/*"
                              className="hidden"
                              onChange={(event) => handleImageChange(index, event)}
                            />
                            <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                              <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                {assetImages[index] ? (
                                  <img
                                    src={assetImages[index]}
                                    alt={`Uploaded Image ${index + 1}`}
                                    className="max-w-full max-h-full rounded-lg"
                                  />
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-12 w-12 text-gray-500"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                )}
                              </div>
                              <span className="block text-center mt-2 text-gray-500">
                                {assetImages[index]
                                  ? `Change Image ${index + 1}`
                                  : `Upload Image ${index + 1}`}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          variant="outline"
                          onClick={() => {
                            setAssetDetails(true);
                            setPictures(false);
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            setPictures(false);
                            setValuation(true);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}

                  {valuation && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Valuation</div>
                      <div className="mb-2">
                        To enhance your prospects of securing a loan, it's imperative to furnish
                        precise and comprehensive information.
                      </div>
                      <div className="md:mx-48">
                        <InputField
                          label="How much did you buy the mobile phone?"
                          error={formState.errors['purchase_amount']}
                          registration={register('purchase_amount')}
                        />
                        <InputField
                          label="How much is it worth currently?"
                          error={formState.errors['current_worth']}
                          registration={register('current_worth')}
                        />
                        <InputField
                          type="date"
                          label="When did you buy it?"
                          error={formState.errors['purchase_date']}
                          registration={register('purchase_date')}
                        />
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          variant="outline"
                          onClick={() => {
                            setValuation(false);
                            setPictures(true);
                          }}
                        >
                          Back
                        </Button>
                        <Button isLoading={createAssetMutation.isLoading} type="submit">
                          Finish
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Form>
          )}
          {category === 'OTHER_ELECTRONICS' && (
            <Form
              id="create-post"
              onSubmit={async (values) => {
                const bodyFormData = new FormData();
                bodyFormData.append('title', values.title);
                bodyFormData.append('category', category);
                values.specify_others
                  ? bodyFormData.append('specify_others', values.specify_others)
                  : null;
                bodyFormData.append('nature_of_electronic', nature_of_electronic);
                bodyFormData.append('product', values.product);
                bodyFormData.append('description', values.description);
                bodyFormData.append('model_type', values.model_type);
                bodyFormData.append('purchase_amount', values.purchase_amount);
                bodyFormData.append('purchase_date', values.purchase_date);
                bodyFormData.append('current_worth', values.current_worth);
                assetImages.forEach((image, index) => {
                  if (image) {
                    bodyFormData.append(`pictures`, dataURItoBlob(image));
                  }
                });
                await createAssetMutation.mutateAsync({ data: bodyFormData });
                navigate('/app/assets/create/success');
              }}
            >
              {({ register, formState, watch, setValue }) => (
                <>
                  {assetDetails && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Asset Details for other electronics</div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="space-y-5">
                          <InputField
                            label="Title of Asset"
                            error={formState.errors['title']}
                            registration={register('title')}
                            caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                          />
                          <ReactSelectField
                            label="Please select Product"
                            type="text"
                            editorContent={watch('product')}
                            setValue={setValue}
                            name="product"
                            defaultValue={watch('product')}
                            error={formState.errors['product']}
                            options={productsQuery.data?.map((p) => ({
                              label: p.product_title,
                              value: p._id,
                            }))}
                          />
                          <ReactSelectField
                            label="Select Electronic"
                            type="text"
                            editorContent={watch('nature_of_electronic')}
                            setValue={setValue}
                            name="nature_of_electronic"
                            defaultValue={watch('nature_of_electronic')}
                            error={formState.errors['nature_of_electronic']}
                            options={[
                              { label: 'Refrigerator', value: 'REFRIGERATOR' },
                              { label: 'Washing Machine', value: 'WASHING_MACHINE' },
                              { label: 'Gaming Console', value: 'GAMING_CONSOLE' },
                              { label: 'Cooker', value: 'COOKER' },
                              { label: 'Oven', value: 'OVEN' },
                              { label: 'Microwave', value: 'MICROWAVE' },
                              { label: 'Other', value: 'OTHER' },
                            ]}
                            onChange={(e) => setNatureOfElectronic(e.value)}
                          />
                          {nature_of_electronic === 'OTHER' && (
                            <InputField
                              label="Specify Other"
                              error={formState.errors['specify_others']}
                              registration={register('specify_others')}
                              caption="Specify an electronic in case you can't find yours in the list"
                            />
                          )}
                          <InputField
                            label="Model Type"
                            error={formState.errors['model_type']}
                            registration={register('model_type')}
                            caption="Specify a model of the electronic"
                          />
                        </div>
                        <div className="space-y-5">
                          <RegularTextAreaField
                            label="Asset Description"
                            error={formState.errors['description']}
                            registration={register('description')}
                            caption="Any extra information you’d like us to know about your computer. This will
                        guide us on serving you better."
                          />
                        </div>
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() => {
                            Swal.fire({
                              title: 'Are you sure?',
                              text: 'You will lose all your progress!',
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonText: 'Yes, close it!',
                              cancelButtonText: 'No, keep it',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                setCategory('');
                                setAssetDetails(false);
                              }
                            });
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            setAssetDetails(false);
                            setPictures(true);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}
                  {pictures && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Pictures</div>
                      <div className="mb-2">
                        Please ensure the uploaded images of your electronic are clear and follow
                        the guidelines on what picture angles to take.
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        {[...Array(4).keys()].map((index) => (
                          <div key={index} className="relative">
                            <input
                              type="file"
                              id={`image-input-${index}`}
                              accept="image/*"
                              className="hidden"
                              onChange={(event) => handleImageChange(index, event)}
                            />
                            <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                              <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                {assetImages[index] ? (
                                  <img
                                    src={assetImages[index]}
                                    alt={`Uploaded Image ${index + 1}`}
                                    className="max-w-full max-h-full rounded-lg"
                                  />
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-12 w-12 text-gray-500"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                )}
                              </div>
                              <span className="block text-center mt-2 text-gray-500">
                                {assetImages[index]
                                  ? `Change Image ${index + 1}`
                                  : `Upload Image ${index + 1}`}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          variant="outline"
                          onClick={() => {
                            setAssetDetails(true);
                            setPictures(false);
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            setPictures(false);
                            setValuation(true);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}

                  {valuation && (
                    <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                      <div className="mb-4">Valuation</div>
                      <div className="mb-2">
                        To enhance your prospects of securing a loan, it's imperative to furnish
                        precise and comprehensive information.
                      </div>
                      <div className="md:mx-48">
                        <InputField
                          label="How much did you buy the electronic?"
                          error={formState.errors['purchase_amount']}
                          registration={register('purchase_amount')}
                        />
                        <InputField
                          label="How much is it worth currently?"
                          error={formState.errors['current_worth']}
                          registration={register('current_worth')}
                        />
                        <InputField
                          type="date"
                          label="When did you buy it?"
                          error={formState.errors['purchase_date']}
                          registration={register('purchase_date')}
                        />
                      </div>
                      <div className="flex flex-row space-x-2 justify-end mt-4">
                        <Button
                          variant="outline"
                          onClick={() => {
                            setValuation(false);
                            setPictures(true);
                          }}
                        >
                          Back
                        </Button>
                        <Button isLoading={createAssetMutation.isLoading} type="submit">
                          Finish
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};
