import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const generatePaymentSchedule = ({ data }) => {
  return axios.post(`/loans/generate-payment-schedule`, data);
};

export const useGeneratePaymentSchedule = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (schedule) => {
        
    },
    onError: (_, __, context) => {},
    onSuccess: () => {
      queryClient.invalidateQueries('paymentSchedule');
    },
    ...config,
    mutationFn: generatePaymentSchedule,
  });
};
