import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getLoanOfficers = () => {
  return axios.get(`/users/loan-officers`);
};

export const useLoanOfficers = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['loanOfficers'],
    queryFn: () => getLoanOfficers(),
  });
};
