import React, { useState } from 'react';
import { Clipboard } from 'react-feather';

export const TrackingID = ({ trackingId }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(trackingId)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className="grid grid-cols-3 gap-8 p-6">
      <div className="text-gray-600 text-sm">Tracking ID</div>
      <div className="font-semibold text-md ">{trackingId.slice(0, 12)}***</div>
      <div className="">
        {copied && <span className="text-green-500 text-xs ml-2">Copied!</span>}
        <button
          onClick={copyToClipboard}
          className="ml-4 flex flex-row items-center space-x-2 text-xs text-gray-800 hover:text-primary-100 px-2 py-1"
        >
          <Clipboard size={16} />
        </button>
      </div>
    </div>
  );
};
