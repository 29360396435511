import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const markNotificationAsUnread = ({ id }) => {
  return axios.post(`/notifications/mark-as-unread/${id}`);
};

markNotificationAsUnread.propTypes = {
  id: PropTypes.string.isRequired,
};

export const useMarkNotificationAsUnread = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (notification) => {
      await queryClient.cancelQueries('notifications');

      const previousNotifications = queryClient.getQueryData('notifications');

      queryClient.setQueryData(
        'notifications',
        previousNotifications?.map((n) =>
          n.id === notification.id ? { ...n, read: false } : n
        )
      );

      return { previousNotifications };
    },
    onError: (_, __, context) => {
      if (context?.previousNotifications) {
        queryClient.setQueryData('notifications', context.previousNotifications);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('notifications');
      addNotification({
        type: 'success',
        title: 'Unread',
      });
    },
    ...config,
    mutationFn: markNotificationAsUnread,
  });
};
