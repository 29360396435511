import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const initiateContract = ({ id, data }) => {
  return axios.post(`/loans/initiate-contract/${id}`, data);
};

initiateContract.propTypes = {
  data: PropTypes.shape({
    // content: PropTypes.string.isRequired,
  }).isRequired,
};

export const useInitiateContract = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async () => {},
    onError: (_, __, context) => {},
    onSuccess: (data) => {
      queryClient.refetchQueries(['loan', data._id]);
      addNotification({
        type: 'success',
        title: 'Loan contract initiated.',
      });
    },
    ...config,
    mutationFn: initiateContract,
  });
};
