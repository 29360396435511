import { Navigate, Route, Routes } from 'react-router-dom';

import { Product } from './Product';
import { Products } from './Products';
import { CreateProduct } from './CreateProduct';
import { Settings } from './Settings';
import { ApplicableFees } from './ApplicableFees';
import { Create } from './Create';
import { DirectProducts } from './DirectProducts';
import { ExecutiveGuarantee } from './ExecutiveGuarantee';

export const ProductsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Products />} />
      <Route path=":id" element={<Product />} />
      <Route path="new" element={<Create />} />
      <Route path="settings" element={<Settings />} />
      <Route path="settings/applicable-fees" element={<ApplicableFees />} />
      <Route path="direct" element={<DirectProducts />} />
      <Route path="direct/executive-guarantee" element={<ExecutiveGuarantee />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
