import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getDirector = ({ id }) => {
  return axios.get(`/directors/${id}`);
};

export const useDirector = ({ id, config }) => {
  return useQuery({
    ...config,
    queryKey: ['director', id],
    queryFn: () => getDirector({ id }),
  });
};
