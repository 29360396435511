import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { Link, useNavigate } from 'react-router-dom';
import { ProductsList } from '../components/ProductsList';
import { Head } from 'components/Head';
import { useEffect } from 'react';

export const DirectProducts = () => {
  const routes = [
    {
      name: 'Product Development',
      path: '/app/products',
    },
    {
      name: 'Direct Products',
      path: '/app/products/direct',
    },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    // check if product creation is active

    const createProduct = localStorage.getItem('create-product');

    if (createProduct) {
      navigate('/app/products/new');
    }
  }, []);

  return (
    <ContentLayout
      title="Direct Products"
      end={<Button onClick={() => navigate('/app')}>Back Home</Button>}
    >
      <Head title="Direct Products" />
      <Breadcrumbs routes={routes} />
      <div className="mt-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Link
            to="/app/products/direct/executive-guarantee"
            className="bg-white border border-primary-100 rounded-xl shadow p-6"
          >
            <div className="text-primary-100 font-semibold text-lg mb-2">Executive Guarantee</div>
            <div className="text-gray-700 text-md">
              Manage directors & executive guaranteed loans
            </div>
          </Link>

          <Button disabled className="bg-white border border-primary-100 rounded-xl shadow p-6">
            <div className="text-primary-100 font-semibold text-lg mb-2">Salary Loans</div>
            <div className="text-gray-700 text-md">Manage Finablr salary loans.</div>
          </Button>
        </div>
      </div>
    </ContentLayout>
  );
};
