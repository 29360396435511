import { Table, Spinner, Button } from 'components/Elements';
import { formatDate } from 'utils/format';

import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLenders } from '../api/getLenderPortfolios';
import { ActivateLender } from './ActivateLender';

export const LendersList = () => {
  const lendersQuery = useLenders();
  const inputRef = useRef(null);
  const [q, setQ] = useState('');
  const [searchColumns, setSearchColumns] = useState([
    'user.basicInformation.firstName',
    'user.basicInformation.lastName',
    'user.basicInformation.contactInformation.email',
    'user.basicInformation.contactInformation.phoneNumber',
  ]);

  if (lendersQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!lendersQuery.data) return null;

  const columns = [
    {
      title: 'Name',
      field: 'basicInformation',
      Cell({ entry: { user } }) {
        return (
          <Link to={`/app/accounts/${user?._id}`}>
            <span className="text-lg font-bold">
              {user?.basicInformation?.firstName} {user?.basicInformation?.lastName}
              {user?.basicInformation?.companyName ?? user?.basicInformation?.companyName}
            </span>
            <br />
            {user?.basicInformation?.firstName ? 'Personal Account' : 'Company Account'}
          </Link>
        );
      },
    },
    {
      title: 'Contact Information',
      field: 'basicInformation',
      Cell({ entry: { user } }) {
        return (
          <>
            {user?.basicInformation?.contactInformation?.email}
            <br />
            {user?.basicInformation?.contactInformation?.phoneNumber}
          </>
        );
      },
    },
    {
      title: 'Commission',
      field: 'commission',
      Cell({ entry: { commission } }) {
        return <>{commission} %</>;
      },
    },
    {
      title: 'Lender Status',
      field: 'status',
    },
    {
      title: 'Created At',
      field: 'createdAt',
      Cell({ entry: { createdAt } }) {
        return <span>{formatDate(createdAt)}</span>;
      },
    },
    {
      title: 'action',
      field: '_id',
      Cell({ entry: { _id, status } }) {
        return (
          <div className="flex flex-row items-center">
            {status !== 'ACTIVE' && <ActivateLender id={_id} />}
            {/* <DeleteUser id={_id} /> */}
          </div>
        );
      },
    },
  ];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some((column) => {
        const value = extractNestedValue(row, column);
        return value.toString().toLowerCase().includes(q.toLowerCase());
      })
    );
  }

  const extractNestedValue = (obj, path) => {
    const keys = path.split('.');
    return keys.reduce((acc, key) => (acc && acc[key] ? acc[key] : ''), obj);
  };

  let users = search(lendersQuery.data);

  const handleInputChange = (event) => {
    setQ(event.target.value);
  };

  return (
    <>
      <div className="mt-8 flex flex-col md:flex-row space-y-2 md:space-y-0 items-center justify-center">
        <input
          ref={inputRef}
          type="text"
          className="border border-gray-700 p-2 rounded-xl w-full md:w-1/2"
          placeholder="Search for first or last name..."
          value={q}
          onChange={handleInputChange}
        />
      </div>
      <div className="mt-8">
        <Table title={'Lenders Portfolios'} data={users} columns={columns} />
      </div>
    </>
  );
};
