import { Navigate, Route, Routes } from 'react-router-dom';

import { Asset } from './Asset';
import { Assets } from './Assets';
import { CreateAsset } from './CreateAsset';
import { Success } from './Success';

export const AssetsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Assets />} />
      <Route path=":id" element={<Asset />} />
      <Route path="create" element={<CreateAsset />} />
      <Route path="create/success" element={<Success />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
