import { Spinner } from 'components/Elements';
import { Head } from 'components/Head';
import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { formatDate } from 'utils/format';
import { useParams } from 'react-router-dom';

import { useProduct } from '../api/getProduct';
import { UpdateProduct } from '../components/UpdateProduct';
import { CreateServiceFee } from '../components/CreateServiceFee';
import { CreateLateFee } from '../components/CreateLateFee';
import { UpdateServiceFeeRange } from '../components/UpdateServiceFeeRange';
import { UpdateLateFeeRange } from '../components/UpdateLateFeeRange';
import { DeleteLateFee } from '../components/DeleteLateFee';
import { DeleteServiceFee } from '../components/DeleteServiceFee';

export const Product = () => {
  const { id } = useParams();
  const productQuery = useProduct({ id });

  const Entry = ({ label, value }) => (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-6">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{value}</dd>
    </div>
  );

  const routes = [
    {
      name: 'products',
      path: '/app/products',
    },
    {
      name: productQuery.data?.product_title,
      path: `/app/products/${id}`,
    },
  ];

  if (productQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!productQuery.data) return null;

  const acceptableCollaterals =
    productQuery.data?.acceptable_collaterals &&
    productQuery.data?.acceptable_collaterals[0]?.split(',');

  return (
    <>
      <Head title={productQuery.data.product_title} />
      <ContentLayout
        title={productQuery.data.product_title}
        end={
          <div className="flex flex-row items-center space-x-2">
            <CreateLateFee id={productQuery.data?._id} />
            <CreateServiceFee id={productQuery.data?._id} />
            <UpdateProduct id={id} />
          </div>
        }
      >
        <Breadcrumbs routes={routes} />
        <div className="border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5 mt-4">
            <Entry label="Product Status" value={`${productQuery.data?.status}`} />
            <Entry
              label="Average Lending Rate"
              value={`${productQuery.data?.average_lending_rate} %`}
            />
            <Entry
              label="Acceptable Collaterals "
              value={
                <div className="flex flex-col md:flex-row items-start space-y-2 md:space-y-0 md:items-center space-x-2">
                  {acceptableCollaterals?.map((collateral, index) => (
                    <div key={index} className="text-xs text-white">
                      <div className="bg-orange-100 text-primary-100 font-bold border border-primary-100 rounded-full p-1 px-2">
                        {collateral}
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          </dl>
          <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5">
            <div className="font-bold my-4 px-6">Service Fees</div>
            {productQuery.data?.service_fees?.map((fee, index) => (
              <div className="flex flex-row items-center justify-between pr-6">
                <Entry key={index} label={fee.name} value={`${fee.percentage} %`} />
                <div className="flex flex-row items-center space-x-2">
                  <UpdateServiceFeeRange
                    id={productQuery.data?._id}
                    max={fee.range?.max}
                    min={fee.range?.min}
                    name={fee.name}
                    percentage={fee.percentage}
                    serviceFeeId={fee._id}
                  />
                  <DeleteServiceFee id={productQuery.data?._id} serviceFeeId={fee._id} />
                </div>
              </div>
            ))}

            <div className="font-bold my-4 px-6">Late Fees</div>
            {productQuery.data?.late_fees?.map((fee, index) => (
              <div className="flex flex-row items-center justify-between pr-6">
                <Entry key={index} label={fee.name} value={`${fee.percentage} %`} />
                <div className="flex flex-row items-center space-x-2">
                  <UpdateLateFeeRange
                    id={productQuery.data?._id}
                    max={fee.range?.max}
                    min={fee.range?.min}
                    name={fee.name}
                    percentage={fee.percentage}
                    lateFeeId={fee._id}
                  />
                  <DeleteLateFee id={productQuery.data?._id} lateFeeId={fee._id} />
                </div>
              </div>
            ))}

            <Entry
              label="Early Repayment Fee"
              value={`${productQuery.data?.early_repayment_fee} %`}
            />
          </dl>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5">
              <div className="font-bold my-4 px-6">Principal</div>
              <Entry
                label="Minimum"
                value={`UGX ${productQuery.data?.principal?.min?.toLocaleString()}`}
              />
              <Entry
                label="Maximum"
                value={`UGX ${productQuery.data?.principal?.max?.toLocaleString()}`}
              />
            </dl>
            <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5">
              <div className="font-bold my-4 px-6">Interest Rate</div>
              <Entry
                label="Minimum"
                value={`${productQuery.data?.interest_rate?.min?.toLocaleString()} %`}
              />
              <Entry
                label="Maximum"
                value={`${productQuery.data?.interest_rate?.max?.toLocaleString()} %`}
              />
            </dl>
            <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5">
              <div className="font-bold my-4 px-6">Period</div>
              <Entry
                label="Minimum"
                value={`${productQuery.data?.period?.min?.toLocaleString()} months`}
              />
              <Entry
                label="Maximum"
                value={`${productQuery.data?.period?.max?.toLocaleString()} months`}
              />
            </dl>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5">
              <div className="font-bold my-4 px-6">Real Estate</div>
              <Entry
                label="Due Deligence Fee"
                value={`UGX ${productQuery.data?.applicable_fees?.real_estate?.due_deligence_fee?.toLocaleString()}`}
              />
              <Entry
                label="Stamp Duty Percentage"
                value={`${productQuery.data?.applicable_fees?.real_estate?.mortgage_placement?.stamp_duty_fees?.toLocaleString()} %`}
              />
              <Entry
                label="Registration Fee"
                value={`UGX ${productQuery.data?.applicable_fees?.real_estate?.mortgage_placement?.registration_fees?.toLocaleString()}`}
              />
              <Entry
                label="Bank Charges"
                value={`UGX ${productQuery.data?.applicable_fees?.real_estate?.mortgage_placement?.bank_charges?.toLocaleString()}`}
              />
              <Entry
                label="Registrar"
                value={`UGX ${productQuery.data?.applicable_fees?.real_estate?.mortgage_placement?.registrar_fees?.toLocaleString()}`}
              />
              <Entry
                label="Legal Fees"
                value={`UGX ${productQuery.data?.applicable_fees?.real_estate?.mortgage_placement?.legal_fees?.toLocaleString()}`}
              />
            </dl>
            <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5">
              <div className="font-bold my-4 px-6">Valuation for Vehicles</div>
              <Entry
                label="Caveat Placement Fee"
                value={`UGX ${productQuery.data?.applicable_fees?.vehicles?.caveat_placement_fee?.toLocaleString()}
                  `}
              />
              <Entry
                label="Insurance Fee"
                value={
                  productQuery.data?.applicable_fees?.vehicles?.insurance_fee?.toLocaleString() + ''
                }
              />
              <Entry
                label="Tracker Fee (Hire)"
                value={
                  productQuery.data?.applicable_fees?.vehicles?.tracker_fee?.hire?.toLocaleString() +
                  ''
                }
              />
              <Entry
                label="Tracker Fee (Buy)"
                value={
                  productQuery.data?.applicable_fees?.vehicles?.tracker_fee?.buy?.toLocaleString() +
                  ''
                }
              />

              <Entry
                label="Commercial Light Vehicles"
                value={`${productQuery.data?.applicable_fees?.vehicles?.valuation?.commercial_light_vehicles?.toLocaleString()}`}
              />
              <Entry
                label="Commercial Heavy Vehicles"
                value={`${productQuery.data?.applicable_fees?.vehicles?.valuation?.commercial_heavy_vehicles?.toLocaleString()}`}
              />
              <Entry
                label="Heavy Vehicles"
                value={`${productQuery.data?.applicable_fees?.vehicles?.valuation?.heavy_vehicles?.toLocaleString()}`}
              />
              <Entry
                label="Personal Vehicles"
                value={`${productQuery.data?.applicable_fees?.vehicles?.valuation?.personal_vehicles?.toLocaleString()}`}
              />
              <Entry
                label="Bikes"
                value={`${productQuery.data?.applicable_fees?.vehicles?.valuation?.bikes?.toLocaleString()}`}
              />
            </dl>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5">
              <div className="font-bold my-4 px-6">Computers</div>
              <Entry
                label="Assessment Fees"
                value={
                  productQuery.data?.applicable_fees?.computers?.assessment_fees?.toLocaleString() +
                  ''
                }
              />
              <Entry
                label="Tracker Fees"
                value={
                  productQuery.data?.applicable_fees?.computers?.tracker_fees?.toLocaleString() + ''
                }
              />
            </dl>
            <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5">
              <div className="font-bold my-4 px-6">Television</div>
              <Entry
                label="Assessment Fees"
                value={
                  productQuery.data?.applicable_fees?.television?.assessment_fees?.toLocaleString() +
                  ''
                }
              />
              <Entry
                label="Tracker Fees"
                value={
                  productQuery.data?.applicable_fees?.television?.tracker_fees?.toLocaleString() +
                  ''
                }
              />
            </dl>
            <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5">
              <div className="font-bold my-4 px-6">Smart Phones</div>
              <Entry
                label="Assessment Fees"
                value={
                  productQuery.data?.applicable_fees?.smart_phone?.assessment_fees?.toLocaleString() +
                  ''
                }
              />
            </dl>
            <dl className="sm:divide-y sm:divide-gray-200 border border-gray-200 rounded-2xl shadow-lg mb-5">
              <div className="font-bold my-4 px-6">Other Electronics</div>
              <Entry
                label="Assessment Fees"
                value={
                  productQuery.data?.applicable_fees?.other_electronics?.assessment_fees?.toLocaleString() +
                  ''
                }
              />
            </dl>
          </div>
        </div>
      </ContentLayout>
    </>
  );
};
