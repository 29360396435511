import { Button, FullScreenSpinner, Spinner } from 'components/Elements';
import {
  DocumentUploadField,
  Form,
  FormDrawer,
  InputField,
  ReactSelectField,
  SelectField,
} from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';

import { useLoans } from 'features/loans';
import { useCreateRepayment } from '../api/createRepayment';
import { useState } from 'react';
import { useUsersWithLoans } from '../api/getUsersWithLoans';

export const CreateRepayment = ({ triggerButton }) => {
  const createRepaymentMutation = useCreateRepayment();
  const loansQuery = useLoans();
  const [paymentDoc, setPaymentDoc] = useState();

  const usersQuery = useUsersWithLoans();

  if (usersQuery.isLoading || loansQuery.isLoading) {
    return <FullScreenSpinner />;
  }

  if (!usersQuery.data || !loansQuery.data) return null;

  if (createRepaymentMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization
      allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.COLLECTIONS_OFFICER, ROLES.LOAN_OFFICER]}
    >
      <FormDrawer
        isDone={createRepaymentMutation.isSuccess}
        size="sm"
        triggerButton={triggerButton}
        title="Create New Repayment"
        submitButton={
          <Button
            form="create-repayment"
            type="submit"
            size="sm"
            isLoading={createRepaymentMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="create-repayment"
          onSubmit={async (values) => {
            const { user, amountReceived, paymentMethod } = values;
            const bodyFormData = new FormData();
            bodyFormData.append('user', user);
            bodyFormData.append('amountReceived', amountReceived);
            bodyFormData.append('paymentDoc', paymentDoc);
            bodyFormData.append('paymentMethod', paymentMethod);
            await createRepaymentMutation.mutateAsync({ data: bodyFormData });
          }}
        >
          {({ register, formState, setValue, watch }) => (
            <>
              <ReactSelectField
                label="Select / Search user"
                error={formState.errors['user']}
                registration={register('user')}
                setValue={setValue}
                name="user"
                defaultValue={watch('user')}
                options={usersQuery.data.map((user) => ({
                  value: user._id,
                  label:
                    user?.basicInformation?.firstName +
                    ' ' +
                    user?.basicInformation?.lastName +
                    ', ' +
                    user?.basicInformation?.contactInformation?.phoneNumber,
                }))}
              />
              <InputField
                label="Amount Received"
                error={formState.errors['amountReceived']}
                registration={register('amountReceived')}
              />
              {/* <div className="grid grid-cols-2 gap-3">
                <div className="">
                  <div className="">Late Fees Due</div>
                  <div className="">Interest Due</div>
                  <div className="">Principal Due</div>
                  <div className="">Penalties Due</div>
                </div>
              </div> */}
              <SelectField
                label="Select Payment Method"
                error={formState.errors['paymentMethod']}
                registration={register('paymentMethod')}
                options={[
                  { value: 'CASH', label: 'Cash' },
                  { value: 'BANK', label: 'Bank' },
                  { value: 'MOBILE', label: 'Mobile' },
                ]}
              />
              <DocumentUploadField
                label="Upload Proof of Payment "
                error={formState.errors['paymentDoc']}
                onChange={(e) => {
                  setPaymentDoc(e.target.files[0]);
                }}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};
