import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { TrashIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

import { useDeleteNotification } from '../api/deleteNotification';

export const DeleteNotification = ({ id }) => {
  const deleteNotificationMutation = useDeleteNotification();

  if (deleteNotificationMutation.isLoading) return <FullScreenSpinner />;

  return (
    <>
      <ConfirmationDialog
        icon="danger"
        title="Remove Notification"
        body="Are you sure you want to remove this notification?"
        triggerButton={
          <button>
            <TrashIcon className="h-4 w-4" />
          </button>
        }
        confirmButton={
          <Button
            isLoading={deleteNotificationMutation.isLoading}
            type="button"
            className="bg-red-600"
            onClick={async () => await deleteNotificationMutation.mutateAsync({ id: id })}
          >
            Trash
          </Button>
        }
      />
    </>
  );
};

DeleteNotification.propTypes = {
  id: PropTypes.string.isRequired,
};
