import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { Authorization, ROLES } from 'lib/authorization';
import PropTypes from 'prop-types';
import { useEflApproval } from '../api/eflApproval';
import { useParams } from 'react-router-dom';

export const EFLApproval = () => {
  const eflApprovalMutation = useEflApproval();

  const { id } = useParams();

  if (eflApprovalMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.LOAN_OFFICER]}>
      <ConfirmationDialog
        icon="info"
        title="EFL Approval"
        body="Would you like to approve this loan request so that?"
        triggerButton={
          <Button className="w-full mb-3">
            <span className="text-lg">EFL Approval</span>
            <br />
            <span>Click here to approve the loan request.</span>
          </Button>
        }
        confirmButton={
          <Button
            isLoading={eflApprovalMutation.isLoading}
            type="button"
            onClick={async () => await eflApprovalMutation.mutateAsync({ id })}
          >
            Approve
          </Button>
        }
      />
    </Authorization>
  );
};

EFLApproval.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
};
