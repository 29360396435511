import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const deleteLoan = ({ id }) => {
  return axios.delete(`/loans/${id}`);
};

deleteLoan.propTypes = {
  id: PropTypes.string.isRequired,
};

export const useDeleteLoan = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (deletedLoan) => {
      await queryClient.cancelQueries('loans');

      const previousLoans = queryClient.getQueryData('loans');

      queryClient.setQueryData(
        'loans',
        previousLoans?.filter((loan) => loan.id !== deletedLoan.id)
      );

      return { previousLoans };
    },
    onError: (_, __, context) => {
      if (context?.previousLoans) {
        queryClient.setQueryData('loans', context.previousLoans);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('loans');
      addNotification({
        type: 'success',
        title: 'Loan Deleted',
      });
    },
    ...config,
    mutationFn: deleteLoan,
  });
};
