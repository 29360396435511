import { Navigate, Route, Routes } from 'react-router-dom';

import { Login } from './Login';
import { Session } from './Session';
import { ConfirmEmail } from './ConfirmEmail';

export const AuthRoutes = () => {
  return (
    <Routes>
      {/* <Route path="register" element={<Register />} /> */}
      <Route path="login" element={<Login />} />
      <Route path="register/confirm/:code" element={<ConfirmEmail />} />
      <Route path="session/timeout" element={<Session />} />
    </Routes>
  );
};
