import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const deleteProduct = ({ id }) => {
  return axios.delete(`/products/${id}`);
};

deleteProduct.propTypes = {
  id: PropTypes.string.isRequired,
};

export const useDeleteProduct = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (deletedProduct) => {
      await queryClient.cancelQueries('products');

      const previousProducts = queryClient.getQueryData('products');

      queryClient.setQueryData(
        'products',
        previousProducts?.filter((product) => product.id !== deletedProduct.id)
      );

      return { previousProducts };
    },
    onError: (_, __, context) => {
      if (context?.previousProducts) {
        queryClient.setQueryData('products', context.previousProducts);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      addNotification({
        type: 'success',
        title: 'Product Deleted',
      });
    },
    ...config,
    mutationFn: deleteProduct,
  });
};
