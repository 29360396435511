import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getUsers = () => {
  return axios.get(`/users/executive_guarantee`);
};

export const useUsers = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['executive_guarantee_users'],
    queryFn: () => getUsers(),
  });
};
