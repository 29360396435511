import { axios } from 'lib/axios';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const computeNetYouReceive = ({ data }) => {
  return axios.post(`/loans/net-you-receive`, data);
};

export const useComputeNetYouReceive = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async () => {},
    onError: (_, __, context) => {},
    onSuccess: (data) => {
      addNotification({
        title: 'Success',
        message: 'Net you receive computed successfully',
        type: 'success',
      });
    },
    ...config,
    mutationFn: computeNetYouReceive,
  });
};
