import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getUser = ({ id }) => {
  return axios.get(`/users/${id}`);
};

export const useUser = ({ id, config }) => {
  return useQuery({
    ...config,
    queryKey: ['user', id],
    queryFn: () => getUser({ id }),
  });
};
