import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { Authorization, ROLES } from 'lib/authorization';
import PropTypes from 'prop-types';
import { useVerifyGuarantor } from '../api/verifyGuarantor';

export const VerifyGuarantor = ({ phoneNumber, loanId }) => {
  const verifyMutation = useVerifyGuarantor();

  if (verifyMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}>
      <ConfirmationDialog
        icon="info"
        title="Verify Guarantor"
        body="Would you like to verify this guarantor?"
        triggerButton={<Button>Verify Guarantor</Button>}
        confirmButton={
          <Button
            isLoading={verifyMutation.isLoading}
            type="button"
            onClick={async () =>
              await verifyMutation.mutateAsync({ data: { phoneNumber }, loanId })
            }
          >
            Verify
          </Button>
        }
      />
    </Authorization>
  );
};

VerifyGuarantor.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
};
