import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import { DirectorsList } from '../components/DirectorsList';
import { Authorization, ROLES } from 'lib/authorization';
import { CreateDirector } from '../components/CreateDirector';

export const Directors = () => {
  const routes = [
    {
      name: 'Directors',
      path: '/app/directors',
    },
  ];
  const navigate = useNavigate();

  return (
    <ContentLayout
      title="Directors"
      end={
        <div className="flex flex-row items-center space-x-2">
          <CreateDirector />
        </div>
      }
    >
      <Breadcrumbs routes={routes} />
      <Authorization
        forbiddenFallback={<div>Only admin can view this.</div>}
        allowedRoles={[ROLES.SUPER_ADMIN]}
      >
        <div className="mt-8">
          <DirectorsList />
        </div>
      </Authorization>
    </ContentLayout>
  );
};
