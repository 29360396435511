import { Button, Spinner } from 'components/Elements';
import { Notifications } from 'components/Notifications/Notifications';
import { AuthProvider } from 'lib/auth';
import { queryClient } from 'lib/react-query';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
// import { useIdleTimer } from 'react-idle-timer';
import { SubmitComplaint } from 'features/support';
// In your component or a utility file
import { getToken } from 'firebase/messaging';
import { messaging } from 'lib/firebase';
import { useEffect } from 'react';
import { axios } from 'lib/axios';

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Sorry, something went wrong </h2>
      <Button
        className="mt-4"
        onClick={() => {
          localStorage.clear();
          window.location.assign(window.location.origin);
        }}
      >
        Refresh
      </Button>
    </div>
  );
};

export const AppProvider = ({ children }) => {
  useEffect(() => {
    // Register the service worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    }

    const requestNotificationPermission = async () => {
      try {
        const permission = await window.Notification.requestPermission();
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          await getDeviceToken(); // Get the device token after permission is granted
        } else {
          console.log('Notification permission denied.');
        }
      } catch (error) {
        console.error('Error requesting notification permission:', error);
      }
    };

    const getDeviceToken = async () => {
      try {
        const currentToken = await getToken(messaging, {
          vapidKey:
            'BD5iNmoQt4dKlCXFxKko9I3eDkTj0xg5ZoNUS0ue7ZT0Oo_IYEFXTSbrguqzMm1XQPmC8XueFkHJczb5b4GVE9M',
        });
        if (currentToken) {
          console.log('Device token:', currentToken);
          // Send this token to your server and store it for future use
          const data = {
            token: currentToken,
          };
          // return axios.post(`/devices/${deviceId}/create-token`, data);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      } catch (error) {
        console.error('An error occurred while retrieving token:', error);
      }
    };

    requestNotificationPermission();
  }, []);

  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner size="xl" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <Notifications />
            <AuthProvider>
              <Router>
                <SubmitComplaint />
                {children}
              </Router>
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
