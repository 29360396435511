import { Button, Dialog, DialogDescription, DialogTitle, Spinner } from 'components/Elements';
import { Form, InputField, RegularTextAreaField } from 'components/Form';
import React from 'react';
import { useSubmitComplaint } from '../api/submitComplaint';
import { useAuth } from 'lib/auth';

export function SubmitComplaint() {
  const [show, setShow] = React.useState(false);

  const createComplaintMutation = useSubmitComplaint();

  const { user } = useAuth();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <button
        className="z-10 fixed bottom-4 right-4 bg-primary-100 hover:bg-gray-800 text-white py-2 px-4 rounded-full shadow-lg"
        onClick={handleOpen}
      >
        Having issues?
      </button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <Form
            id="create-tip"
            onSubmit={async (values) => {
              if (user) {
                values.email = user.basicInformation?.contactInformation?.email;
              }
              await createComplaintMutation.mutateAsync({ data: values });
            }}
          >
            {({ register, formState }) => (
              <div>
                <DialogTitle className="text-xl text-center font-bold mb-4">
                  Sorry about the issue you're facing.
                </DialogTitle>
                <DialogDescription>
                  {createComplaintMutation.isSuccess ? (
                    <>
                      <div className="">
                        Thank you for your submission. We'll get back to you as soon as we've
                        rectified the issue.
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mb-4">
                        Please describe the issue you're facing and we'll get back to you as soon as
                        possible.
                      </div>
                      <div className="mx-auto">
                        {!user && (
                          <InputField
                            label="Email Address"
                            error={formState.errors['email']}
                            registration={register('email')}
                            caption="We'll use this to get back to you."
                          />
                        )}
                        <RegularTextAreaField
                          label="Complaint"
                          error={formState.errors['content']}
                          registration={register('content')}
                        />
                      </div>
                    </>
                  )}
                </DialogDescription>
                {createComplaintMutation.isSuccess ? (
                  <div className="flex flex-row items-center space-x-2">
                    <button
                      onClick={() => {
                        createComplaintMutation.reset();
                        setShow(false);
                      }}
                      type="button"
                      className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl"
                    >
                      Close
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-row items-center space-x-2">
                    <button
                      onClick={handleClose}
                      type="button"
                      className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="mt-4 p-2 px-4 bg-gray-100 w-full flex flex-row justify-center items-center space-x-2 rounded-2xl text-white bg-primary-100"
                    >
                      {createComplaintMutation.isLoading && <Spinner size="sm" />}
                      <div className="">Proceed</div>
                    </button>
                  </div>
                )}
              </div>
            )}
          </Form>
        </div>
      </Dialog>
    </div>
  );
}
